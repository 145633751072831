<template>
  <v-dialog v-model="localDialog" persistent max-width="600px">
    <v-card v-if="selectedConversation" style="overflow:hidden; border-radius:25px;">
        <v-row justify="center">
          <v-row justify="center">
            <v-col cols="auto">
              <h1 style="padding-top:25px;" class="h1-class">{{$t('conversation_details')}}</h1>
              <template>
                <h2 class="text-center">{{ formatDate(this.selectedConversation.date_created) }}</h2>
              </template>
            </v-col>
          </v-row>

          <v-card-text class="dialog-content">
              <div v-for="message in selectedConversation.messages" :key="message.id" :class="getMessageClass(message)">
                <h3 class="text-right" v-if="message.timestamp">{{ formatTime(message.timestamp) }}</h3>
                <div v-html="message.contentHTML"></div>
              </div>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-row style="padding:35px;">
                  <v-btn
                      elevation="5"
                      :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:35px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                      color="#E140A0"
                      @click="close">
                      {{$t('close')}}
                  </v-btn>
              </v-row>
          </v-card-actions>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    selectedConversation: Object
  },
  data() {
    return {
      localDialog: this.dialog,
    };
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal;
    },
    localDialog(newVal) {
      if (!newVal) {
        this.$emit('update:dialog', newVal);
      }
    },
  },
  methods: {
    getMessageClass(message) {
      return {
        'chatSession-message-user': message.role === 'user',
        'chatSession-message-assistant': message.role === 'assistant',
      };
    },
    close() {
      this.localDialog = false;
      this.$emit('close-dialog');
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Intl.DateTimeFormat('nl-NL', {
        timeZone: userTimeZone,
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);
    },
    formatTime(timestamp) {
      // Append 'Z' to indicate UTC time
      const utcTimestamp = timestamp + 'Z';
      const date = new Date(utcTimestamp);

      const convertedDate = date.toLocaleTimeString('nl-NL', {
        timeZone: 'Europe/Amsterdam',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });

      return convertedDate;
    }
  },
};
</script>

<style scoped>

.dialog-content {
  max-height: 50vh;
  max-width: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (min-width: 1000px) {
  .h1-class {
    font-size: 36px;
  }
  .h2-class {
    font-size: 30px;
  }
  .p-class {
    font-size: 20px;
  }
}
.chatSession-message-user {
  background-color: #e3f2fd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: right;
}

.chatSession-message-assistant {
  background-color: #fce4ec;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
}

</style>
