<template>
  <div>
    <v-dialog v-if="activeSubscription" v-model="localDialog" persistent max-width="800px">
      <v-card style="overflow:hidden; border-radius:25px;">
        <v-row style="padding:20px;">
            <v-col cols="2" class="d-flex justify-start">
            </v-col>
            <v-col cols="8" class="d-flex justify-center">
                <v-card-title class="justify-center text-h6 font-weight-bold">
                  <div class="subscription-header">
                    <h1>{{$t('subscription_details')}}</h1>
                  </div>
                </v-card-title>
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
                <v-btn icon @click="close" class="close-button">
                    <v-icon size="36">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <!-- No live subbscription yet, but a custom subscription is prepared -->            
        <v-card-text v-if="activeSubscription">         
          <v-list two-line class="text-center">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold">{{$t('product')}}</v-list-item-title>
                <v-list-item-subtitle class="text-h6">{{ activeSubscription.chosen_product }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold">{{$t('subscription_type')}}</v-list-item-title>
                <v-list-item-subtitle class="text-h6">{{ activeSubscription.subscription_type }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold">{{$t('subscription_id')}}</v-list-item-title>
                <v-list-item-subtitle class="text-h6">{{ activeSubscription._id }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold">{{$t('amount')}}</v-list-item-title>
                <v-list-item-subtitle
                  class="text-h6">
                  {{ activeSubscription.manual_amount }} EUR
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold">{{$t('invoices')}}</v-list-item-title>
                <v-list-item-subtitle
                class="text-h6">
                  <a href="https://billing.stripe.com/p/login/6oE4iIezH1aH4923cc" style="color:blue;" target="_blank">{{$t('link_to_stripe_portal')}}</a>
                
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="activeSubscription.subscription_data && activeSubscription.subscription_data.status === 'canceled'">
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold">{{$t('status')}}</v-list-item-title>
                <v-list-item-subtitle class="text-h6">{{$t('canceled')}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="activeSubscription.subscription_data && activeSubscription.subscription_data.status === 'canceled'">
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold">{{$t('end_date')}}</v-list-item-title>
                <v-list-item-subtitle class="text-h6">{{ activeSubscription.end_date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="activeSubscription.subscription_data && activeSubscription.subscription_data.status === 'active'">
                <v-list-item-content class="d-flex flex-column align-center">
                    <v-list-item-title class="text-h6 font-weight-bold">{{$t('status')}}</v-list-item-title>
                    <v-select
                        style="max-width:150px;"
                        v-model="selectedStatus"
                        :items="statusOptions"
                        @change="statusChanged"
                    ></v-select>
                </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row style="padding:35px;">
            <v-btn
                v-if="showConfirmButton"
                color="red"
                class="mt-3"
                @click="confirmCancellation"
                >
                {{$t('confirm_termination')}}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmationDialog"  persistent max-width="400px">
    <v-card style="border:5px solid red;">
      <v-card-title class="text-h5">{{$t('confirm_termination')}}</v-card-title>
      <v-card-text style="padding:5px;">{{$t('are_you_sure_you_want_to_terminate')}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-row>
            <v-btn text @click="confirmationDialog = false">{{$t('cancel')}}</v-btn>
            <v-btn color="red" @click="terminateSubscription">{{$t('confirm')}}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
    import { EventBus } from '../../eventBus.js';
    import axios from 'axios';

  export default {
    props: {
      dialog: Boolean,
      activeSubscription: Object
    },
    data() {
      return {
        localDialog: this.dialog,
        confirmationDialog: false,
        selectedStatus: 'active', // default status
        showConfirmButton: false,
        statusOptions: [
            'active',
            'terminate', // this represents the termination option
        ],
        chosenSubscriptionType: 'monthly'
      };
    },
    watch: {
      dialog(newVal) {
        this.localDialog = newVal;
      },
      localDialog(newVal) {
        if (!newVal) {
          this.$emit('update:dialog', newVal);
        }
      },
    },
    methods: {
        statusChanged(value) {
            // When the value is 'terminate', show the confirm button
            this.showConfirmButton = value === 'terminate';
        },
        close() {
            this.localDialog = false;
            this.$emit('close-dialog');
        },
        confirmCancellation() {
            // Open confirmation dialog
            this.confirmationDialog = true;
        },
        async terminateSubscription() {
            EventBus.$emit('show-overlay');
            try {
                const token = localStorage.getItem('userToken');

                let subscriptionId = this.activeSubscription.subscription_id;
                let mollie_customer_id = this.activeSubscription.subscription_data.customerId;
                let configuration_id = this.activeSubscription.configuration_id;

                const params = new URLSearchParams({
                    subscription_id: subscriptionId,
                    customer_id: mollie_customer_id,
                    configuration_id: configuration_id,
                }).toString();

                const response = await axios.get(`${this.$config.configurationServer}/orders/cancel-subscription?${params}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                let result = response.data;
                console.log('*** result', result);

            } catch (error) {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });
            } finally {
                this.showConfirmButton = false;
                this.confirmationDialog = false;
                this.close();
                EventBus.$emit('hide-overlay');
            }
        },
        setSubscriptionType() {
          if (this.chosenSubscriptionType === 'annual') {
            this.chosenSubscriptionType = 'monthly';
          }
          else {
            this.chosenSubscriptionType = 'annual';
          }
        },
    },
  };
  </script>

  <style scoped>

  .h1-class {
    font-size: 36px;
  }
  .h2-class {
    font-size: 30px;
  }
  .p-class {
    font-size: 20px;
  }

.dialog-card {
  overflow: hidden;
  border-radius: 25px;
}

.logo {
  width: 250px;
  margin-top: 25px; /* Adjust as needed */
}

.close-button {
  position: absolute;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
}

.subscription-header {
  text-align: center;
  margin-bottom: 20px;
}

.subscription-title {
  display: block; /* Makes it take up the full width */
  text-align: center;
  margin-bottom: 5px;
}

.pricing-details {
  margin-bottom: 20px;
}

/* Dialog Content Styling */
.dialog-content {
  font-family: 'Arial', sans-serif;
  color: #333; /* Adjust the base text color as needed */
  margin: 0px;
}
.pricing-item {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
}
.pricing-item span {
    font-size:20px;
}

/* Typography */
.purple-text {
  color: #800080;
  font-weight: bold;
  font-size: 28px; /* Adjust as needed for your design */
}

.text-center {
  text-align: center;
}

/* Divider */
.v-divider {
  margin: 10px 0;
  border-bottom: 1px solid #ccc; /* Adjust divider color as needed */
}

/* Subscription Info */
.subscription-info {
  margin-bottom: 20px;
}

.subscription-info div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.subscription-info span {
  flex: 1;
  text-align: center;
}

/* Button Styling */
.upgrade-btn {
  border-radius: 15px;
  color: white;
  font-weight: bolder;
  background-color: #E140A0;
  padding: 10px 20px; /* Adjust padding as needed */
  margin: 20px auto; /* Center button in the available space */
  display: block; /* Make it a block element to apply margin auto */
  text-transform: uppercase; /* Optional: makes text uppercase */
}

.upgrade-btn span {
    color:white;
}

.upgrade-btn:hover {
  background-color: #C13584; /* Darker shade for hover effect */
}

/* Checkbox */
.v-checkbox {
  margin-top: 20px;
}

/* Terms and Conditions Text */
.terms-text {
  font-size: 14px; /* Adjust the font size as needed */
  color: #666; /* Adjust the font color as needed */
  text-align: center;
  margin-top: 10px;
}

/* Small print at the bottom */
.small-print {
  font-size: 12px; /* Adjust as needed */
  color: #666; /* Adjust as needed */
  text-align: center;
  margin-top: 20px;
}

/* Ensure the button and links are accessible */
a, button {
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 600px) {
  .dialog-content {
    margin: 10px;
  }

  .pricing-card {
    padding-bottom: 50px;
  }

  /* Adjust font sizes for smaller screens */
  .purple-text {
    font-size: 24px;
  }

  .terms-text, .small-print {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .mobile-zoom{
    zoom: 90%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 85%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 80%
  }
}

@media (max-width: 500px) {
  .mobile-zoom{
    zoom: 75%
  }
}

@media (max-width: 450px) {
  .mobile-zoom{
    zoom: 70%
  }
}

@media (max-width: 400px) {
  .mobile-zoom{
    zoom: 65%
  }
}

.dialog-card span{
    color:black;
}


.dialog-content {
  max-height: 50vh;
  max-width: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
}


</style>
