<template>
  <v-card class="window-card">
    <div class="chart-title">ADMIN - Gesprekken alle configuraties</div>
    <apex-chart :options="options" :series="series"></apex-chart>
  </v-card>
</template>

<script>
  import { EventBus } from './../../eventBus.js';
  import axios from 'axios';
  export default {
    name: 'AdminConversationsWidget',
    data() {
      return {
        options: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            }
          },
          xaxis: {
            categories: []  // Initialize as an empty array
          }
        },
        series: [
          {
            name: 'Geinitieerde gesprekken',  // Name of the first series
            data: []  // Initialize as an empty array
          },
          {
            name: 'Werkelijke gesprekken',  // Name of the second series
            data: []  // Initialize as an empty array
          }
        ]
      };
    },
    created() {
      EventBus.$on('reload-chart', () => {
        this.loadData();
      });
    },
    mounted() {
        this.loadData();
    },
    methods: {
      async loadData() {
        const token = localStorage.getItem('userToken');
        if (token) {
            const widget_id = 'widget_adm_1';
            return axios.get(this.$config.configurationServer + '/dashboard/get_widget_data', {
                headers: { Authorization: `Bearer ${token}` },
                params: { widget_id }  // Passing widget_id as a query parameter
            })
            .then(response => {
                const data = response.data;
                if (response.status === 200) {
                    // Translate series names
                    const translatedSeries = data.series.map(serie => ({
                        ...serie,
                        name: this.$t(serie.name)  // Translate name using vue-i18n
                    }));

                    // Update categories and series with translations
                    this.options = {
                        ...this.options,
                        xaxis: {
                            ...this.options.xaxis,
                            categories: data.categories
                        }
                    };
                    this.series = translatedSeries;
                } else {
                    console.log('*** error: ', response);
                }
            })
            .catch(error => {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('data_retrieval_unsuccessful') + error });
            });
        } else {
            return Promise.resolve([]);
        }
    }
    }
  }
</script>

<style scoped>
  .window-card {
  }

  .chart-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
</style>