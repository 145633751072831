import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'
import './assets/main.css'
import '@mdi/font/css/materialdesignicons.css'
import i18n from './i18n'
import config from '.././configs/config.json';
import VueApexCharts from 'vue-apexcharts'
import '../node_modules/flag-icon-css/css/flag-icons.min.css'
import Vuex from 'vuex';

Vue.config.productionTip = false
Vue.prototype.$config = config;
Vue.prototype.$globalState = {
  wizardValues: {
    enableAIStartMessage: false,
    useEmoticons: false,
    startMessage: '',
    companyDescription: '',
    aiDescription: '',
    goalDescription: '',
    answerDescription: '',
    generalInformationDescription: ''
  }
};

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueApexCharts);
Vue.component('apex-chart', VueApexCharts);

const store = new Vuex.Store({
  state: {
    userRole: null,
    selectedConfiguration: null,
  },
  mutations: {
    setUserRole(state, role) {
      state.userRole = role;
    },
    setSelectedConfiguration(state, configuration) {
      state.selectedConfiguration = configuration;
    },
  },
  actions: {
    updateUserRole({ commit }, role) {
      commit('setUserRole', role);
    },
    updateSelectedConfiguration({ commit }, configuration) {
      commit('setSelectedConfiguration', configuration);
    },
    clearSelectedConfiguration(state) {      
      state.selectedConfiguration = null; // Resets to null or define your own default state
    }
  },
  getters: {
    userRole: state => state.userRole,
    selectedConfiguration: state => state.selectedConfiguration,
  },
});


const routes = [
]

const router = new VueRouter({
  routes
})

const userLanguage = localStorage.getItem('userLanguage') || 'nl';

const vuetifyInstance = vuetify({
  lang: {
    current: userLanguage,
  },
});

new Vue({
  router,
  vuetify: vuetifyInstance,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')