<template>
  <div>
    <v-dialog v-model="dialog" persistent fullscreen>
      <v-app-bar color="primary" dark>
        <v-row align="center" justify="start">
          <v-col cols="auto">
            <v-img
              alt="HostHub Logo"
              src="@/assets/logo.png"
              height="38"
              width="200"
            />
          </v-col>
          <v-btn icon @click="handleLogout">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
          <v-select
            v-if="selectedLanguage"
            v-model="selectedLanguage"
            :items="languages"
            @change="changeLanguage"
            style="max-width: 50px;"
            class="language-selector"
            flat
          >
            <template v-slot:item="{ item }">
                <div v-html="item.text"></div>
            </template>
            <template v-slot:selection="{ item }">
                <div v-html="item.text"></div>
            </template>
          </v-select>

        </v-row>
      </v-app-bar>
      <v-card style="background-color: #FDF1F7;">
        <!-- <v-card-title class="dialog-header bold-text">
          <v-spacer></v-spacer>
          {{$t('setup_wizard_title')}}
          <v-spacer></v-spacer>
        </v-card-title> -->
        <v-card-text>
          <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">{{$t('welcome')}}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">{{$t('instructions')}}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 3" step="3">{{$t('design')}}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 4" step="4">{{$t('test')}}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 5" step="5">{{$t('finalize')}}</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Step 1 Content -->
            <v-stepper-content style="padding:0px;" step="1">
              <step-one-welcome
                v-if="renderSteps"
                :configuration="configuration"
                @configuration-name-updated="updateConfigurationName" />
              <v-row justify="center" style="height:75px;">
                <v-col cols="auto" style="padding:0px;">
                  <v-btn
                    elevation="5"
                    :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                    color="#E140A0"
                    @click="e1 = 2">
                    {{$t('next')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <!-- Step 2 Content -->
            <v-stepper-content style="padding:0px;" step="2">
              <step-two-instructions
                v-if="renderSteps"
                :configuration="configuration"
                @input-validated="updateConfigurationInstruction"
                @urls-updated="updateUrls"
                @step-two-finished="showStep2Buttons = true"
                @step-two-not-finished="showStep2Buttons = false" />
              <v-row v-if="showStep2Buttons" class="mobile-zoom"
                justify="center">
                <v-col cols="auto" style="height:100px;">
                  <v-btn
                    elevation="5"
                    :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:35px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                    color="#E140A0"
                    @click="saveConfigurationAndProceedToStep3">
                    {{$t('next')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <!-- Step 3 Content -->
            <v-stepper-content style="padding:0px;" step="3">
              <step-three-design
                v-if="renderSteps"
                :configuration="configuration"
                @chatbotsettings-saved="enableTest = true" />
              <v-row justify="center" style="padding-top:10px; height: 75px;" class="mobile-zoom">
                 <v-btn
                  elevation="5"
                  :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                  color="#E140A0"
                  @click="e1 = 2">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  elevation="5"
                  :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                  color="#E140A0"
                  @click="nextStep3Clicked">
                  {{$t('next')}}
                </v-btn>
              </v-row>
            </v-stepper-content>
            <!-- Step 4 Content -->
            <v-stepper-content style="padding:0px;" step="4">
              <step-four-test
              v-if="renderSteps"
              :configuration="configuration" />
              <v-row justify="center" style="padding-top:0px;">
                <v-col cols="auto">
                  <v-btn
                    elevation="5"
                    :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                    color="#E140A0"
                    @click="e1 = 3">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    elevation="5"
                    :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-left:25px; padding-right:25px; font-size:22px; font-weight:bolder;`"
                    color="#E140A0"
                    @click="e1 = 5">
                    {{$t('next')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!-- Step 5 Content -->
            <v-stepper-content style="padding:0px;" step="5">
              <step-five-finalize v-if="renderSteps" :configuration="configuration" />
              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn
                    elevation="5"
                    :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                    color="#E140A0"
                    @click="e1 = 4">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        </v-card-text>
        <v-card-actions v-if="e1 === 5 || configuration.wizardCompleted" class="dialog-footer justify-center">
          <v-btn
            elevation="5"
            :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
            color="#E140A0"
            @click="completeWizard">
            <span class="mdi mdi-flag-checkered"></span>
            <span style="padding-left:30px;">{{$t('complete_setup')}}</span>
          </v-btn>

        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import StepOneWelcome from '../wizard/StepOneWelcome.vue';
import StepTwoInstructions from '../wizard/StepTwoInstructions.vue';
import StepThreeDesign from '../wizard/StepThreeDesign.vue';
import StepFourTest from '../wizard/StepFourTest.vue';
import StepFiveFinalize from '../wizard/StepFiveFinalize.vue';
import { EventBus } from '../../eventBus.js';
import axios from 'axios';

export default {
  components: {
    StepOneWelcome,
    StepTwoInstructions,
    StepThreeDesign,
    StepFourTest,
    StepFiveFinalize
  },
  props: {
    configurationId: String
  },

  data() {
    return {
      dialog: true,
      selectedLanguage: '',
      e1: 1,
      configurationServer: this.$config.configurationServer,
      configurationName: '',
      childWelcomeMessage: '',
      configuration: {
        "_id": "",
        "name": "",
        "model": "gpt-4-1106-preview",
        "entity_instruction": "",
        "company_description": "",
        "ai_assistant_description": "",
        "goal_instruction": "",
        "answer_instruction": "",
        "general_information_instruction": "",
        "include_query_examples": false,
        "intent_example_questions": [],
        "functions": [],
        "message_limit": 50,
        "temperature": 0.3,
        "topp": 0.3,
        "max_tokens": 500,
        "wizardCompleted": false,
        "current_product": 'entry', // new configurations are always entry (free) level
        "isAutoOnboarding": 'true', // flag to seperate auto onboarding and manual configurations
        // TODO: Make sure a subscription record is created with an active subscription for ENTRY level
        "has_active_subscription": true // entry level always have active subscription by default
      },
      chatbotSettings: {
      },
      urls: [],
      enableTest: false,
      renderSteps: false,
      showWizardStart: false,
      showStep2Buttons: false
    };
  },
  mounted() {
    if (this.configurationId) { // configuration id is passed from the main component into the wizard, this means that the existing configuration is not yet finished
      EventBus.$emit('show-snackbar', { type: 'ERROR', message: 'configuratie gevonden!' });
      this.fetchConfiguration();
      this.e1 = 2;
      this.showStep2Buttons = true;
    }
    else {
      this.showWizardStart = false;
      this.renderSteps = true;
    }

    this.selectedLanguage = localStorage.getItem('userLanguage');

  },
  created() {

  },
  computed: {
    languages() {
      return [
        { text: this.getFlagIcon('us'), value: 'en' },
        { text: this.getFlagIcon('nl'), value: 'nl' },
        { text: this.getFlagIcon('de'), value: 'de' },
        { text: this.getFlagIcon('fr'), value: 'fr' },
        { text: this.getFlagIcon('es'), value: 'es' },
        { text: this.getFlagIcon('pt'), value: 'pt' }
      ];
    }
  },
  methods: {
    fetchConfiguration() {
      const token = localStorage.getItem('userToken');
      let configuration_id = this.configurationId;

      if (token) {
        // Include the configuration_id in the query parameters
        const queryParams = new URLSearchParams({ configuration_id }).toString();
        const url = this.$config.configurationServer + '/configuration?' + queryParams;

        return axios.get(url, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(configResponse => {
          if (configResponse.status === 200) {

            this.configuration = configResponse.data;
            if (!this.configuration.wizardCompleted) {
              EventBus.$emit('show-snackbar', { type: 'WARN', message: this.$t('configuration_not_completed'), timeout: -1 });
            }
            this.renderSteps = true;
          } else {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') });
            return [];
          }
        })
        .catch(error => {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') + error });
          return [];
        });
      } else {
        return Promise.resolve([]);
      }
    },
    handleLogout() {
      localStorage.removeItem('userToken');
      localStorage.removeItem('userConfigurations'); // Clear configurations from local storage
      location.reload(true);
    },
    updateConfigurationName(name) {
      this.configuration.name = name;
    },
    saveConfigurationAndProceedToStep3() {

      // Implement the logic to save the configuration
      this.saveConfiguration();
      this.e1 = 3; // Proceed to the next step
    },
    nextStep3Clicked() {
      EventBus.$emit('execute-save-design');
      this.e1 = 4;
    },
    async saveConfiguration() {
      EventBus.$emit('show-overlay');
      // Retrieve the token from localStorage
      const token = localStorage.getItem('userToken');
      if (!token) {
        // Handle the case where there is no token (user not logged in or token expired)
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: 'Not authenticated' });
        return;
      }

      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const payload = JSON.parse(atob(base64));

        if (payload.cid) {
          this.configuration.customerId = payload.cid;
        }
        else {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: 'Error, no customer id' });
          return;
        }
        this.configuration.created_by = 'auto'; // identifier that created by is done by auto onboarding
        this.configuration.name = this.$globalState.wizardValues.assistantName;
        this.configuration.companyName = this.$globalState.wizardValues.companyName;
        this.configuration.company_description = this.$globalState.wizardValues.companyDescription;
        this.configuration.assistantName = this.$globalState.wizardValues.assistantName;
        this.configuration.ai_assistant_description = this.$globalState.wizardValues.aiDescription;
        this.configuration.goal_instruction = this.$globalState.wizardValues.goalDescription;
        this.configuration.answer_instruction = this.$globalState.wizardValues.answerDescription;
        this.configuration.enableAIStartMessage = this.$globalState.wizardValues.enableAIStartMessage;
        this.configuration.startMessage = this.$globalState.wizardValues.startMessage;

        // werkt nog niet lekker :()
        // let htmlInstruction = this.$t('extra_instructions.html_included');
        // if (!this.configuration.answer_instruction.includes(htmlInstruction)) {
        //     this.configuration.answer_instruction += "\n" + htmlInstruction;
        // }

        this.configuration.answer_instruction += "\n" + this.$t('extra_instructions.html_included');
        this.configuration.general_information_instruction = this.$globalState.wizardValues.generalInformationDescription;

        this.configuration.entity_instruction =
        [this.configuration.company_description, this.configuration.ai_assistant_description]
        .filter(desc => desc && desc.trim() !== '')
        .join("\n\n");

        const response = await axios.post(`${this.configurationServer}/configuration/create_or_update`,
          this.configuration,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        // Success
        if (response.data && (response.data.inserted_id || response.data.updated_id)) {
          if (response.data.inserted_id) {
            this.configuration._id = response.data.inserted_id;
            // new configurations created from the portal are always entry level
            this.currentProduct = 'entry';
          }
          const urls = this.$globalState.wizardValues.urls;
          if (urls && urls.length > 0) {
            const urlData = {
              configurationId: this.configuration._id,
              urls: urls
            }
            await axios.post(`${this.configurationServer}/training/urls/save`, urlData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
          }
          // store default chatdesign when there's a new id
          if (response.data.inserted_id) {
            EventBus.$emit('execute-save-design');
          }
        }

      } catch (error) {
        // Handle error
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: 'Error saving configuration' });
        console.error('Error saving configuration:', error);
      } finally {
        EventBus.$emit('hide-overlay');
      }
    },
    updateConfigurationInstruction(payload) {
      switch (payload.identifier) {
        case 'companyDescription':
          this.configuration.company_description = payload.value;
          break;
        case 'aiAssistantDescription':
          this.configuration.ai_assistant_description = payload.value;
          break;
        case 'goalDescription':
          this.configuration.goal_instruction = payload.value;
          break;
        case 'answerDescription':
          this.configuration.answer_instruction = payload.value;
          break;
        case 'generalInformationDescription':
          this.configuration.general_information_instruction = payload.value;
          break;
      }
    },
    updateUrls(newUrls) {
        this.urls = newUrls; // Make sure you have a urls data property in your parent component
    },
    handleAiIntroductionUpdate(shouldIntroduce) {
      this.configuration.showStartMessage = !shouldIntroduce; // showing start message actually means the start message is manual
    },
    handleWelcomeMessageUpdate(welcomeMessage) {
      this.childWelcomeMessage = welcomeMessage;
    },
    async completeWizard() {
      const token = localStorage.getItem('userToken');
      let data = {
        configurationId: this.configuration._id,
      }
      try {
          const response = await axios.post(`${this.configurationServer}/configuration/complete_wizard`, data, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          if (response.status === 200) {
            EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('wizard_completed') });
          } else {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + response.data });
          }
      }
      catch (error) {
        // Handle error
        //EventBus.$emit('show-snackbar', { type: 'ERROR', message: 'Error saving configuration' });
        console.error('Error saving configuration:', error);
      } finally {
        this.dialog = false;
        this.$emit('wizard-completed');

      }
    },
    changeLanguage(newLang) {
      this.$i18n.locale = newLang;
      this.$vuetify.lang.current = newLang;
      localStorage.setItem('userLanguage', newLang);
    },
    getFlagIcon(countryCode) {
      return `<span class="flag-icon flag-icon-${countryCode}"></span>`;
    },
  },
};
</script>

<style scoped>
.v-icon {
  font-size: 40px !important; /* Increase the size as needed */
}

.language-selector {
  position: absolute;
  right: 0px;
  margin-top: 15px;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .mobile-zoom{
    zoom: 90%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 85%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 80%
  }
}

@media (max-width: 500px) {
  .mobile-zoom{
    zoom: 75%
  }
}

@media (max-width: 450px) {
  .mobile-zoom{
    zoom: 70%
  }
}

@media (max-width: 400px) {
  .mobile-zoom{
    zoom: 65%
  }
}
</style>
