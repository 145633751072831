var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-preview-container"},[_c('div',{staticClass:"scrollable-step-content"},[_c('v-col',[_c('div',{staticClass:"chatbot-container",style:({
                    borderRadius: _vm.chatbotSettings.chatBlockBorderRadius + 'px',
                    background: _vm.chatbotSettings.chatBackgroundType === 'solid'
                                ? _vm.chatbotSettings.firstChatBackgroundColor
                                : _vm.chatbotSettings.chatBackgroundType === 'gradient'
                                ? `linear-gradient(0deg, ${_vm.chatbotSettings.firstChatBackgroundColor}, ${_vm.chatbotSettings.secondChatBackgroundColor})`
                                : `linear-gradient(rgba(255,255,255,${_vm.chatbotSettings.chatBackgroundImageTransparency}), rgba(255,255,255,${_vm.chatbotSettings.chatBackgroundImageTransparency})), url('${_vm.chatbotSettings.chatBackgroundImageSrc}') no-repeat center center / cover`,
                    width: _vm.chatbotSettings.chatbotWidth + 'px',
                    height: _vm.chatbotSettings.chatbotHeight + 'px'
                })},[_c('div',{staticClass:"chatbot-header",style:({
                        background: _vm.chatbotSettings.headerBackgroundColorType === 'solid'
                                    ? _vm.chatbotSettings.firstHeaderBackgroundColor
                                    : `linear-gradient(${_vm.chatbotSettings.headerGradientAngle}deg, ${_vm.chatbotSettings.firstHeaderBackgroundColor}, ${_vm.chatbotSettings.secondHeaderBackgroundColor})`,
                        borderTopLeftRadius: _vm.chatbotSettings.chatBlockBorderRadius + 'px',
                        borderTopRightRadius: _vm.chatbotSettings.chatBlockBorderRadius + 'px'
                    })},[(_vm.chatbotSettings.includeBotAvatar)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.chatbotSettings.typeImageIcon === 'image')?_c('img',{staticClass:"chatbot-header-image",style:({ width: _vm.chatbotSettings.botImageIconSize + 'px', height: _vm.chatbotSettings.botImageIconSize + 'px' }),attrs:{"src":_vm.chatbotSettings.botImageSrc,"alt":"chatbot-avatar-image"}}):(_vm.chatbotSettings.typeImageIcon === 'icon')?_c('div',{style:({ width: _vm.chatbotSettings.botImageIconSize + 'px', height: _vm.chatbotSettings.botImageIconSize + 'px', backgroundColor: _vm.chatbotSettings.botIconBackgroundColor })},[_c('img',{style:({ width: _vm.chatbotSettings.botImageIconSize / 2 + 'px' }),attrs:{"src":_vm.chatbotSettings.botIconSrc,"alt":"chatbot-avatar-icon"}})]):_vm._e(),_c('div',{staticClass:"chatbot-header-text",style:({ fontSize: _vm.chatbotSettings.headerFontSize + 'px', color: _vm.chatbotSettings.headerTextColor, fontFamily: _vm.chatbotSettings.headerFontType })},[_c('h5',{staticClass:"m-0",style:({color: _vm.chatbotSettings.headerTextColor})},[(_vm.chatbotSettings.language == 'nl')?_c('span',[_vm._v("Chat met")]):_vm._e(),(_vm.chatbotSettings.language == 'en')?_c('span',[_vm._v("Chat with")]):_vm._e()]),_vm._v(" "+_vm._s(_vm.chatbotSettings.headerText)+" ")])]):_vm._e(),(_vm.chatbotSettings.enableOnlineMessage)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"chatbot-status pt-3"}),_c('span',{style:({color: _vm.chatbotSettings.onlineTextColor})},[_vm._v(_vm._s(_vm.chatbotSettings.onlineMessage))])]):_vm._e()]),_c('div',{staticClass:"chatbot-content",style:({ borderRadius: _vm.chatbotSettings.chatBlockBorderRadius + 'px'})},[(_vm.chatbotSettings.enableStaticStartMessage)?_c('div',{staticClass:"message-bubble bot"},[(_vm.chatbotSettings.typeImageIcon == 'image' && _vm.chatbotSettings.showChatImages)?_c('img',{staticClass:"me-1",style:({
                                width: _vm.chatbotSettings.avatarChatSize + 'px',
                                height: _vm.chatbotSettings.avatarChatSize + 'px',
                            }),attrs:{"src":_vm.chatbotSettings.botImageSrc,"alt":"bot-avatar-image"}}):_vm._e(),(_vm.chatbotSettings.typeImageIcon == 'icon' && _vm.chatbotSettings.showChatImages)?_c('img',{staticClass:"me-1",style:({
                                width: _vm.chatbotSettings.avatarChatSize + 'px',
                                height: _vm.chatbotSettings.avatarChatSize + 'px',
                            }),attrs:{"src":_vm.chatbotSettings.botIconSrc,"alt":"chatbot-avatar-icon"}}):_vm._e(),_c('div',[(_vm.chatbotSettings.showDateTime)?_c('span',{staticClass:"date bot",style:({
                                    fontSize: _vm.chatbotSettings.messageDateFontSize + 'px',
                                    color: _vm.chatbotSettings.datetimeColor
                                })},[_vm._v("12:30 ")]):_vm._e(),_c('div',{style:({
                                color: _vm.chatbotSettings.botFontColor,
                                fontSize: _vm.chatbotSettings.speechBubbleFontSize + 'px',
                                fontFamily: _vm.chatbotSettings.speechBubbleFontType,
                                borderRadius: _vm.chatbotSettings.speechBubbleBorderRadius + 'px',
                                backgroundImage: 'linear-gradient(145deg, ' + _vm.chatbotSettings.speechBubbleBotBackgroundFirstColor + ', ' + (_vm.chatbotSettings.speechBubbleBotBackgroundColorType === 'solid' ? _vm.chatbotSettings.speechBubbleBotBackgroundFirstColor : _vm.chatbotSettings.speechBubbleBotBackgroundSecondColor) + ')',
                            })},[_vm._v(_vm._s(_vm.chatbotSettings.startMessage)+" ")])])]):_vm._e(),_c('div',{staticClass:"message-bubble user"},[_c('div',[(_vm.chatbotSettings.showDateTime)?_c('span',{staticClass:"date user",style:({
                                    fontSize: _vm.chatbotSettings.messageDateFontSize + 'px',
                                    color: _vm.chatbotSettings.datetimeColor })},[_vm._v(" 13:50 ")]):_vm._e(),_c('v-row',[_c('div',{staticClass:"text-white",style:({
                                        padding: '15px',
                                        color: _vm.chatbotSettings.userFontColor,
                                        fontSize: _vm.chatbotSettings.speechBubbleFontSize + 'px',
                                        fontFamily: _vm.chatbotSettings.speechBubbleFontType,
                                        borderRadius: _vm.chatbotSettings.speechBubbleBorderRadius + 'px',
                                        backgroundImage: 'linear-gradient(145deg, ' + _vm.chatbotSettings.speechBubbleUserBackgroundFirstColor + ', ' + (_vm.chatbotSettings.speechBubbleUserBackgroundColorType === 'solid' ? _vm.chatbotSettings.speechBubbleUserBackgroundFirstColor : _vm.chatbotSettings.speechBubbleUserBackgroundSecondColor) + ')'
                                    })},[_vm._v(_vm._s(_vm.chatbotSettings.messageText)+" ")]),(_vm.chatbotSettings.showChatImages)?_c('div',{staticClass:"message-bubble user-avatar",style:({
                                        width: _vm.chatbotSettings.avatarChatSize/1 + 'px',
                                        height: _vm.chatbotSettings.avatarChatSize/1 + 'px',
                                        backgroundColor: _vm.chatbotSettings.userAvatarBackgroundColor,
                                        borderColor: _vm.chatbotSettings.userAvatarBorderColor
                                    })},[_c('i',{staticClass:"fa-solid fa-user",style:({
                                            color: _vm.chatbotSettings.userAvatarIconColor,
                                            fontSize: _vm.chatbotSettings.userAvatarIconSize + 'px'
                                        })})]):_vm._e()])],1)]),_c('div',{staticClass:"message-bubble bot"},[(_vm.chatbotSettings.typeImageIcon == 'image' && _vm.chatbotSettings.showChatImages)?_c('img',{staticClass:"me-1",style:({
                                width: _vm.chatbotSettings.avatarChatSize + 'px',
                                height: _vm.chatbotSettings.avatarChatSize + 'px',
                            }),attrs:{"src":_vm.chatbotSettings.botImageSrc,"alt":"bot-avatar-image"}}):_vm._e(),(_vm.chatbotSettings.typeImageIcon == 'icon' && _vm.chatbotSettings.showChatImages)?_c('img',{staticClass:"me-1",style:({
                                width: _vm.chatbotSettings.avatarChatSize + 'px',
                                height: _vm.chatbotSettings.avatarChatSize + 'px',
                            }),attrs:{"src":_vm.chatbotSettings.botIconSrc,"alt":"chatbot-avatar-icon"}}):_vm._e(),_c('div',[(_vm.chatbotSettings.showDateTime)?_c('span',{staticClass:"date bot",style:({
                                    fontSize: _vm.chatbotSettings.messageDateFontSize + 'px',
                                    color: _vm.chatbotSettings.datetimeColor
                                })},[_vm._v("12:39 ")]):_vm._e(),_c('div',{style:({
                                color: _vm.chatbotSettings.botFontColor,
                                fontSize: _vm.chatbotSettings.speechBubbleFontSize + 'px',
                                fontFamily: _vm.chatbotSettings.speechBubbleFontType,
                                borderRadius: _vm.chatbotSettings.speechBubbleBorderRadius + 'px',
                                backgroundImage: 'linear-gradient(1455deg, ' + _vm.chatbotSettings.speechBubbleBotBackgroundFirstColor + ', ' + (_vm.chatbotSettings.speechBubbleBotBackgroundColorType === 'solid' ? _vm.chatbotSettings.speechBubbleBotBackgroundFirstColor : _vm.chatbotSettings.speechBubbleBotBackgroundSecondColor) + ')',
                            })},[_vm._v(_vm._s(_vm.chatbotSettings.messageText)+" ")])])]),_c('div',{staticClass:"loading-dots"},[_c('span',{style:({backgroundColor: _vm.chatbotSettings.sendLoadingDotsIconColor})}),_c('span',{style:({backgroundColor: _vm.chatbotSettings.sendLoadingDotsIconColor})}),_c('span',{style:({backgroundColor: _vm.chatbotSettings.sendLoadingDotsIconColor})})])]),_c('div',{staticClass:"chatbot-footer",style:({
                    'border-bottom-left-radius': `${_vm.chatbotSettings.chatBlockBorderRadius}px`,
                    'border-bottom-right-radius': `${_vm.chatbotSettings.chatBlockBorderRadius}px`,
                    'background': _vm.chatbotSettings.footerBackgroundColorType !== 'none'
                        ? `linear-gradient(${_vm.chatbotSettings.footerBackgroundColorType === 'solid' ? '0' : _vm.chatbotSettings.footerGradientAngle}deg, ${_vm.chatbotSettings.firstFooterBackgroundColor}, ${_vm.chatbotSettings.footerBackgroundColorType === 'solid' ? _vm.chatbotSettings.firstFooterBackgroundColor : _vm.chatbotSettings.secondFooterBackgroundColor})`
                        : 'none'
                    })},[_c('textarea',{style:({
                        'margin-top': '25px',
                        'overflow-y': 'hidden',
                        'margin-left': 'auto',
                        'margin-right': 'auto',
                        'width': `${_vm.chatbotSettings.userInputWidth}%`,
                        'height': `${_vm.chatbotSettings.userInputHeight}px`,
                        'font-size': `${_vm.chatbotSettings.userInputFontSize}px`,
                        'border-radius': `${_vm.chatbotSettings.userInputBorderRadius}px`,
                        'background-color': _vm.chatbotSettings.userInputBackgroundColor
                        }),attrs:{"placeholder":_vm.chatbotSettings.userInputPlaceholderText}}),_c('div',{staticClass:"send-button-container",style:({
                                    'background': _vm.chatbotSettings.sendButtonBackgroundColorType !== 'none'
                                    ? `linear-gradient(${_vm.chatbotSettings.sendButtonBackgroundColorType === 'solid' ? '0' : _vm.chatbotSettings.footerGradientAngle}deg, ${_vm.chatbotSettings.sendButtonBackgroundFirstColor}, ${_vm.chatbotSettings.sendButtonBackgroundColorType === 'solid' ? _vm.chatbotSettings.sendButtonBackgroundFirstColor : _vm.chatbotSettings.sendButtonBackgroundSecondColor})`
                                    : 'none'
                                })},[_c('div',{staticClass:"send-button"},[_c('i',{staticClass:"fa-solid fa-paper-plane",style:({
                                'color': _vm.chatbotSettings.sendButtonIconColor, 'fontSize': '16px' })})])]),(_vm.chatbotSettings.showPoweredBy)?_c('div',{staticClass:"powered-by-container"},[_c('a',{staticClass:"powered-by-text",style:({ textDecoration: 'none' }),attrs:{"href":"http://www.hosthub.ai","target":"_blank"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"pt-3",style:({ color: _vm.chatbotSettings.poweredByTextColor })},[_vm._v("Powered by")]),_c('img',{staticStyle:{"width":"150px","height":"43px"},attrs:{"src":_vm.chatbotSettings.poweredByImageSrc}})])])]):_vm._e()])]),_c('h2',{staticClass:"text-center",staticStyle:{"margin-top":"20px"}},[_vm._v("Button and bubble preview")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('div',{attrs:{"id":"intro-bubble-container"}},[(_vm.chatbotButtonSettings.showChatbuttonBubble)?_c('div',{staticStyle:{"position":"relative","padding":"10px"}},[_c('div',{ref:"bubbleContainer",style:({
                                    position: 'absolute',
                                    top: _vm.chatbotButtonSettings.chatbuttonBubbleTopPosition + 'px',
                                    right: _vm.chatbotButtonSettings.chatbuttonBubbleRightPosition + 'px',
                                    width: _vm.chatbotButtonSettings.chatbuttonBubbleWidth + 'px',
                                    background: _vm.chatbotButtonSettings.chatButtonBubbleBackgroundColorType === 'solid'
                                    ? _vm.chatbotButtonSettings.firstChatbuttonBubbleBackgroundColor
                                    : `linear-gradient(90deg, ${_vm.chatbotButtonSettings.firstChatbuttonBubbleBackgroundColor} 0%, ${_vm.chatbotButtonSettings.secondChatbuttonBubbleBackgroundColor} 75%)`,                                                                               
                                    color: _vm.chatbotButtonSettings.chatbuttonBubbleTextColor,
                                    padding: '20px',
                                    borderRadius: '25px',
                                    fontFamily: 'Arial, sans-serif'
                                })},[_c('div',{staticStyle:{"font-size":"16px","margin-bottom":"8px"}},[_vm._v(" "+_vm._s(_vm.chatbotButtonSettings.chatbuttonBubbleText)+" ")]),_c('div',{style:({
                                        position: 'absolute',
                                        bottom: '-15px',
                                        right: '10px',
                                        width: '40px',
                                        height: '30px',
                                        'background-color': _vm.chatbotButtonSettings.chatButtonBubbleBackgroundColorType === 'solid' 
                                        ? _vm.chatbotButtonSettings.firstChatbuttonBubbleBackgroundColor : _vm.chatbotButtonSettings.secondChatbuttonBubbleBackgroundColor,
                                        'clip-path': 'polygon(50% 100%, 0 0, 100% 0)'
                                    })}),_c('button',{style:({
                                        position: 'absolute',
                                        top: '-10px',
                                        right: '-10px',
                                        width: '30px',
                                        height: '30px',
                                        'border-radius': '50%',
                                        border: 'none',
                                        'background-color': _vm.chatbotButtonSettings.chatbuttonBubbleCloseBtnColor,
                                        color: 'white',
                                        'font-size': '16px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        'align-items': 'center',
                                        'justify-content': 'center'
                                        }),attrs:{"onclick":"this.parentElement.style.display='none';"}},[_c('strong',[_vm._v("x")])])])]):_vm._e()]),_c('div',{style:({
                                border: _vm.chatbotButtonSettings.borderWidth + 'px solid ' + _vm.chatbotButtonSettings.borderColor,
                                boxShadow: '0 0 ' + _vm.chatbotButtonSettings.shadowWidth + 'px ' + _vm.chatbotButtonSettings.shadowColor,
                                width: _vm.chatbotButtonSettings.buttonWidth + 'px',
                                height: _vm.chatbotButtonSettings.buttonHeight + 'px',
                                borderRadius: _vm.chatbotButtonSettings.buttonBorderRadius + '%',
                                backgroundImage: 'linear-gradient(to right, ' + _vm.chatbotButtonSettings.buttonBackgroundColor + ', ' + _vm.chatbotButtonSettings.buttonBackgroundColorB + ')',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }),attrs:{"id":"chatbot-button"}},[_c('i',{staticClass:"fa-solid fa-message",style:({
                                fontSize: _vm.chatbotButtonSettings.iconSize + 'px',
                                color: _vm.chatbotButtonSettings.iconColor
                            })})])])],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }