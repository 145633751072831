<template>
  <v-container>
    <v-card flat>
      <v-row no-gutters justify="center">
        <v-col cols="12">
          <v-row no-gutters justify="center">           
            <v-radio-group v-model="mode" row>
              <v-radio :label="$t('websites')" value="website"></v-radio>
              <v-radio :label="$t('documents')" value="document"></v-radio>
              <v-radio :disabled="!selectedConfiguration.rag_products" :label="$t('webshop_title')" value="webshop"></v-radio>
            </v-radio-group>          
          </v-row>
          <div v-if="mode === 'website' || mode === 'document'">
            <v-row v-if="mode === 'website'" no-gutters justify="center">
              <v-text-field dense
                v-model="url"
                outlined
                :label="$t('url')"
                :disabled="extractedItems.length > 0 || scrapingSitemapURLs"
              ></v-text-field>
              <div class="pt-1 pl-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="!url || extractedItems.length > 0 || scrapingSitemapURLs" icon small v-bind="attrs" v-on="on" @click="startScrape">
                      <v-icon>mdi-crosshairs</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('scrape_website_text')}}</span>
                </v-tooltip>
              </div>            
              <v-spacer></v-spacer>
              <v-checkbox dense
                v-model="scrapeSitemap"
                hide-details="true"
                :label="$t('scrape_sitemap')"
                :disabled="extractedItems.length > 0 || scrapingSitemapURLs">
              </v-checkbox>
            </v-row>         
            <v-row no-gutters>
              <v-col cols="6" v-if="mode == 'document'">
                <v-card>
                  <v-overlay :value="loadingfiles" absolute>
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>
                  <div class="tableLayout">
                    <div>
                      <h5 class="header-title">{{$t('uploaded_documents')}}<span v-if="uploadedDocuments.length > 0">({{ uploadedDocuments.length }})</span></h5>
                    </div>
                    <div class="filter-controls">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- Trigger file input click on button click -->
                          <v-btn icon small v-bind="attrs" v-on="on" @click="triggerFileUpload">
                            <v-icon>mdi-upload</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('upload_document')}}</span>
                      </v-tooltip>
                      <!-- Hidden file input -->
                      <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none" />
                    </div>
                  </div>
                  <v-simple-table dense fixed-header height="15vh" style="border: 1px solid black;">
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(item, index) in uploadedDocuments" :key="index">
                          <td><div class="truncatedText">{{ item.name }}</div></td>
                          <td class="align-right">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" @click="scrapeDocument(item.name)">
                                  <v-icon>mdi-share-all</v-icon>
                                </v-btn>
                              </template>
                              <span>{{$t('scrape_document')}}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on" @click="deleteFile(item.name)">
                                    <v-icon>mdi-trash-can</v-icon>
                                  </v-btn>
                                </template>
                              <span>{{$t('delete_document')}}</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
              <!-- EXTRACTED ITEMS -->
              <v-col cols="6" v-if="!scrapeSitemap && mode == 'website'">
                <div class="tableLayout">
                  <div>
                    <h5 class="header-title">{{$t('extracted_items')}}<span v-if="extractedItems.length > 0">({{ extractedItems.length }})</span></h5>
                  </div>
                  <div class="filter-controls">
                    <v-text-field  :disabled="extractedItems.length === 0"
                      v-model="wordCountThreshold"
                      type="number"
                      dense
                      hide-details
                      :placeholder="$t('min_word_count')"
                      class="word-count-input"
                    ></v-text-field>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="pr-8" :disabled="extractedItems.length === 0" icon small v-bind="attrs" v-on="on" @click="filterExtractedItems">
                          <v-icon>mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('min_word_count_text')}}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="pr-5" :disabled="extractedItems.length === 0" icon small v-bind="attrs" v-on="on" @click="moveAllExtractedToKept">
                          <v-icon>mdi-share-all</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('keep_all_items')}}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="extractedItems.length === 0" icon small v-bind="attrs" v-on="on" @click="extractedItems = []">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('delete_all_other_items')}}</span>                    
                    </v-tooltip>
                  </div>
                </div>
                <v-simple-table class="itemTable" dense fixed-header height="15vh" style="border: 1px solid black;">
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(item, index) in extractedItems" :key="index" @click="moveToKeptList(index)" @contextmenu.prevent="removeItem(index, 'extractedItems')">
                        <td><div class="truncatedText">{{ item }}</div></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-col>
              <!-- SITEMAP URLS -->
              <v-col cols="6" v-if="scrapeSitemap">
                <v-card>
                  <v-overlay :value="scrapingSitemapURLs" absolute>
                    <v-progress-circular indeterminate size="64">{{scrapingProgressText}}</v-progress-circular>
                  </v-overlay>
                  <div class="tableLayout">
                    <div>
                      <h5 class="header-title">{{$t('urls_in_sitemap')}}<span v-if="sitemapUrls.length > 0">({{ sitemapUrls.length }})</span></h5>
                    </div>
                    <div class="filter-controls">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="pr-8" :disabled="sitemapUrls.length === 0" icon small v-bind="attrs" v-on="on" @click="toggleSelectSiteMapUrls">
                            <v-icon>mdi-checkbox-multiple-marked-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('select_deselect_urls')}}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="pr-5" :disabled="sitemapUrls.length === 0" icon small v-bind="attrs" v-on="on" @click="sitemapUrls = []">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('delete_url_objects')}}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="sitemapUrls.length === 0" icon small v-bind="attrs" v-on="on" @click="scrapeSitemapUrls">
                            <v-icon>mdi-sitemap</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('create_items_from_selected_urls')}}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon small v-bind="attrs" v-on="on" @click="addUrlsDialog = true">
                            <v-icon>mdi-plus-box</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('add_urls')}}</span>
                      </v-tooltip>
                    </div>
                  </div>

                  <v-simple-table class="itemTable" dense fixed-header height="15vh" style="border: 1px solid black;">
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(item, index) in sitemapUrls" :key="index">
                          <td @click="toggleEditingSitemapUrl(index)" v-if="!item.editing">
                            <v-tooltip top class="custom-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <!-- Toggle between input field and plain text based on editing state -->
                                <div v-if="!item.editing" class="truncatedText" v-bind="attrs" v-on="on">
                                  {{ item.url }}
                                </div>
                                <input v-else v-model="item.url" class="url-input">
                              </template>
                              <span>{{ item.url }}</span>
                            </v-tooltip>
                          </td>
                          <td>
                            <v-text-field v-if="item.editing"
                                @blur="item.editing = false"                               
                                @keyup.enter="item.editing = false"
                                v-model="item.url"                                 
                                dense
                                :label="$t('url')"
                                hide-details="auto"
                              ></v-text-field>
                          </td>
                          <td>
                            <v-row>
                              <v-checkbox class="pt-4" dense
                                v-model="item.selected"
                                :input-value="item.selected"
                                @click.stop
                              ></v-checkbox>
                            </v-row>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <!-- add urls popup -->
                  <v-dialog v-model="addUrlsDialog" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{$t('add_urls')}}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-textarea
                          v-model="urlsToAdd"
                          label="Paste URLs (one per line)"
                          rows="10"
                        ></v-textarea>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialog = false">{{$t('cancel')}}</v-btn>
                        <v-btn color="blue darken-1" text @click="addUrls">{{$t('add')}}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </v-card>
                <v-row no-gutters class="pt-3">    
                  <v-col cols="5">
                    <v-text-field dense
                      v-model="filtered_classes"
                      outlined
                      :label="$t('filter_in_classes')"
                      :disabled="sitemapUrls.length === 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-row no-gutters>
                      <v-text-field dense
                        v-model="urls_to_remove_txt"
                        outlined
                        :label="$t('remove_urls_that_contain')"
                        :disabled="sitemapUrls.length === 0"
                      ></v-text-field>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="!urls_to_remove_txt" icon small v-bind="attrs" v-on="on" @click="removeUrlsThatContain()">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('delete_document')}}</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>            
                </v-row>
              </v-col>
              <!-- KEPT ITEMS -->
              <v-col cols="6">
                <v-card>
                  <v-overlay :value="processingArticles" absolute>
                    <v-progress-circular indeterminate size="64">{{articleProgressText}}</v-progress-circular>
                  </v-overlay>
                  <div class="tableLayout">
                    <div>
                      <h5 class="header-title">{{$t('items_for_article')}}</h5>
                    </div>
                    <div class="filter-controls">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="pr-5" icon small v-bind="attrs" v-on="on" @click="addBlankKeptItem">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('add_empty_item')}}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="keptItems.length < 2" icon small v-bind="attrs" v-on="on" @click="mergeKeptItems">
                            <v-icon>mdi-merge</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('merge_all_items_tt')}}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="keptItems.length < 2" icon small v-bind="attrs" v-on="on" @click="createArticles">
                            <v-icon>mdi-file-document-multiple-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('create_articles')}}</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <v-simple-table class="itemTable" dense fixed-header height="15vh" style="width:100%; border: 1px solid black;">
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(item, index) in keptItems" :key="index">
                          <td @click="editKeptItem(index)" @contextmenu.prevent="removeItem(index, 'keptItems')">
                            <v-tooltip top class="custom-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <div class="truncatedText" v-bind="attrs" v-on="on">{{ item.text }}</div>
                              </template>
                              <span>{{ item.text | truncate }}</span>
                            </v-tooltip>
                          </td>
                          <td>{{ getWordCount(item.text) }} {{$t('words')}}</td>
                          <td class="align-right">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="getWordCount(item.text) < 200" icon small v-bind="attrs" v-on="on" @click="createArticle(item)">
                                  <v-icon>mdi-file-document-arrow-right</v-icon>
                                </v-btn>
                              </template>
                              <span>{{$t('create_article')}}</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <!--Article Table -->
              <v-col cols="12">
                <v-card>
                  <v-overlay :value="loadingArticles" absolute>
                    <v-progress-circular indeterminate size="64">{{loadingArticlesText}}</v-progress-circular>
                  </v-overlay>
                  <div class="tableContainer">

                    <h5 class="tableHeader pl-4">
                      {{$t('articles')}} <span v-if="articles.length > 0">({{ articles.length }})</span>
                      <v-menu top offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltipOn }">
                              <v-btn icon small v-bind="attrs" v-on="{ ...on, ...tooltipOn }">
                                <v-icon color="white">mdi-dots-horizontal</v-icon>
                              </v-btn>
                            </template>
                          <span>{{$t('options')}}</span>
                          </v-tooltip>
                        </template>
                        <v-list>
                          <v-list-item @click="deleteAllArticles"  :disabled="!articles.length">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" :disabled="!articles.length">
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                              </template>
                            <span>{{$t('delete_all_articles')}}</span>
                            </v-tooltip>
                          </v-list-item>
                          <v-list-item @click="searchInArticlesDialog = true" :disabled="!articles.length">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" :disabled="!articles.length">
                                  <v-icon>mdi-find-replace</v-icon>
                                </v-btn>
                              </template>
                            <span>{{$t('search_and_replace')}}</span>
                            </v-tooltip>
                          </v-list-item>
                          <v-list-item @click="indexArticles" :disabled="!articlesToIndex.length">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" :disabled="!articlesToIndex.length">
                                  <v-icon>mdi-database-export</v-icon>
                                </v-btn>
                              </template>
                            <span>{{$t('index_all_articles')}}</span>
                            </v-tooltip>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </h5>

                    <v-simple-table dark dense fixed-header height="20vh">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th style="max-width: 15px !important;">{{$t('date_modified')}}</th>
                            <th style="max-width: 75px !important;">{{$t('content')}}</th>
                            <th style="max-width: 40px !important;">{{$t('source')}}</th>
                            <th style="max-width: 10px !important;">{{$t('number_of_words')}}</th>
                            <th style="max-width: 25px !important;">{{$t('indexed')}}</th>
                            <th style="max-width: 25px !important;">{{$t('actions')}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in articles" :key="index" @click="menu = index">
                            <td style="max-width: 15px !important;">{{ formatDate(item.date_modified) }}</td>
                            <td style="max-width: 75px !important;"><div>{{ truncateWords(item.content) }}</div></td>
                            <td style="max-width: 40px !important;">{{ item.source }}</td>
                            <td style="max-width: 10px !important;">{{ item.word_count }}</td>
                            <td style="max-width: 25px !important;">
                              <v-row style="background-color: transparent;">
                                <v-col style="background:transparent;" cols="12" class="d-flex justify-center">
                                  <v-icon v-if="item.is_indexed" color="green">mdi-check</v-icon>
                                </v-col>
                                <v-col cols="12" v-if="!item.is_indexed">
                                  <v-text-field
                                    v-model="item.splitLength"
                                    type="number"
                                    dense
                                    :label="$t('split_length')"
                                    hide-details="auto"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" v-if="!item.is_indexed">
                                  <v-text-field
                                    v-model="item.splitOverlap"
                                    type="number"
                                    dense
                                    :label="$t('overlap')"
                                    hide-details="auto"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </td>

                            <td style="max-width: 25px !important;">
                              <!-- Menu button -->
                              <v-menu offset-y top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item @click="openArticle(item)">
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn  icon small v-bind="attrs" v-on="on">
                                          <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{$t('open_article')}}</span>
                                    </v-tooltip>
                                  </v-list-item>
                                  <v-list-item @click="deleteArticle(item)">
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon small v-bind="attrs" v-on="on">
                                          <v-icon color="primary">mdi-trash-can</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{$t('delete_article')}}</span>
                                    </v-tooltip>
                                  </v-list-item>
                                  <v-list-item v-if="!item.is_indexed" @click="indexArticle(item)">
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn  icon small v-bind="attrs" v-on="on">
                                          <v-icon color="primary">mdi-database-export</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{$t('index_article')}}</span>
                                    </v-tooltip>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn width="200px" @click="openUrlsDialog">
                <span v-if="!loadingUrls">{{ urlObjects.length }} {{$t('found_urls')}}</span>
                <span v-if="loadingUrls">...</span>
              </v-btn>
            </v-row>
          </div>          
          <WebshopSettings v-if="mode === 'webshop'"></WebshopSettings>
        </v-col>
      </v-row>
    </v-card>
    <!-- KEPT ITEM DIALOG -->    
    <v-dialog v-model="isKeptItemDialogOpen" persistent max-width="70vw">
    <v-card>
      <v-card-title class="justify-center grey primary white--text">
        {{$t('modify_item')}}
      </v-card-title>
      <v-card-text class="pa-4">
        <div style="max-height: 600px; overflow-y: auto;" ref="contentArea">
          <!-- Content area here -->
          <div
            contenteditable="true"
            v-html="highlightedText"
            @input="updateSelectedKeptItemText"
            class="editable pa-2"
            style="min-height: 100px; background-color: #fff;"
          ></div>
        </div>
        <v-text-field
          :label="$t('source')"
          v-model="selectedKeptItemSource"
          class="pt-2"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-row class="justify-center">
          <v-col cols="12" class="d-flex justify-space-between">
            <div>
              <v-btn color="primary" @click="saveKeptItem">{{$t('save')}}</v-btn>
              <v-btn color="secondary" @click="isKeptItemDialogOpen = false">{{$t('cancel')}}</v-btn>
            </div>
            <v-text-field
              :label="$t('search')"
              v-model="searchTerm"
              @input="highlightText"
              class="mt-0 pt-0"
              outlined dense
              hide-details
            ></v-text-field>
            <div>
              <v-btn icon @click="goToPrevResult" :disabled="currentResultIndex === 0">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon @click="goToNextResult" :disabled="currentResultIndex >= searchResults.length - 1 || searchResults.length === 0">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <!-- FOUND URLS DIALOG -->
    <v-dialog v-model="isUrlDialogOpen" persistent max-width="70vw">
      <v-card>
        <v-card-title class="justify-center grey primary white--text">
          {{$t('registered_urls')}}
        </v-card-title>
        <v-card-text class="pa-4" style="max-height: 600px; overflow-y: auto;">
          <v-simple-table dense fixed-header height="300">
            <template v-slot:default>
              <thead>
                <tr>
                  <th><strong>{{$t('url')}}</strong></th>
                  <th><strong>{{$t('subject')}}</strong></th>
                  <th>
                    <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div>
                        <v-btn :disabled="isEditingUrl" icon small v-bind="attrs" v-on="on" @click="addBlankUrlObject">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn class="pl-3" icon small @click="deleteAllUrlObjects()" :disabled="isEditingUrl">
                          <v-icon color="primary">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{$t('add_url_manually')}}</span>
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in urlObjects" :key="index">
                  <td >
                    <div style="width:350px;" v-if="!item.editing">
                      <a :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.url }}</a>
                    </div>
                    <v-text-field
                      v-else
                      v-model="item.url"
                      dense
                    ></v-text-field>
                  </td>
                  <td style="width:200px;">
                    <div  v-if="!item.editing">{{ item.subject }}</div>
                    <v-text-field
                      v-else
                      v-model="item.subject"
                      dense
                    ></v-text-field>
                  </td>
                  <td>
                    <v-btn :disabled="isEditingUrl" v-if="!item.editing" icon small @click="startEditingUrl(item, index)">
                      <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>
                     <v-btn v-if="item.editing" icon small @click="stopEditingUrl(item, index)">
                      <v-icon color="green">mdi-check</v-icon>
                    </v-btn>
                    <v-btn class="pl-3" icon small @click="deleteUrlObject(index)" :disabled="isEditingUrl">
                      <v-icon color="primary">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn v-if="hasUrlChanges" :disabled="isEditingUrl" color="primary" @click="saveUrlObjects">{{$t('save')}}</v-btn>
          <v-btn v-if="hasUrlChanges" color="secondary" @click="cancelUrlObjectChanges">{{$t('cancel')}}</v-btn>
          <v-btn v-if="!hasUrlChanges" color="primary" @click="isUrlDialogOpen = false">{{$t('close')}}</v-btn>
          <v-btn :disabled="hasUrlChanges || urlObjects.length == 0" color="secondary" @click="copyUrlsToKeptItem">{{$t('copy_to_article')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE ARTICLE -->
    <v-dialog v-model="deleteArticleDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="justify-center grey primary white--text">
          {{$t('delete_article')}}
        </v-card-title>
        <v-card-text class="pl-6 pt-4"><v-label>{{ deleteArticleText }}</v-label></v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="deleteArticleDialog = false">{{$t('cancel')}}</v-btn>
          <v-btn color="secondary" @click="confirmDeleteArticle">{{$t('delete')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE ARTICLES -->
    <v-dialog v-model="deleteArticlesDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="justify-center grey primary white--text">
          {{$t('delete_articles')}}
        </v-card-title>
        <v-card-text class="pl-6 pt-4"><v-label>{{ deleteArticlesText }}</v-label></v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="deleteArticlesDialog = false">{{$t('cancel')}}</v-btn>
          <v-btn color="secondary" @click="confirmDeleteArticles">{{$t('delete')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SEARCH IN ARTICLES -->
    <v-dialog v-model="searchInArticlesDialog" persistent max-width="85vw">
      <v-card>
        <v-card-title class="justify-center grey primary white--text">
          {{$t('search_in_articles')}}
        </v-card-title>
        <v-row class="pt-5 pb-5 justify-center">        
          <v-col cols="5">
            <v-row>
              <v-text-field 
                v-model="searchArticlesText"                                 
                dense
                :label="$t('search_string')"
                hide-details="auto"
              ></v-text-field>
              <div class="pl-5">
                <v-btn :disabled="!searchArticlesText" color="secondary" @click="searchInArticles">{{$t('search')}}</v-btn>
              </div>
            </v-row>
          </v-col>          
        </v-row>
        <v-row v-if="foundArticles.length > 0" class="justify-center">
            <v-col cols="12">
              <v-card class="mb-2" v-for="(article, index) in foundArticles" :key="index">
                  <v-card-title class="justify-center" style="font-size:1.3em; font-weight: bold;">
                      Article ID: {{ article._id }}
                  </v-card-title>
                  <v-card-title class="justify-center" style="font-size:1.3em; font-weight: bold;">
                      Source: {{ article.source }}
                  </v-card-title>
                  <v-card-text v-for="(block, blockIndex) in article.content_blocks" :key="'block-' + blockIndex">
                      <div v-html="highlightTextBlock(block)"></div>                     
                  </v-card-text>
              </v-card>
            </v-col>
        </v-row>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="searchInArticlesDialog = false">{{$t('close')}}</v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
    import { EventBus } from '../../eventBus.js';
    import WebshopSettings from './datatraining/webshopSettings.vue';
    import axios from 'axios';
    export default {
        name: 'DataTrainingTab',
        components: {
          WebshopSettings
        },
        props: {
          configurationId: String
        },
        data: () => ({
          mode: "website",
          scrapeSource: "",
          scrapeSitemap: false,
          scrapingSitemapURLs: false,
          scrapingProgressText: '',
          articleProgressText: '',
          processingArticles: false,
          loadingArticles: false,
          loadingArticlesText: '',
          loadingfiles: false,
          sitemapUrls: [],
          uploadedDocuments: [],
          url: null,
          urlObjects: [],
          initialUrlObjects: null,
          wordCountThreshold: 10,
          isKeptItemDialogOpen: false,
          isUrlDialogOpen: false,
          isEditingUrl: false,
          loadingUrls: false,
          selectedKeptItemIndex: null,
          selectedKeptItemText: '',
          selectedKeptItemSource: '',
          searchTerm: '',
          highlightedText: '',
          searchResults: [],
          currentResultIndex: -1,
          deleteArticleDialog: false, // single article
          deleteArticlesDialog: false,// all articles
          searchInArticlesDialog: false,
          foundArticles: [],
          searchArticlesText: '',
          deleteArticleText: '',
          deleteArticlesText: '',
          articleToDelete: null,
          tags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'a', 'img', 'ul', 'ol', 'li', 'div', 'span', 'table', 'tr', 'td', 'th', 'button', 'input', 'textarea', 'form', 'src'],
          extractedItems: [],
          keptItems: [],
          articles: [], 
          filtered_classes: '',
          urls_to_remove_txt: '', 
          addUrlsDialog: false,
          urlsToAdd: ''
        }),
        filters: {
          truncate(text, length = 100) {
            const words = text.split(' ');
            if (words.length > length) {
              return words.slice(0, length).join(' ') + '...';
            }
            return text;
          }
        },
        computed: {
          selectedConfiguration: {
            get() {
              return this.$store.getters.selectedConfiguration;
            }
          },
          articlesToIndex() {
            return this.articles.filter(article => !article.is_indexed);
          },
          hasUrlChanges() {
            const result = JSON.stringify(this.urlObjects) !== JSON.stringify(this.initialUrlObjects);
            return result;
          }
        },
        mounted() {
          this.initialize();
        },
        created() {
          EventBus.$on('set-configuration', () => {
            this.initialize();
          });
        },
        watch: {
          selectedKeptItemText() {
            // Update highlighted text when the item text changes
            this.highlightText();
          }
        },
        methods: {
          async initialize() {
            this.url = '';
            this.urlObjects = [];
            this.sitemapUrls = [];
            this.extractedItems = [];
            this.keptItems = [];
            this.articles = [];
            this.uploadedDocuments = [];
            this.getUrlObjects();
            this.getArticles();
            this.getFileNames();
          },
          moveToKeptList(index) {
            const itemText = this.extractedItems.splice(index, 1)[0];
            const keptItem = {
                text: itemText,
                source: this.scrapeSource
            };
            this.keptItems.push(keptItem);
          },
          removeItem(index, fromArray) {
            this[fromArray].splice(index, 1); // Remove the item based on index and array name
          },
          filterExtractedItems() {
            this.extractedItems = this.extractedItems.filter(item => {
              const wordCount = item.split(' ').length;
              return wordCount >= this.wordCountThreshold;
            });
          },
          mergeKeptItems() {
            if (this.keptItems.length > 0) {
                const mergedText = this.keptItems.map(item => item.text).join(' ');
                const mergedSource = this.keptItems[0].source;

                this.keptItems = [{
                    text: mergedText,
                    source: mergedSource
                }];
            }
          },
          editKeptItem(index) {
            this.selectedKeptItemIndex = index;
            this.selectedKeptItemText = this.keptItems[index].text;
            this.selectedKeptItemSource = this.keptItems[index].source;
            this.isKeptItemDialogOpen = true;
          },
          removeEmptyLines() {
            this.selectedKeptItemText = this.selectedKeptItemText
              .split('\n') // Split the text into lines
              .filter(line => line.trim() !== '') // Remove any lines that are empty or contain only whitespace
              .join('\n'); // Join the remaining lines back into a single string
          },
          saveKeptItem() {
            if (this.selectedKeptItemIndex !== null) {
                // Directly update the text and source of the item at the selected index
                this.keptItems[this.selectedKeptItemIndex].text = this.selectedKeptItemText;
                this.keptItems[this.selectedKeptItemIndex].source = this.selectedKeptItemSource; // Also update the source

                this.isKeptItemDialogOpen = false;
                // Reset editing state if necessary
                this.selectedKeptItemIndex = null;
                this.selectedKeptItemText = '';
                this.selectedKeptItemSource = '';
            }
          },
          updateSelectedKeptItemText(event) {
            this.selectedKeptItemText = event.target.innerText;
          },
          highlightTextBlock(block) {
            const searchText = this.searchArticlesText;
            const re = new RegExp(`(${searchText})`, 'gi'); // Global and case-insensitive matching
            return block.replace(re, '<span style="background-color: yellow; font-weight:bold; font-size:1.1em;">$1</span>');
          },
          highlightText() {
            this.searchResults = []; // Reset search results
            this.currentResultIndex = -1; // Reset current result index
            if (!this.searchTerm) {
              this.highlightedText = this.selectedKeptItemText;
              return;
            }
            const regex = new RegExp(`(${this.searchTerm})`, 'gi');
            let index = 0;
            this.highlightedText = this.selectedKeptItemText.replace(regex, (match) => {
              // For each match, push the index to searchResults and increment the index
              this.searchResults.push(index++);
              // Return the matched text wrapped in a span with inline styling for highlighting
              return `<span style="background-color: yellow;">${match}</span>`;
            });

            if (this.searchResults.length > 0) {
              this.currentResultIndex = 0; // Set to the first result
              this.$nextTick(() => {
                this.scrollToCurrentResult(); // Scroll to the first result after rendering
              });
            }
          },
          goToNextResult() {
            if (this.currentResultIndex < this.searchResults.length - 1) {
              this.currentResultIndex++;
              this.scrollToCurrentResult();
            }
          },
          goToPrevResult() {
            if (this.currentResultIndex > 0) {
              this.currentResultIndex--;
              this.scrollToCurrentResult();
            }
          },
          scrollToCurrentResult() {
            const highlights = this.$refs.contentArea.querySelectorAll('.highlight');
            if (highlights[this.currentResultIndex]) {
              highlights[this.currentResultIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
              // Optional: add focus or additional highlighting for the current search result
            }
          },
          addBlankKeptItem() {
            this.keptItems.push({
              text: this.$t('modify_this_item'),
              source: 'manual'
            });
          },
          openUrlsDialog() {
            this.isUrlDialogOpen = true;
          },
          async getUrlObjects() {
            this.loadingUrls = true;
            const token = localStorage.getItem('userToken');
            const configurationId = this.selectedConfiguration._id;
            try {
                const url = `${this.$config.configurationServer}/training/urls/get?configurationId=${encodeURIComponent(configurationId)}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                this.urlObjects = response.data;
                this.initialUrlObjects = JSON.parse(JSON.stringify(this.urlObjects));
            } catch (error) {
                console.error("Failed to fetch URLs:", error);
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('urls_fetch_failed') + error });
            } finally {
              this.loadingUrls = false;
            }
          },
          async removeUrlsThatContain() {
              console.log('*** remove all urls that contain: ', this.urls_to_remove_txt);
              const urlsToRemove = this.urls_to_remove_txt.split('\n').map(url => url.trim());              
           
              this.sitemapUrls = this.sitemapUrls.filter(obj => 
                  !urlsToRemove.some(removalText => obj.url.includes(removalText))
              );
          },
          async saveUrlObjects() {
            const token = localStorage.getItem('userToken');
            const data = {
                configurationId: this.selectedConfiguration._id,
                urls: this.urlObjects
            };

            try {
              await axios.post(`${this.$config.configurationServer}/training/urls/save`, data, {
                  headers: {
                      'Authorization': `Bearer ${token}`
                  }
              });
              EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('urls_stored')});
              this.getUrlObjects();
            } catch (error) {
                console.error("Failed to save URLs:", error);
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('urls_saved_failed') + error });
            } finally {
                this.isUrlDialogOpen = false;
            }
          },
          async copyUrlsToKeptItem() {
            // Combine URLs and their descriptions into a single string
            // Each entry is formatted as "[Description] (URL)"
            const combinedUrlsText = this.urlObjects
              .map(urlObj => `[${urlObj.subject}] (${urlObj.url})`)
              .join('\n');

            // Create a new kept item with these URLs and descriptions
            const newKeptItem = {
              text: combinedUrlsText,
              source: 'URLs', // This could be a default or dynamic source identifier
              // Include any additional properties here as per your application requirements
            };

            // Add this new kept item to your keptItems array
            this.keptItems.push(newKeptItem);

            // Notify user of success
            EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: 'URLs moved to kept items' });

            // Optionally, close the URL dialog if it's open
            this.isUrlDialogOpen = false;
          },
          cancelUrlObjectChanges() {
            this.urlObjects = JSON.parse(JSON.stringify(this.initialUrlObjects));
            this.isUrlDialogOpen = false;
            this.isEditingUrl = false;
          },
          async getUrlsFromSitemap() {
            const token = localStorage.getItem('userToken');
            var data = {
              url: this.url
            };

            try {
              const response = await axios.post(`${this.$config.configurationServer}/training/scrape_sitemap`, data, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              });

              if (response.data && Array.isArray(response.data.data)) {
                const newUrls = response.data.data.filter(url => !this.sitemapUrls.some(sitemapUrl => sitemapUrl.url === url));
                const newUrlsObjects = newUrls.map(url => ({
                  url: url,
                  selected: true, 
                  editing: false
                }));
                this.sitemapUrls = [...this.sitemapUrls, ...newUrlsObjects];
              } else {
                console.log('Unexpected response format:', response.data);
              }

            } catch (error) {
              console.error('Axios error response:', error.response);
              const errorMessage = error.response && error.response.data && error.response.data.error
                ? error.response.data.error.error
                : this.$t('error_scraping_sitemap');
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: errorMessage });
            }
          },
          async scrapeWebsite(url) {
            const token = localStorage.getItem('userToken');
            const data = {
              tags: this.tags,
              url: url,
              filtered_classes: this.filtered_classes
            };

            try {
              const response = await axios.post(`${this.$config.configurationServer}/training/scrape_static_website`, data, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              });

              const subjectUrlMap = response.data.subject_url_map;

              this.processUrlObjects(subjectUrlMap)
              const texts = response.data.components
                .map(component => component.text.trim())
                .filter(text => text.length > 0);

              // Remove duplicates from the fetched texts before returning
              return this.removeDuplicates(texts);
            } catch (error) {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });
              return []; // Return an empty array in case of error
            }
          },
          async processUrlObjects(subjectUrlMap) {
            const seenUrls = new Set(this.urlObjects.map(obj => obj.url));
            this.urlObjects = [...this.urlObjects, ...Object.entries(subjectUrlMap).filter(entry => {
                const [, url] = entry;
                return !seenUrls.has(url.trim()); // Only include URLs not already seen
            }).map(([subject, url]) => ({
                subject: subject.trim(),
                url: url.trim()
            }))];
          },
          async startScrape() {
            EventBus.$emit('show-overlay');
            try {
              if (this.scrapeSitemap) {
                await this.getUrlsFromSitemap();
                // If additional processing is needed for sitemap URLs, do it here
              } else {
                this.scrapeSource = this.url;
                const scrapedItems = await this.scrapeWebsite(this.url);
                this.extractedItems = [...this.extractedItems, ...scrapedItems];

                if (this.hasUrlChanges) {
                  await this.saveUrlObjects();
                }
              }
            } catch (error) {
              console.error('Error during scrape operation:', error);
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error_during_scrape') + error.message });
            } finally {
              EventBus.$emit('hide-overlay');
            }
          },
          removeDuplicates(texts) {
            const seenTexts = new Set();
            return texts.filter(item => {
              const trimmedText = item.trim();
              if (seenTexts.has(trimmedText)) {
                return false; // Skip adding duplicates
              } else {
                seenTexts.add(trimmedText);
                return true; // Include unique items
              }
            });
          },
          moveAllExtractedToKept() {
            // Adjusting for the possibility that extractedItems contains objects with a text property
            const existingKeptTexts = new Set(this.keptItems.map(item => item.text.trim()));

            const uniqueAndNewTexts = this.extractedItems
              .filter(item => {
                // Ensure this line is adjusted to the actual structure of extractedItems
                const trimmedText = typeof item === 'string' ? item.trim() : item.text.trim();
                return trimmedText.length > 0 && !existingKeptTexts.has(trimmedText);
              })
              .map(item => {
                const text = typeof item === 'string' ? item : item.text;
                return {
                  text: text.trim(),
                  source: this.scrapeSource || '' // Assuming scrapeSource is relevant here
                };
              });

            this.keptItems = [...this.keptItems, ...uniqueAndNewTexts];
            this.extractedItems = []; // Clear extractedItems after moving
          },
          getWordCount(text) {
            return text.trim().split(/\s+/).length;
          },
          toggleSelectSiteMapUrls() {
            const allSelected = this.sitemapUrls.every(item => item.selected);
            this.sitemapUrls.forEach(item => {
              item.selected = !allSelected;
            });
          },
          async scrapeSitemapUrls() {
            this.scrapingSitemapURLs = true;

            const selectedUrls = this.sitemapUrls.filter(url => url.selected);
            const totalUrls = selectedUrls.length;

            // Reset progress at the start
            this.scrapingProgressText = `1 of ${totalUrls}`;

            for (let index = 0; index < selectedUrls.length; index++) {
                const url = selectedUrls[index].url;
                const scrapedItems = await this.scrapeWebsite(url);
                const concatenatedText = scrapedItems.join(' ');

                this.keptItems.push({
                    text: concatenatedText,
                    source: url
                });

                // Update the progress and text for each processed URL
                this.scrapingProgressText = `${index + 1} of ${totalUrls}`;
            }

            this.sitemapUrls = this.sitemapUrls.filter(url => !url.selected);

            // at the end save all url objects gatherered in memory
            if (this.hasUrlChanges) {
              await this.saveUrlObjects();
            }
            this.scrapingSitemapURLs = false; // Processing done
          },
          startEditingUrl(item, index) {
            this.isEditingUrl = true;
            this.$set(this.urlObjects, index, { ...item, editing: true });
          },
          stopEditingUrl(item, index) {
            this.isEditingUrl = false;
            this.$set(this.urlObjects, index, { ...item, editing: false });
          },
          addBlankUrlObject() {
            const newItem = {
              url: '',
              subject: '',
              editing: true
            };
            // Prepend the new item to the array
            this.urlObjects.unshift(newItem);
            this.isEditingUrl = true;
          },
          deleteUrlObject(index) {
            // Confirm deletion with the user
            if (confirm(this.$t('confirm_delete_url'))) {
              this.urlObjects.splice(index, 1);
            }
          },
          deleteAllUrlObjects() {
            if (confirm(this.$t('confirm_delete_urls'))) {
              this.urlObjects = [];
            }
          },
          toggleEditingSitemapUrl(index) {
            // Toggle the editing state for only the clicked URL, and reset others
            this.sitemapUrls.forEach((url, idx) => {
              if (index === idx) {
                url.editing = !url.editing;
              } else {
                url.editing = false;
              }
            });
          },
          formatDate(dateString) {
            const date = new Date(dateString);
            const options = {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false // Use 24-hour format
            };
            return new Intl.DateTimeFormat('nl-NL', options).format(date);
          },
          truncateWords(text, limit = 25) {
            if (!text) return '';
            const words = text.split(' ');
            if (words.length > limit) {
                return words.slice(0, limit).join(' ') + '...';
            }
            return text;
          },

          // ARTICLE METHODS //
          deleteArticle(item) {
            this.articleToDelete = item;
            this.deleteArticleText = item.is_indexed
              ? this.$t('confirm_delete_article_indexed')
              : this.$t('confirm_delete_article');
            this.deleteArticleDialog = true;
          },
          async confirmDeleteArticle() {
            this.loadingArticles = true;

            const token = localStorage.getItem('userToken');
            const data = {
              _id: this.articleToDelete._id, 
              configuration_id: this.selectedConfiguration._id
            };

            try {
              await axios.post(`${this.$config.configurationServer}/training/article/delete`, data, {
                headers: { 'Authorization': `Bearer ${token}` }
              });
              EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('article_deleted') });
              this.getArticles();
            } catch (error) {
                console.error("Failed to delete article:", error);
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('article_deletion_failed') + error });
            } finally {
              this.deleteArticleDialog = false;
              this.articleToDelete = null;
            }
          },
          deleteAllArticles() {
            this.deleteArticlesText = this.$t('delete_all_articles_text');
            this.deleteArticlesDialog = true;
          },

          async confirmDeleteArticles() {            
            this.loadingArticles = true;

            const token = localStorage.getItem('userToken');
           
            const data = {             
              configuration_id: this.selectedConfiguration._id
            };

            try {
              await axios.post(`${this.$config.configurationServer}/training/articles/wipe_article_data_for_configuration`, data , {
                headers: { 'Authorization': `Bearer ${token}` }
              });
              EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('articles_deleted') });
              this.getArticles(); // Refresh the articles list
            } catch (error) {
              console.error("Failed to delete articles:", error);
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('articles_deletion_failed') + error });
            } finally {
              this.deleteArticlesDialog = false;
              this.loadingArticles = false;
            }
          },

          async indexArticles() {
            this.loadingArticles = true;

            const token = localStorage.getItem('userToken');

            const allArticlesToIndex = this.articles.filter(article => !article.is_indexed);
            const articlesToIndex = allArticlesToIndex.slice(0, 10); // Process only 10 articles to avoid server load

            // Notify the user if there are more than 10 articles to be indexed
            if (allArticlesToIndex.length > 10) {
              EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('only_ten_articles_can_be_processed') });
            }

            let processedCount = 0; // Track the number of articles processed

            for (const article of articlesToIndex) {
              const data = {
                _id: article._id,
                splitLength: article.splitLength,
                splitOverlap: article.splitOverlap
              };

              try {
                this.loadingArticlesText = `${processedCount + 1} of ${articlesToIndex.length}`;
                await axios.post(`${this.$config.configurationServer}/training/index_article`, data, {
                  headers: { 'Authorization': `Bearer ${token}` }
                });
                processedCount++; // Increment after successful indexing
              } catch (error) {
                console.error(`Failed to index article ${article._id}:`, error);
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: `${this.$t('article_index_failed')} ${article._id}: ${error}` });
              }
            }

            // Reset states and refresh articles after all operations
            this.loadingArticles = false;
            this.loadingArticlesText = '';
            this.getArticles();
          },
          async indexArticle(item) {
            this.loadingArticles = true;
            this.loadingArticlesText = '1 of 1';

            const token = localStorage.getItem('userToken');
            var data = {
              _id: item._id,
              splitLength: item.splitLength,
              splitOverlap: item.splitOverlap
            };

            try {
              await axios.post(`${this.$config.configurationServer}/training/index_article`, data, {
                headers: { 'Authorization': `Bearer ${token}` }
              });
              EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('article_indexed') });
              this.getArticles();
            } catch (error) {
                console.error("Failed to index article:", error);
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('article_index_failed') + error });
            } finally {
              this.loadingArticles = false;
              this.loadingArticlesText = '';
            }
          },
          openArticle(item) {
            console.log('*** todo open article: ', item);
          },

          async searchInArticles() {
            EventBus.$emit('show-overlay');  // Show a loading overlay or similar UI element
            const token = localStorage.getItem('userToken');

            const data = {
                configurationId: this.selectedConfiguration._id,
                searchText: this.searchArticlesText    
            };

            try {
                // Make the post request to the server
                const response = await axios.post(`${this.$config.configurationServer}/training/search_in_articles`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                // Handle response
                if (response.data) {
                    this.foundArticles = response.data;  // Assuming the server returns an array of articles
                } else {
                    console.error("No data returned from server");
                    this.foundArticles = [];  // Clear previous results if no new data
                }

            } catch (error) {
                console.error("Failed to search articles :", error);
                this.foundArticles = [];  // Clear previous results on error
            } finally {
                EventBus.$emit('hide-overlay');  // Hide the loading overlay
            }
          },

          async getArticles() {
            this.loadingArticles = true;
            const token = localStorage.getItem('userToken');
            const configurationId = this.selectedConfiguration._id;
            try {
              const url = `${this.$config.configurationServer}/training/articles?_id=${encodeURIComponent(configurationId)}`;
              const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
              });
              this.articles = response.data;

              this.articles.forEach(article => {
                if(!article.is_indexed && article.word_count) {
                  this.setSplitAndOverlapLength(article);
                }
              })

            } catch (error) {
                console.error("Failed to fetch articles:", error);
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('articles_fetch_failed') + error });
            } finally {
              this.loadingArticles = false;
            }
          },
          async createArticles() {
            this.processingArticles = true;

            // Filter items that can be processed
            const itemsToProcess = this.keptItems.filter(item => this.getWordCount(item.text) >= 200);
            const totalItems = itemsToProcess.length; // Total number of articles to be processed
            let processedCount = 0; // Counter for processed articles

            const token = localStorage.getItem('userToken');

            for (let i = 0; i < itemsToProcess.length; i++) {
                const item = itemsToProcess[i];

                // Update progress text
                processedCount += 1;
                this.articleProgressText = `${processedCount} of ${totalItems}`;

                const data = {
                    configurationId: this.selectedConfiguration._id,
                    text: item.text,
                    source: item.source,
                    wordCount: this.getWordCount(item.text)
                };

                try {
                    await axios.post(`${this.$config.configurationServer}/training/create_article`, data, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });

                    // No need to add item to processedItems since we're modifying keptItems directly
                    EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('article_creation_success') });
                } catch (error) {
                    console.error("Failed to create article:", error);
                    EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('article_creation_failed') + error });
                }
            }

            // Remove successfully processed items from keptItems
            this.keptItems = this.keptItems.filter(item => !itemsToProcess.includes(item));

            this.processingArticles = false;
            this.articleProgressText = '';
            this.getArticles(); // Refresh articles list
          },
          async createArticle(item, index) {
            this.processingArticles = true;
            this.articleProgressText = '1 of 1'; // single article always 1 of 1

            const token = localStorage.getItem('userToken');
            const data = {
                configurationId: this.selectedConfiguration._id,
                text: item.text,
                source: item.source,
                wordCount: this.getWordCount(item.text)
            };

            try {
              await axios.post(`${this.$config.configurationServer}/training/create_article`, data, {
                  headers: {
                      'Authorization': `Bearer ${token}`
                  }
              });
              this.keptItems.splice(index, 1); // Remove the processed item
              EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('article_creation_success') });
            } catch (error) {
                console.error("Failed to create article:", error);
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('article_creation_failed') + error });
            } finally {
                this.processingArticles = false;
                this.articleProgressText = '';
                this.getArticles(); // Refresh articles list
            }
          },
          setSplitAndOverlapLength(article) {
              // Calculate splitLength based on word count
              const wordCount = article.word_count;

              let splitLength;
              if (wordCount <= 500) {
                  splitLength = Math.round(wordCount * 0.05); // 5% for short articles
              } else {
                  splitLength = Math.round(wordCount * 0.02); // 2% for long articles
              }

              // Ensure splitLength is at least 50, and not more than 200
              splitLength = Math.max(50, Math.min(splitLength, 200));

              // Calculate overlap as 30% of splitLength
              let splitOverlap = Math.round(splitLength * 0.3);

              // Ensure splitOverlap is at least 35
              splitOverlap = Math.max(35, splitOverlap);

              // Set calculated values
              article.splitLength = splitLength;
              article.splitOverlap = splitOverlap;
          },
          // FILES
        triggerFileUpload() {
          // Trigger click on hidden file input
          this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
          const file = event.target.files[0];
          if (file) {
            this.$refs.fileInput.value = '';
            console.log("File to upload:", file);
            this.uploadFile(file);
          }
        },
        async uploadFile(file) {
          const token = localStorage.getItem('userToken');
          let formData = new FormData();
          formData.append('file', file);
          formData.append('configuration_id', this.selectedConfiguration._id);

          const uploadUrl = `${this.$config.configurationServer}/training/files/upload`;
          this.loadingfiles = true;
          try {
            await axios.post(uploadUrl, formData, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            this.getFileNames();
            //this.uploadedDocuments.push({ name: response.data.filename }); // Assuming `uploadedDocuments` is an array of objects with a `name` property and response.data.filename is the name of the file returned from the server
          } catch (error) {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('file_upload_failed') + error });
          } finally {
            this.loadingfiles = false;
          }
        },
        async getFileNames() {
          this.loadingfiles = true;
          const configurationId = this.selectedConfiguration._id; // Assuming you have `selectedConfiguration` in your data
          const filesUrl = `${this.$config.configurationServer}/files/get_names`;
          const token = localStorage.getItem('userToken');
          try {
            const response = await axios.get(filesUrl, {
              params: { 'configuration_id': configurationId },
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            // Update your data property that holds the file names
            this.uploadedDocuments = response.data.file_names.map(name => ({ name: name }));
          } catch (error) {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('filenames_retrieval_failed') + error });
          } finally {
            this.loadingfiles = false;
          }
        },
        async deleteFile(filename) {
          this.loadingfiles = true;
          const deleteUrl = `${this.$config.configurationServer}/files/delete`;

          try {
            await axios.delete(deleteUrl, {
              params: {
                'configuration_id': this.selectedConfiguration._id,
                'filename': filename
              },
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`
              }
            });
            this.getFileNames();
          } catch (error) {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('file_deletion_error') + error });
          } finally {
            this.loadingfiles = true;
          }
        },
        async scrapeDocument(filename) {
          this.loadingfiles = true;
          const scrapeUrl = `${this.$config.configurationServer}/files/scrape`;
          const token = localStorage.getItem('userToken');
          let formData = new FormData();
          formData.append('configuration_id', this.selectedConfiguration._id);
          formData.append('filename', filename);

          try {
            const response = await axios.post(scrapeUrl, formData, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            });

            if (response.data.components && response.data.components[0] && response.data.components[0].error) {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: `Error scraping file: ${response.data.components[0].error}` });
            } else {
              console.log("Scraped data:", response.data.components);
              response.data.components.forEach(component => {
                if (component.text) { // Ensure there's actual text to push
                  this.keptItems.push({
                    text: component.text,
                    source: filename
                  });
                }
              });
            }
          } catch (error) {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: 'Error scraping file' });
          } finally {
            this.loadingfiles = false;
          }
        },
        async createURLS() {
          const text = this.selectedKeptItemText; // Your text variable
          const regex = /\[([^\]]*)\]\s*\((https?:\/\/[^\s]+)\)/g;
          let match;
          //this.urlObjects = []; // Assuming urlObjects is reactive and already declared in your data

          while ((match = regex.exec(text)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (match.index === regex.lastIndex) {
              regex.lastIndex++;
            }

            // Extracted data from the match
            const subject = match[1].trim();
            const url = match[2].trim();

            // Push the object into the urlObjects array
            this.urlObjects.push({
              subject: subject || 'No Subject', // In case the subject is empty, you can customize this placeholder
              url: url
            });
          }
        },
        addUrls() {
          // Split de ingevoerde URLs in regels en voeg ze toe aan sitemapUrls
          const newUrls = this.urlsToAdd.split('\n').filter(url => url.trim() !== '');
          newUrls.forEach(url => {
            this.sitemapUrls.push({ url: url.trim(), selected: false, editing: false });
          });
          this.urlsToAdd = ''; // Reset de textarea
          this.addUrlsDialog = false; // Sluit de popup
        }
      },
    }
</script>

<style scoped>
.itemTable tbody tr {
  cursor: pointer;
}

.tableContainer {
  border: 1px solid black;
  border-radius: 4px; /* Optional: adds rounded corners */
}

.tableHeader {
  margin: 0;
  padding: 5px;
  background-color: #232323;
  color:#fff;
  border-bottom: 1px solid black;
}

.truncatedText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 275px; /* Adjust based on your layout */
  display: block;
}

.tableLayout {
  height: 30px;
  border:1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px; /* Adjusts padding to match your design */
}

.header-title {
  margin-right: auto; /* Ensures the title is aligned to the left */
}

.filter-controls {
  display: flex;
  align-items: center;
}

.word-count-input {
  width: 50px;
}

.custom-tooltip .v-tooltip__content {
  max-width: 250px;
  width: 250px;
  white-space: normal;
}

.align-right {
  text-align: right;
}

.highlight {
    background-color: yellow;
    font-weight: bold;
    font-size: 1.1em;
}

</style>

