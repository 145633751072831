import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Function to create a new Vuetify instance
export default function createVuetify(opts = {}) {
  return new Vuetify({
    lang: {
      current: opts.lang.current, // Use the provided language setting
    },
    theme: {
      themes: {
        light: {
          primary: '#1d0d3e',
          background: 'transparent', // Setting background to transparent
        },
      },
    },
  });
}
