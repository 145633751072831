<template>
  <v-container class="pt-5">
    <v-card flat>
      <v-row no-gutters justify="center" class="pt-5">
        <v-col cols="12">
          <div>
            <v-row no-gutters>
              <v-col>
                <v-radio-group
                  v-model="uiType"
                  @change="setIframeCode('false')"
                  row
                >
                  <v-radio :label="$t('overlay')" value="overlay"></v-radio>
                  <v-radio :label="$t('embedded')" value="embedded"></v-radio>

                </v-radio-group>
                <v-radio-group
                  v-model="initialState"
                  @change="setIframeCode('false')"
                  row
                >
                  <v-radio :label="$t('initial_closed')" value="closed"></v-radio>
                  <v-radio :label="$t('initial_open')" value="open"></v-radio>
                  <v-radio :disabled="uiType === 'embedded'" :label="$t('initial_full_screen')" value="fullscreen"></v-radio>
                </v-radio-group>

                <div style="width:300px">
                  <v-slider
                    v-model="fullHeightLevel"
                    :min="70"
                    :max="100"
                    :step="1"
                    label="Full height Level"
                    thumb-label="always"
                  ></v-slider>
                </div>

              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-textarea v-if="!scriptSaved"
                  v-model="scriptText"
                  style="font-style: italic; padding-top: 10px;"
                  outlined
                  class="iframe-textarea"
                  height="100px"
                  readonly>
                </v-textarea>
                <v-textarea v-if="scriptSaved" style="font-style: italic; padding-top: 10px;" outlined class="iframe-textarea"  height="200px"
                  v-model="embedScript"
                  readonly>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <v-text-field dense
                  label="Position right"
                  @change="setIframeCode('false')"
                  v-model="iframePositionRight">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field dense
                  label="Position bottom"
                  @change="setIframeCode('false')"
                  v-model="iframePositionBottom">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <template>
                  <v-row no-gutters>
                    <v-btn color="primary" @click="saveScript">{{$t('save_script')}}</v-btn>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                      </template>
                      <span>{{ storedExcecutionScript }}</span>
                    </v-tooltip>
                  </v-row>
                </template>

                <v-btn color="primary" :disabled="!iframeCode || !scriptSaved" @click="openIframeInNewWindow">{{$t('test_ai_assistant')}}</v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" @click.stop="copyToClipboard"
                      :disabled="!iframeCode || !scriptSaved"
                      v-bind="attrs"
                      v-on="on">
                      <span>{{$t('copy_script')}}</span>
                    </v-btn>
                  </template>
                  <span>{{$t('copy_iframe_tt')}}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-btn v-if="chatbotSettings && !chatbotSettings.demo_url" color="primary" :disabled="!iframeCode || !scriptSaved" @click="createDemoPage">{{$t('create_demo')}}</v-btn>
                <v-btn v-if="chatbotSettings && chatbotSettings.demo_url" color="primary" :disabled="!iframeCode || !scriptSaved" @click="replaceDemoPageDialog = true">{{$t('create_demo')}}</v-btn>
              </v-col>
              <v-col v-if="scriptSaved" cols="9">
                <div style="background-color: lightgrey; padding: 5px;">
                  <a href="" @click.prevent="goToDemoPage"><strong>{{ demo_url }}</strong></a>
                </div>
              </v-col>
            </v-row>
        </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- Replace demo page -->
    <v-dialog v-model="replaceDemoPageDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="justify-center grey primary white--text">
          {{$t('replace_demopage_title')}}
        </v-card-title>
        <v-card-text class="pt-4 text-center">
          <v-label>{{$t('replace_demopage_text')}}</v-label>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="replaceDemoPageDialog = false">{{$t('cancel')}}</v-btn>
          <v-btn color="secondary" @click="confirmReplaceDemoPage">{{$t('ok')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
    import { EventBus } from '../../eventBus.js';
    import axios from 'axios';
    export default {
        name: 'ChatTestTab',
        data() {
          return {
            // Assuming chatbotSettings and initialState are defined here or come from props
            iframeCode: '',
            chatbotSettings: null,
            chatbotButtonSettings: null,
            uiType: 'overlay',
            initialState: 'closed',
            demo_url: '',
            replaceDemoPageDialog: false,
            scriptSaved: false,
            scriptText: this.$t('save_script_txt'),
            embedScript: '',
            storedExcecutionScript: '',
            fullHeightLevel: 100, 
            iframePositionRight: 5,
            iframePositionBottom: 10
          };
        },
        computed: {
          // Compute iframe dimensions based on initialState and chatbotSettings
          iframeDimensions() {
            const baseSpace = 130; // Default base size, adjust as needed
            let height = this.initialState === 'open' ? (this.chatbotSettings.chatbotHeight + 30) + 'px' : baseSpace + 'px';
            let width = this.initialState === 'open' ? (this.chatbotSettings.chatbotWidth + 38) + 'px' : baseSpace + 'px';
            return { height, width };
          },
          configuration: {
            get() {
              return this.$store.getters.selectedConfiguration;
            }
          },
        },
        mounted() {
          this.initialize();
        },
        created() {
          EventBus.$on('set-configuration', () => {
            this.initialize();
          });
          EventBus.$on('reload-test-tab', () => {
            this.initialize();
          });
        },
        methods: {
          async initialize() {
            this.getChatbotSettings()
            .then(settings => {
              // Assuming getChatbotSettings now correctly resolves with the settings data
              this.chatbotSettings = settings;
              this.chatbotButtonSettings = settings.button_settings;
              this.demo_url = this.convertDemoUrl(settings.demo_url);
              this.setIframeCode('false');
              this.getScript();
            })
            .catch(error => {
              console.error("Failed to load chatbot settings:", error);
            });
          },


          convertDemoUrl(demoUrl) {
            try {
                // Extract the relevant parts from the demo URL
                const urlParts = demoUrl.split('/');
                const configurationId = urlParts[urlParts.length - 2];
                const demoIdWithExtension = urlParts[urlParts.length - 1];

                // Remove the .html extension from the demo ID
                const demoId = demoIdWithExtension.replace('.html', '');

                // Generate the new URL layout
                const newUrl = `https://www.hosthub.ai/demo.html?configuration_id=${configurationId}&demo_id=${demoId}`;

                return newUrl;
            } catch (error) {
                console.error("Invalid URL format:", error);
                return null;
            }
          },

          setIframeCode(isTest) {            
            this.iframeCode = this.generateIframeCode(isTest, true);
          },

          generateEmbedScript() {
            let environment = 2; // default to prod
            if (this.$config.configurationServer.includes('test')) {
              environment = 1;
            } else if (this.$config.configurationServer.includes('127.0.0.1')) {
              environment = 0;
            }

            const configurationId = this.configuration._id;
            const scriptStart = `<script id="hosthub-embed-script" data-config-id="${configurationId}" data-environment="${environment}">`;
            const scriptEnd = '<'+'/script>';

            let scriptContent = `
          (function() {
            const script = document.createElement('script');
            script.src = 'https://hosthubCDN-g6d4cufud7eecee5.z02.azurefd.net/cdn/chat.js';
            script.async = true;
            document.head.appendChild(script);
          })();
          `;

            // Ensure there are no extra newlines
            this.embedScript = scriptStart + '\n' + scriptContent.trim() + '\n' + scriptEnd;
          },

          generateIframeCode(isTest, includeScriptTags) {
            const chatbotHost = this.$config.chatbotHost;
            const configurationId = this.configuration._id;
            const chatbotWidth = parseInt(this.chatbotSettings.chatbotWidth, 10) + 70;
            const chatbotHeight = parseInt(this.chatbotSettings.chatbotHeight, 10) + 30;

            let buttonSpace = Number(this.chatbotButtonSettings.buttonHeight) + Number(this.chatbotButtonSettings.borderWidth) + Number(this.chatbotButtonSettings.shadowWidth) + 15;

            let iframeStartHeight = buttonSpace;
            let iframeStartWidth = buttonSpace;

            if (this.chatbotButtonSettings.showChatbuttonBubble) {
              iframeStartHeight += 150;
              iframeStartWidth += Number(this.chatbotButtonSettings.chatbuttonBubbleWidth) + 100;
            }

            const iframeHeight = this.uiType === 'overlay' ? iframeStartHeight : chatbotHeight;
            const iframeWidth = this.uiType === 'overlay' ? iframeStartWidth : chatbotWidth;
            const iframePositionRight = this.iframePositionRight;
            const iframePositionBottom = this.iframePositionBottom;

            let scriptStart = '<script>';
            let scriptEnd = '<' + '/script>';
            let scriptContent = `
          (function() {
            // Dynamic iframe creation and event handling logic
            function createChatbotIframe() {
              const iframe = document.createElement('iframe');
              iframe.id = 'host-hub-chatbot';
              iframe.style.position = 'fixed';
              iframe.style.bottom = '${iframePositionBottom}px';
              iframe.style.right = '${iframePositionRight}px';
              iframe.style.height = '${iframeHeight}px';
              iframe.style.width = '${iframeWidth}px';
              iframe.style.zIndex = '9999';
              iframe.src = '${chatbotHost}/chatbot.html?version=01&configId=${configurationId}&initialState=${this.initialState}&test=${isTest}';
              iframe.frameBorder = '0';
              document.body.appendChild(iframe);
              setupIframeListeners(iframe);
            }

            function setupIframeListeners(iframe) {
              const FULL_WIDTH = '100vw';
              const FULL_HEIGHT = '${this.fullHeightLevel}vh';

              window.addEventListener('message', function(event) {
                if (event.origin === '${chatbotHost}') {
                  switch(event.data.type) {
                    case 'hostHubExtendChatBot':
                      if (window.innerWidth < window.innerHeight) {

                        resizeIframe(iframe, FULL_WIDTH, FULL_HEIGHT);
                        iframe.style.bottom = '0px';
                        iframe.style.right = '0px';
                        iframe.contentWindow.postMessage({ type: 'setFullScreen' }, '${chatbotHost}');
                      } else {
                        resizeIframe(iframe, '${chatbotWidth}px', '${chatbotHeight}px');
                      }
                      break;
                    case 'goFullScreen':
                      resizeIframe(iframe, FULL_WIDTH, FULL_HEIGHT);
                      break;
                    case 'exitFullScreen':
                      resizeIframe(iframe, '${chatbotWidth}px', '${chatbotHeight}px');
                      break;
                    case 'collapseChat':
                      resizeIframe(iframe, '${buttonSpace}px', '${buttonSpace}px');
                      break;
                    case 'refreshIframe':
                      resizeIframe(iframe, '${iframeStartWidth}px', '${iframeStartHeight}px');
                      iframe.src = iframe.src;
                      break;
                    default:
                      break;
                  }
                }
              });
            }

            function resizeIframe(iframe, width, height) {
              iframe.style.width = width;
              iframe.style.height = height;
            }
            window.onload = createChatbotIframe;
            window.createChatbotIframe = createChatbotIframe;
          })();` ;

            if (includeScriptTags) {
              return scriptStart + scriptContent + scriptEnd;
            }
            return scriptContent;
          },

          getChatbotSettings() {
            EventBus.$emit('show-overlay');
            const token = localStorage.getItem('userToken');
            const configurationId = this.configuration._id;

            return axios.get(`${this.$config.configurationServer}/chatbot/settings/byConfigurationId`, {
              headers: { 'Authorization': `Bearer ${token}` },
              params: { configuration_id: configurationId }
            })
            .then(response => {
              EventBus.$emit('hide-overlay');
              return response.data; // Resolves the promise with the data
            })
            .catch(error => {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: error.toString()});
              EventBus.$emit('hide-overlay');
              throw error; // Rethrows the error to be caught by the caller
            });
          },
          openIframeInNewWindow() {
            // Generate the iframe code first if not already done
            let iframeCode = this.generateIframeCode('true', true);

            // Open a new browser window
            const newWindow = window.open('', '_blank');

            if (newWindow) {

              const htmlContent = `
              <!DOCTYPE html>
              <html>
              <head>
                <title>Chatbot Preview</title>
                <meta name="viewport" content="width=device-width, initial-scale=1">
                <style>
                  body { margin: 0; height: 100vh; display: flex; justify-content: center; align-items: center; }
                </style>
              </head>
              <body>
                ${iframeCode}
              </body>
              </html>
            `;

              newWindow.document.write(htmlContent);

              // Optional: You might want to add some basic styles to the body of the new window for better presentation
              newWindow.document.body.style.margin = '0';
              newWindow.document.body.style.height = '100vh';
              newWindow.document.body.style.display = 'flex';
              newWindow.document.body.style.justifyContent = 'center';
              newWindow.document.body.style.alignItems = 'center';

              // Add a title to the new window
              newWindow.document.title = 'Chatbot Preview';

              // Important: Close the document write stream to ensure the content is fully loaded
              newWindow.document.close();
            } else {
              alert('Failed to open a new window. Please check your popup blocker settings.');
            }
          },
          copyToClipboard() {
            if (this.embedScript) {
              navigator.clipboard.writeText(this.embedScript)
                .then(() => {
                  EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('the_script_has_been') });
                })
                .catch(err => {
                  // Handle possible errors
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error_copying_the_script') + err });
                });
            }
          },
          confirmReplaceDemoPage() {
            this.createDemoPage();
            this.replaceDemoPageDialog = false;
          },

          getScript() {
            EventBus.$emit('show-overlay');
            const configurationId = this.configuration._id;

            return axios.get(`${this.$config.configurationServer}/chatbot/settings/script`, {
              params: { configuration_id: configurationId },
              validateStatus: function (status) {
                return status >= 200 && status < 300 || status === 404; // Accept 404 as a valid status
              }
            })
            .then(response => {
              EventBus.$emit('hide-overlay');

              if (response.status === 200) {
                this.scriptSaved = true;
                this.storedExcecutionScript = response.data.script;
                this.generateEmbedScript(); // execution script is present
              } else {
                this.scriptSaved = false;
              }

            })
            .catch(error => {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: error.toString()});
              EventBus.$emit('hide-overlay');
              throw error; // Rethrows the error to be caught by the caller
            });
          },

          saveScript() {
            EventBus.$emit('show-overlay');
            const token = localStorage.getItem('userToken');
            let iframeCode = this.generateIframeCode('false', false);            
            let formData = new FormData();
            formData.append('configuration_id', this.configuration._id);
            formData.append('script_content', iframeCode);

            axios.post(
                this.$config.configurationServer + '/chatbot/settings/script/upload',
                formData,
                {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                  }
                }
            )
            .then(response => {
              EventBus.$emit('hide-overlay');
              if (response.status === 200) {
                  EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('save_success') });
                  this.generateEmbedScript();
                  this.scriptSaved = true;
              } else {
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('save_failed') });
              }
            })
            .catch(error => {
                console.error('Failed to store script!', error);
                EventBus.$emit('hide-overlay');
            });
          },

          createDemoPage() {
            EventBus.$emit('show-overlay');
            const token = localStorage.getItem('userToken');
            let formData = new FormData();
            formData.append('configuration_id', this.configuration._id);

            let iframeCode = this.generateIframeCode('true', true);
            let configurationName = this.configuration.name;

            const htmlContent = `


            <!DOCTYPE html>
<html>
<head>
    <title>HostHub - Demo Pagina</title>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style>
        @font-face {
            font-family: 'CodecPro-ExtraBold';
            src: url('https://hosthubstoragetest.blob.core.windows.net/cdn/Codec-Pro-ExtraBold.ttf') format('truetype');
            font-weight: 800;
            font-style: normal;
        }

        @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
        }
        body {
            margin: 0;
            padding: 0;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #fffcfc; /* Light background as per the screenshot */
            color: #333; /* Dark gray text color for readability */
            font-family: 'Roboto', sans-serif;
        }
        header {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            text-align: center;
            background: #1b0e40;
            padding: 10px 0;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
            animation: fadeIn 0.5s ease-in-out forwards;
        }
        img {
            height: 60px;
        }
        .config-name {
            font-size: 20px;
            font-weight: 400;
            color: white;
            margin-top: 5px;
        }
        .content {
            max-width: 800px; /* Set a max-width for content area */
            text-align: left;
            padding: 20px;
            font-size: 18px;
            line-height: 1.6;
        }
        .content h1 {
            font-family: 'CodecPro-ExtraBold', sans-serif; /* Use the custom font for headers */
            color: #1d0c3a; /* Dark purple text color */
        }
        .bold-text {
            font-family: 'CodecPro-ExtraBold', sans-serif; /* Apply custom font to bold-text class */
        }
        .button {
            display: inline-block;
            padding: 10px 20px;
            margin-top: 20px;
            background-color: #e140a0; /* Purple button background */
            color: white;
            border-radius: 20px;
            text-decoration: none;
            font-weight: bold;
        }
    </style>
</head>
<body>
    <header>
        <img src="https://hosthubCDN-g6d4cufud7eecee5.z02.azurefd.net/cdn/logo_white.png" alt="HostHub Logo">
        <div class="config-name">${configurationName}</div>
    </header>
    <div class="content">
        <h1><span style="color:#1d0c3a">Leuk dat je onze </span><span style="color:#e140a0">demo bekijkt!</span></h1>
        <p>Dit is een van de manieren op je AI assistent te gebruiken. We kunnen echter nog veel meer repetitieve taken en gesprekken, reserveringen etc. voor je automatiseren.</p>
        <p>Vraag hieronder naar alle mogelijkheden.</p>
        <a href="https://www.hosthub.ai/contact.html" target="_blank" class="button">Neem contact op</a>
    </div>
    ${iframeCode} <!-- iframeCode should be properly set to embed the content -->
</body>
</html>`
;

            formData.append('demo_content', new Blob([htmlContent], { type: 'text/html' }));

            axios.post(
                this.$config.configurationServer + '/chatbot/settings/demo/upload',
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .then(response => {
                if (response.data.url) {
                    this.demo_url = this.convertDemoUrl(response.data.url);
                    this.chatbotSettings.demo_url = this.demo_url;
                } else {
                    console.error('Demo page creation response did not include a URL.');
                }
                EventBus.$emit('hide-overlay');
            })
            .catch(error => {
                console.error('Failed to create demo page:', error);
                EventBus.$emit('hide-overlay');
            });
          },
          goToDemoPage() {
              const url = this.chatbotSettings.demo_url || this.demo_url;
              window.open(url, '_blank').focus();
          }
        }
    }
</script>

<style scoped>
</style>

