<template>
    <v-row justify="center">
      <v-col cols="12" xl="4" lg="5" md="6" sm="7" xs="12" class="zoom-class position-relative">
        <v-carousel :continuous="false" v-model="currentStep" hide-delimiters height="auto">
          <v-carousel-item>
            <v-card elevation="8" class="card-shadow flex-fill my-6 card-padding">
              <v-card-text class="card-text-padding">
                <h1 class="h1-class" style="line-height: 1.5;">{{$t('give_your_assistant_a_name')}}<span class="pink-dot">.</span></h1>
                <v-text-field
                  @input="onAssistantNameChanged"
                  class="textinput-class"
                  outlined
                  @blur="setAssistantName"
                  :placeholder="$t('name')"
                  v-model="assistantName"
                ></v-text-field>
                <div class="custom-label">{{$t('your_assistant_character')}}</div>
                <FeedbackTextArea
                  @disable-next-button="handleDisableNextButton"
                  @enable-next-button="handleEnableNextButton"
                  @input-validated="handleInputValidated"
                  identifier="aiAssistantDescription"
                  v-model="aiAssistantDescription"
                  :placeholder="$t('describe_here_the_character')"
                  :feedback="aiAssistantDescriptionFeedback"
                  :session-id="sessionId"
                  :socket="socket"
                  @updateLastActive="lastActiveTextArea = $event"/>
              </v-card-text>
            </v-card>
          </v-carousel-item>
          <v-carousel-item>
            <v-card elevation="8" class="card-shadow flex-fill my-6 card-padding">
              <v-card-text class="card-text-padding">
                <h1 class="h1-class" style="line-height: 1.5;">{{$t('what_is_your_start_message')}}<span class="pink-dot">?</span></h1>
                  <v-radio-group @change="onStartMessageChoiceChanged" v-model="localEnableAIStartMessage">
                    <v-radio :value="true">
                      <template v-slot:label>
                        <v-label class="radio-label">
                          <span v-html="$t('my_ai_assistant_can')"></span>
                        </v-label>
                      </template>
                    </v-radio>
                    <br/>
                    <v-radio :value="false">
                      <template v-slot:label>
                        <v-label class="radio-label">
                          <span v-html="$t('i_always_have_the_same')"></span>
                        </v-label>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <div class="textarea-container">
                    <v-textarea
                      @input="onStartMessageChanged"
                      @blur="setStartMessage"
                      style="padding-left:20px; padding-right:20px;"
                      v-model="localStartMessage"
                      :placeholder="localEnableAIStartMessage ? startMessageAIPlaceHolder : startMessageCustomPlaceHolder"
                      :disabled="localEnableAIStartMessage"
                    ></v-textarea>
                  </div>
              </v-card-text>
            </v-card>
          </v-carousel-item>
          <v-carousel-item>
            <v-card elevation="8" class="card-shadow flex-fill my-6 card-padding">
              <v-card-text class="card-text-padding">
                <h1 class="h1-class" style="line-height: 1.5;">{{$t('tell_us_about_your_company')}}<span class="pink-dot">.</span></h1>
                <v-text-field
                  @input="onCompanyNameChanged"
                  class="textinput-class"
                  outlined
                  @blur="setCompanyName"
                  :placeholder="$t('company_name')"
                  v-model="companyName"
                ></v-text-field>
                <FeedbackTextArea
                  @disable-next-button="handleDisableNextButton"
                  @enable-next-button="handleEnableNextButton"
                  @input-validated="handleInputValidated"
                  identifier="companyDescription"
                  v-model="companyDescription"
                  :placeholder="$t('describe_shortly_your_company')"
                  :feedback="companyDescriptionFeedback"
                  :session-id="sessionId"
                  :socket="socket"
                  @updateLastActive="lastActiveTextArea = $event"/>
              </v-card-text>
            </v-card>
          </v-carousel-item>
          <v-carousel-item>
            <v-card elevation="8" class="card-shadow flex-fill my-6 card-padding">
              <v-card-text class="card-text-padding">
                <h1 class="h1-class" style="line-height: 1.5;">{{$t('describe_the_goal_of')}}<span class="pink-dot">.</span></h1>
                <FeedbackTextArea
                  @disable-next-button="handleDisableNextButton"
                  @enable-next-button="handleEnableNextButton"
                  @input-validated="handleInputValidated"
                  identifier="goalDescription"
                  v-model="goalDescription"
                  :placeholder="$t('describe_here_the_goal_of')"
                  :feedback="goalDescriptionFeedback"
                  :session-id="sessionId"
                  :socket="socket"
                  @updateLastActive="lastActiveTextArea = $event"/>
              </v-card-text>
            </v-card>
          </v-carousel-item>
          <v-carousel-item>
            <v-card elevation="8" class="card-shadow flex-fill my-6 card-padding">
              <v-card-text class="card-text-padding">
                <h1 class="h1-class" style="line-height: 1.5;">{{$t('this_way_your_ai_should_answer')}}<span class="pink-dot">.</span></h1>
                <FeedbackTextArea
                  @disable-next-button="handleDisableNextButton"
                  @enable-next-button="handleEnableNextButton"
                  @input-validated="handleInputValidated"
                  identifier="answerDescription"
                  v-model="answerDescription"
                  :placeholder="$t('describe_how_your_ai_assistant_should_answer')"
                  :feedback="answerDescriptionFeedback"
                  :session-id="sessionId"
                  :socket="socket"
                  @updateLastActive="lastActiveTextArea = $event"/>
                <v-checkbox
                  v-model="$globalState.wizardValues.useEmoticons"
                  :label="$t('enable_emoticons')"
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-carousel-item>
          <v-carousel-item>
            <v-card elevation="8" class="card-shadow flex-fill my-6 card-padding">
              <v-card-text class="card-text-padding">
                <h1 class="h1-class" style="line-height: 1.5;">{{$t('tell_us_about_your_services')}}<span class="pink-dot">.</span></h1>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="width: 75%;" class="text-left"><span class="custom-label">{{$t('product_or_service')}}</span></th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="border: 1px solid black;" v-for="(url, index) in urls" :key="index">
                        <td>
                          <v-text-field
                            dense
                            v-model="url.subject"
                            :placeholder="$t('describe_your_first_service')"
                          ></v-text-field>
                            <v-text-field
                            dense
                            :disabled="!url.subject"
                            v-model="url.url"
                            :placeholder="$t('first_service_url')"
                            @blur="setUrls"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-btn
                            elevation="1"
                            fab
                            x-small
                            color="#E140A0"
                            @click="addNewUrl"
                            v-if="index === urls.length - 1 && urls.length < 5"
                            :disabled="!isRowComplete(index)">
                              <span style="color:#ffffff" class="mdi mdi-plus mdi-24px"></span>
                          </v-btn>
                          <v-btn
                            elevation="1"
                            fab
                            x-small
                            color="#E140A0"
                            @click="deleteUrl(index)"
                            v-if="urls.length > 1">
                            <span style="color:#ffffff" class="mdi mdi-minus mdi-24px"></span>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-carousel-item>
          <v-carousel-item>
            <v-card elevation="8" class="card-shadow flex-fill my-6 card-padding">
              <v-card-text class="card-text-padding">
                <h1 class="h1-class" style="line-height: 1.5;">{{$t('tell_us_some_general_information')}}<span class="pink-dot">.</span></h1>
                <FeedbackTextArea
                  @input-validated="handleInputValidated"
                  identifier="generalInformationDescription"
                  v-model="generalInformationDescription"
                  :placeholder="$t('tell_us_more_general_info')"
                  :feedback="generalInformationDescriptionFeedback"
                  :session-id="sessionId"
                  :socket="socket"
                  @updateLastActive="lastActiveTextArea = $event"/>
              </v-card-text>
            </v-card>
          </v-carousel-item>

          <!-- navigation buttons -->
          <template v-slot:prev="{ on, attrs }">
            <v-btn
              fab
              v-if="currentStep > 0"
              @click="previousPressed"
              small
              color="#E140A0"
              v-bind="attrs"
              v-on="on">
              <span class="mdi mdi-chevron-left mdi-36px"></span>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn
              v-if="currentStep < 6"
              :disabled="nextDisabled"
              @click="nextPressed"
              fab
              small
              color="#E140A0"
              v-bind="attrs"
              v-on="on">
              <span class="mdi mdi-chevron-right mdi-36px"></span>
            </v-btn>
          </template>
        </v-carousel>
      </v-col>
    </v-row>

</template>

<script>
import io from 'socket.io-client';  // Ensure Socket.IO client library is installed
import axios from 'axios';
import FeedbackTextArea from './FeedbackTextArea.vue';
import { EventBus } from '../../eventBus.js';

export default {
  props: {
    configuration: Object
  },
  components: {
    FeedbackTextArea,
  },
  name: "StepTwoInstructions",
  data() {
    return {
      currentStep: 0,
      cards: 6,
      nextDisabled: true,
      hasAgentDescription: false,
      hasCompanyDescription: false,
      hasGoalDescription: false,
      hasAnswerDescription: false,
      configurationServer: this.$config.configurationServer,
      showSnackbar: false,
      aiSuggestion: '',
      loading: true,
      companyName: '',
      assistantName: '',
      companyDescription: '',
      aiAssistantDescription: '',
      goalDescription: '',
      answerDescription: '',
      generalInformationDescription: '',
      showOverlay: false,
      overlayText: '',
      overlayIcon: "../icons/robot.gif",
      socket: null,
      sessionId: null,
      server: this.$config.chatServer,
      promptbotconfigurationId: this.$config.promptbotConfigurationId,
      lastActiveTextArea: '',
      companyDescriptionFeedback: '',
      aiAssistantDescriptionFeedback: '',
      goalDescriptionFeedback: '',
      answerDescriptionFeedback: '',
      generalInformationDescriptionFeedback: '',
      focusedCard: null,
      localEnableAIStartMessage: true,
      startMessageAIPlaceHolder: '',
      startMessageCustomPlaceHolder: '',
      localStartMessage: '',
      urls: [{ address: '', description: '' }],
    };
  },
  mounted() {
    //this.$globalState.wizardValues.startMessage = this.$t('fixed_welcome_message');
    this.startMessageAIPlaceHolder = this.$t('your_ai_assistant_generates');
    this.startMessageCustomPlaceHolder = this.$t('create_your_fixed_message');

    if (this.configuration && this.configuration._id) { // if Id is there, it means it's an existing configuration

      this.assistantName = this.configuration.assistantName;
      this.$globalState.wizardValues.assistantName = this.assistantName;

      this.aiAssistantDescription = this.configuration.ai_assistant_description;
      this.$globalState.wizardValues.aiDescription = this.aiAssistantDescription;
      this.hasAgentDescription = !this.aiAssistantDescription;

      this.localEnableAIStartMessage = this.configuration.enableAIStartMessage;
      this.$globalState.wizardValues.enableAIStartMessage = this.localEnableAIStartMessage;

      this.localStartMessage = this.configuration.startMessage;
      this.$globalState.wizardValues.startMessage = this.localStartMessage;

      this.companyName = this.configuration.companyName;
      this.$globalState.wizardValues.companyName = this.configuration.companyName;

      this.companyDescription = this.configuration.company_description;
      this.$globalState.wizardValues.companyDescription = this.companyDescription;
      this.hasCompanyDescription = !!this.companyDescription;

      this.goalDescription = this.configuration.goal_instruction;
      this.$globalState.wizardValues.goalDescription = this.goalDescription;
      this.hasGoalDescription = !!this.goalDescription;

      this.answerDescription = this.configuration.answer_instruction;
      this.hasAnswerDescription = !!this.answerDescription;

      let htmlInstruction = this.$t('extra_instructions.html_included');
      if (this.answerDescription.includes(htmlInstruction)) {
        this.answerDescription = this.answerDescription.replace(htmlInstruction, '');
      }

      this.$globalState.wizardValues.answerDescription =  this.answerDescription;

      this.generalInformationDescription = this.configuration.general_information_instruction;
      this.$globalState.wizardValues.generalInformationDescription = this.generalInformationDescription;

      this.getConfigurationUrls();

      if (this.assistantName && this.aiAssistantDescription) {
        this.nextDisabled = false;
      }
    }
  },
  computed: {
    entityInstruction() {
      return [this.companyDescription, this.aiAssistantDescription]
        .filter(text => text.trim() !== '')
        .join("\n\n");
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    '$globalState.wizardValues.enableAIStartMessage'(newVal) {
      this.localEnableAIStartMessage = newVal;
    },
    urls: {
        deep: true,
        handler(newUrls) {
            this.$emit('urls-updated', newUrls);
        }
    }
  },
  methods: {
    getConfigurationUrls() {
      const token = localStorage.getItem('userToken');
      const configurationId = this.configuration._id;
      axios.get(`${this.configurationServer}/training/urls/get`, {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { configurationId }
      })
      .then(response => {
        // Handle success
        this.urls = response.data.map(item => ({
          url: item.url,        // Map 'url' from the response to 'address'
          subject: item.subject // Map 'subject' from the response to 'description'
        }));

        if (this.urls.length === 0) {
          this.addNewUrl();
        }
      })
      .catch(error => {
        // Handle error
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: error});
      });
    },
    setCompanyName() {
      this.$globalState.wizardValues.companyName = this.companyName;
    },
    setAssistantName() {
      this.$globalState.wizardValues.assistantName = this.assistantName;
    },
    setStartMessage() {
      this.$globalState.wizardValues.startMessage = this.localStartMessage;
    },
    initialize() {
      const requestBody = {
          id: this.promptbotconfigurationId,
          conversation_type: 'USER',  // Change as per your logic
      };
      axios.post(`${this.server}/initialize`, requestBody)
          .then(response => {
          this.sessionId = response.data.session_id;
          this.initializeChatSocket(this.sessionId);
          })
          .catch(error => {
          console.error('Initialization error:', error);
      });
    },
    handleInputValidated(payload) {
      this.$emit('input-validated', payload);
    },

    initializeChatSocket(sessionId) {
      this.socket = io(`${this.server}`, {
          query: { session_id: sessionId }
      });

      this.socket.on('connect', () => {
          console.log('Connected to the server via Socket.IO');
          this.socket.emit('join', { session_id: sessionId });
      });

      this.socket.on('disconnect', () => {
          console.log('Disconnected from the server');
      });

      this.socket.on('response', (data) => {
          this.handleChatResponse(data);
      });
    },

    handleChatResponse(data) {
      if (data.message && data.message.length > 0) {
        const aiResponse = data.message[data.message.length - 1];
        switch(this.lastActiveTextArea) {
          case 'companyDescription':
            this.companyDescriptionFeedback = aiResponse;
            break;
          case 'aiAssistantDescription':
            this.aiAssistantDescriptionFeedback = aiResponse;
            break;
          case 'goalDescription':
            this.goalDescriptionFeedback = aiResponse;
            break;
          case 'answerDescription':
            this.answerDescriptionFeedback = aiResponse;
            break;
          case 'generalInformationDescription':
            this.generalInformationDescriptionFeedback = aiResponse;
            break;
          default:
            console.log('Unhandled text area:', this.lastActiveTextArea);
        }
      }
    },
    isRowComplete(index) {
        if (index < 0 || index >= this.urls.length) {
            return false;
        }
        const row = this.urls[index];
        return row && row.subject && row.url && row.subject.trim() !== '' && row.url.trim() !== '';
    },

    addNewUrl() {
      this.urls.push({ url: '', subject: '' });
    },
    deleteUrl(index) {
      this.urls.splice(index, 1);
    },
    setUrls() {
      this.$globalState.wizardValues.urls = [...this.urls];
    },
    previousPressed() {
      this.nextDisabled = false; // next button always enabled when pressing the previous button
      if (this.currentStep > 0) {
        this.currentStep--;
        if (this.currentStep === this.cards -1) {
           this.$emit('step-two-not-finished');
        }
      }
    },
    nextPressed() {
      if (this.currentStep < this.cards - 1) {
        this.currentStep++;
      } else if (this.currentStep === this.cards - 1) {
        this.$emit('step-two-finished');
      }

      switch (this.currentStep) {
        case 1:
          this.nextDisabled = !this.localEnableAIStartMessage && !this.localStartMessage;
          break;
        case 2:
          this.nextDisabled = !this.hasCompanyDescription || !this.companyName;
          break;
        case 3:
          this.nextDisabled = !this.hasGoalDescription;
          break;
        case 4:
          this.nextDisabled = !this.hasAnswerDescription;
          break;
        case 5:
          this.nextDisabled = false;
          break;
        default:
          this.nextDisabled = true;
      }
    },
    onAssistantNameChanged() {
      if (this.assistantName && this.hasAgentDescription) {
        this.nextDisabled = false;
      } else {
        this.nextDisabled = true;
      }
    },
    onCompanyNameChanged() {
       if (this.companyName && this.hasCompanyDescription) {
        this.nextDisabled = false;
      } else {
        this.nextDisabled = true;
      }
    },
    onStartMessageChanged() {
      if (this.localStartMessage) {
        this.nextDisabled = false;
      } else {
        this.nextDisabled = true;
      }
    },
    onStartMessageChoiceChanged() {
      this.$globalState.wizardValues.enableAIStartMessage = this.localEnableAIStartMessage;
      if (!this.localEnableAIStartMessage) {
        if (this.localStartMessage) {
          this.nextDisabled = false;
        }
        else {
          this.nextDisabled = true;
        }
      } else {
        this.nextDisabled = false;
      }
    },
    handleDisableNextButton() {
      this.nextDisabled = true;

      switch (this.currentStep) {
        case 0:
          this.hasAgentDescription = false;
          break;
        case 2:
          this.hasCompanyDescription = false;
          break;
        case 3:
          this.hasGoalDescription = false;
          break;
        case 4:
          this.hasAnswerDescription = false;
          break;
      }
    },
    handleEnableNextButton() {
      switch (this.currentStep) {
        case 0: // Enable if assistantName is provided
          this.nextDisabled = !this.assistantName;
          this.hasAgentDescription = this.assistantName != null;
          break;
        case 2: // Enable if companyName is provided
          this.nextDisabled = !this.companyName;
          this.hasCompanyDescription = this.companyName != null;
          break;
        case 3: // Always enable for step 3
          this.hasGoalDescription = true;
          this.nextDisabled = false;
          break;
        case 4: // Always enable for step 4
          this.hasAnswerDescription = true;
          this.nextDisabled = false;
          break;
        // Add other cases as necessary
        default:
          this.nextDisabled = true; // Default to disabled for other steps
      }
    }
  }
}
</script>

<style scoped>
.scrollable-step-content {
  height: calc(75vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}

.card-shadow:hover {
  box-shadow: 0 8px 12px rgb(97, 97, 97) !important;
  filter: none;
}

.card-shadow {
  transition: box-shadow .2s ease-in-out !important;
  border: 2px solid rgb(211, 211, 211);
  background-color: #fff;
  /* filter: blur(2px);  */
}

.card-title-bold {
  font-weight: bolder;
  padding-bottom: 0.5rem;
}

.card-text-padding {
  padding: 16px;
}
.custom-snackbar {
  width: 550px;
  left: 50%;
  transform: translateX(-50%);
}

.innerSnackbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: white;
  color: #000000;
}

.robot-icon {
  width: 80px;
  height: 80px;
  transition: transform 0.3s ease;
}

.snackbar-text {
  text-align: center;
  font-size: 1.2em;
}

.button-container {
  display: flex;
  justify-content: center;
}

.custom-label {
  font-size: 1.2em; /* Larger font size */
  font-weight: bold; /* Bold text */
  margin-bottom: 0.5em; /* Spacing between label and input */
  color: #333; /* Label text color */
}

/* overlay */

.textarea-with-feedback {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4); /* Subtle shadow */
  z-index: 10;
  display: flex;
  flex-direction: column; /* Align content vertically */
  align-items: center; /* Center align items */
  padding: 10px;
}

.overlay-content {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.robot-iconV2 {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.overlay-text {
  text-align: left;
}

.overlay-close-button {
  margin-top: 10px; /* Spacing above the button */
}

.textarea-container {
  position: relative;
}

.card-padding {
  padding-left:50px;
  padding-right:50px;
  border-radius: 45px;
}

.textinput-class {
  border-radius: 25px
}

.textarea-container {
  position: relative;
  border-radius: 35px;
  border: 1px solid black;
}

.radio-label {
  display: flex;
  align-items: center;
}

.pink-dot {
  color: hwb(322 8% 15%)
}

@media (min-width: 1000px) {
  .h1-class {
    font-size: 36px;
  }
  .h2-class {
    font-size: 30px;
  }
  .p-class {
    font-size: 20px;
  }

}

@media (max-width: 650px) {
  .zoom-class{
    zoom: 80%
  }
}


</style>
