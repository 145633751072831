<template>
    <div class="chat-preview-container">
        <div class="scrollable-step-content">
            <v-col>
                <div class="chatbot-container"
                    :style="{
                        borderRadius: chatbotSettings.chatBlockBorderRadius + 'px',
                        background: chatbotSettings.chatBackgroundType === 'solid'
                                    ? chatbotSettings.firstChatBackgroundColor
                                    : chatbotSettings.chatBackgroundType === 'gradient'
                                    ? `linear-gradient(0deg, ${chatbotSettings.firstChatBackgroundColor}, ${chatbotSettings.secondChatBackgroundColor})`
                                    : `linear-gradient(rgba(255,255,255,${chatbotSettings.chatBackgroundImageTransparency}), rgba(255,255,255,${chatbotSettings.chatBackgroundImageTransparency})), url('${chatbotSettings.chatBackgroundImageSrc}') no-repeat center center / cover`,
                        width: chatbotSettings.chatbotWidth + 'px',
                        height: chatbotSettings.chatbotHeight + 'px'
                    }">

                   <div class="chatbot-header"
                        :style="{
                            background: chatbotSettings.headerBackgroundColorType === 'solid'
                                        ? chatbotSettings.firstHeaderBackgroundColor
                                        : `linear-gradient(${chatbotSettings.headerGradientAngle}deg, ${chatbotSettings.firstHeaderBackgroundColor}, ${chatbotSettings.secondHeaderBackgroundColor})`,
                            borderTopLeftRadius: chatbotSettings.chatBlockBorderRadius + 'px',
                            borderTopRightRadius: chatbotSettings.chatBlockBorderRadius + 'px'
                        }">


                        <!-- <img id="chatbot-header-background"> -->
                        <div v-if="chatbotSettings.includeBotAvatar" style="display: flex; align-items: center;">
                            <!-- Image avatar -->
                            <img v-if="chatbotSettings.typeImageIcon === 'image'"
                                class="chatbot-header-image"
                                :src="chatbotSettings.botImageSrc"
                                :style="{ width: chatbotSettings.botImageIconSize + 'px', height: chatbotSettings.botImageIconSize + 'px' }"
                                alt="chatbot-avatar-image">

                            <!-- Icon avatar -->
                            <div v-else-if="chatbotSettings.typeImageIcon === 'icon'"
                                :style="{ width: chatbotSettings.botImageIconSize + 'px', height: chatbotSettings.botImageIconSize + 'px', backgroundColor: chatbotSettings.botIconBackgroundColor }">
                                <img :src="chatbotSettings.botIconSrc"
                                    :style="{ width: chatbotSettings.botImageIconSize / 2 + 'px' }"
                                    alt="chatbot-avatar-icon">
                            </div>

                            <!-- Header text -->
                            <div class="chatbot-header-text"
                                :style="{ fontSize: chatbotSettings.headerFontSize + 'px', color: chatbotSettings.headerTextColor, fontFamily: chatbotSettings.headerFontType }">
                                <h5 class="m-0"
                                    :style="{color: chatbotSettings.headerTextColor}">
                                        <span v-if="chatbotSettings.language == 'nl'">Chat met</span>
                                        <span v-if="chatbotSettings.language == 'en'">Chat with</span>
                                </h5>
                                {{ chatbotSettings.headerText }}
                            </div>
                        </div>
                        <div v-if="chatbotSettings.enableOnlineMessage" style="display: flex; align-items: center;">
                            <p class="chatbot-status pt-3"></p>
                            <span :style="{color: chatbotSettings.onlineTextColor}">{{chatbotSettings.onlineMessage}}</span>
                        </div>
                    </div>
                    <!-- INNER CONTENT -->
                    <div class="chatbot-content"
                        :style="{ borderRadius: chatbotSettings.chatBlockBorderRadius + 'px'}">
                        <!-- Start message bubble -->
                        <div v-if="chatbotSettings.enableStaticStartMessage" class="message-bubble bot">
                            <img class="me-1"
                                v-if="chatbotSettings.typeImageIcon == 'image' && chatbotSettings.showChatImages"
                                :src="chatbotSettings.botImageSrc"
                                alt="bot-avatar-image"
                                :style="{
                                    width: chatbotSettings.avatarChatSize + 'px',
                                    height: chatbotSettings.avatarChatSize + 'px',
                                }">
                            <img class="me-1"
                                v-if="chatbotSettings.typeImageIcon == 'icon' && chatbotSettings.showChatImages"
                                :src="chatbotSettings.botIconSrc"
                                alt="chatbot-avatar-icon"
                                :style="{
                                    width: chatbotSettings.avatarChatSize + 'px',
                                    height: chatbotSettings.avatarChatSize + 'px',
                                }">
                            <div>
                                <span v-if="chatbotSettings.showDateTime"
                                    class="date bot"
                                    :style="{
                                        fontSize: chatbotSettings.messageDateFontSize + 'px',
                                        color: chatbotSettings.datetimeColor
                                    }">12:30
                                </span>
                                <div :style="{
                                    color: chatbotSettings.botFontColor,
                                    fontSize: chatbotSettings.speechBubbleFontSize + 'px',
                                    fontFamily: chatbotSettings.speechBubbleFontType,
                                    borderRadius: chatbotSettings.speechBubbleBorderRadius + 'px',
                                    backgroundImage: 'linear-gradient(145deg, ' + chatbotSettings.speechBubbleBotBackgroundFirstColor + ', ' + (chatbotSettings.speechBubbleBotBackgroundColorType === 'solid' ? chatbotSettings.speechBubbleBotBackgroundFirstColor : chatbotSettings.speechBubbleBotBackgroundSecondColor) + ')',
                                }"
                                >{{ chatbotSettings.startMessage }}
                                </div>
                            </div>
                        </div>

                        <!-- User message bubble -->
                        <div class="message-bubble user">
                            <!-- column -->
                            <div>
                                <span v-if="chatbotSettings.showDateTime"
                                    class="date user"
                                    :style="{
                                        fontSize: chatbotSettings.messageDateFontSize + 'px',
                                        color: chatbotSettings.datetimeColor }">
                                        13:50
                                </span>
                                <v-row>
                                    <div class="text-white"
                                        :style="{
                                            padding: '15px',
                                            color: chatbotSettings.userFontColor,
                                            fontSize: chatbotSettings.speechBubbleFontSize + 'px',
                                            fontFamily: chatbotSettings.speechBubbleFontType,
                                            borderRadius: chatbotSettings.speechBubbleBorderRadius + 'px',
                                            backgroundImage: 'linear-gradient(145deg, ' + chatbotSettings.speechBubbleUserBackgroundFirstColor + ', ' + (chatbotSettings.speechBubbleUserBackgroundColorType === 'solid' ? chatbotSettings.speechBubbleUserBackgroundFirstColor : chatbotSettings.speechBubbleUserBackgroundSecondColor) + ')'
                                        }">{{ chatbotSettings.messageText }}
                                    </div>

                                    <div v-if="chatbotSettings.showChatImages"
                                        class="message-bubble user-avatar"
                                        :style="{
                                            width: chatbotSettings.avatarChatSize/1 + 'px',
                                            height: chatbotSettings.avatarChatSize/1 + 'px',
                                            backgroundColor: chatbotSettings.userAvatarBackgroundColor,
                                            borderColor: chatbotSettings.userAvatarBorderColor
                                        }">
                                        <i class="fa-solid fa-user"
                                            :style="{
                                                color: chatbotSettings.userAvatarIconColor,
                                                fontSize: chatbotSettings.userAvatarIconSize + 'px'
                                            }">
                                        </i>
                                    </div>
                                </v-row>

                            </div>
                        </div>

                        <!-- Bot message bubble-->
                        <div class="message-bubble bot">
                            <img class="me-1"
                                v-if="chatbotSettings.typeImageIcon == 'image' && chatbotSettings.showChatImages"
                                :src="chatbotSettings.botImageSrc"
                                alt="bot-avatar-image"
                                :style="{
                                    width: chatbotSettings.avatarChatSize + 'px',
                                    height: chatbotSettings.avatarChatSize + 'px',
                                }">
                            <img class="me-1"
                                v-if="chatbotSettings.typeImageIcon == 'icon' && chatbotSettings.showChatImages"
                                :src="chatbotSettings.botIconSrc"
                                alt="chatbot-avatar-icon"
                                :style="{
                                    width: chatbotSettings.avatarChatSize + 'px',
                                    height: chatbotSettings.avatarChatSize + 'px',
                                }">
                            <div>
                                <span v-if="chatbotSettings.showDateTime"
                                    class="date bot"
                                    :style="{
                                        fontSize: chatbotSettings.messageDateFontSize + 'px',
                                        color: chatbotSettings.datetimeColor
                                    }">12:39
                                </span>
                                <div :style="{
                                    color: chatbotSettings.botFontColor,
                                    fontSize: chatbotSettings.speechBubbleFontSize + 'px',
                                    fontFamily: chatbotSettings.speechBubbleFontType,
                                    borderRadius: chatbotSettings.speechBubbleBorderRadius + 'px',
                                    backgroundImage: 'linear-gradient(1455deg, ' + chatbotSettings.speechBubbleBotBackgroundFirstColor + ', ' + (chatbotSettings.speechBubbleBotBackgroundColorType === 'solid' ? chatbotSettings.speechBubbleBotBackgroundFirstColor : chatbotSettings.speechBubbleBotBackgroundSecondColor) + ')',
                                }"
                                >{{ chatbotSettings.messageText }}
                                </div>
                            </div>
                        </div>

                        <!-- Loading dots -->
                        <div class="loading-dots">
                            <span :style="{backgroundColor: chatbotSettings.sendLoadingDotsIconColor}"></span>
                            <span :style="{backgroundColor: chatbotSettings.sendLoadingDotsIconColor}"></span>
                            <span :style="{backgroundColor: chatbotSettings.sendLoadingDotsIconColor}"></span>
                        </div>
                    </div>
                    <!-- FOOTER -->
                    <!-- COLUMN -->
                    <div class="chatbot-footer"
                        :style="{
                        'border-bottom-left-radius': `${chatbotSettings.chatBlockBorderRadius}px`,
                        'border-bottom-right-radius': `${chatbotSettings.chatBlockBorderRadius}px`,
                        'background': chatbotSettings.footerBackgroundColorType !== 'none'
                            ? `linear-gradient(${chatbotSettings.footerBackgroundColorType === 'solid' ? '0' : chatbotSettings.footerGradientAngle}deg, ${chatbotSettings.firstFooterBackgroundColor}, ${chatbotSettings.footerBackgroundColorType === 'solid' ? chatbotSettings.firstFooterBackgroundColor : chatbotSettings.secondFooterBackgroundColor})`
                            : 'none'
                        }">

                        <!-- INPUT -->
                        <textarea
                            :placeholder="chatbotSettings.userInputPlaceholderText"
                            :style="{
                            'margin-top': '25px',
                            'overflow-y': 'hidden',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                            'width': `${chatbotSettings.userInputWidth}%`,
                            'height': `${chatbotSettings.userInputHeight}px`,
                            'font-size': `${chatbotSettings.userInputFontSize}px`,
                            'border-radius': `${chatbotSettings.userInputBorderRadius}px`,
                            'background-color': chatbotSettings.userInputBackgroundColor
                            }"
                        >
                        </textarea>

                        <!-- SEND BUTTON -->
                        <div class="send-button-container"
                            :style="{
                                        'background': chatbotSettings.sendButtonBackgroundColorType !== 'none'
                                        ? `linear-gradient(${chatbotSettings.sendButtonBackgroundColorType === 'solid' ? '0' : chatbotSettings.footerGradientAngle}deg, ${chatbotSettings.sendButtonBackgroundFirstColor}, ${chatbotSettings.sendButtonBackgroundColorType === 'solid' ? chatbotSettings.sendButtonBackgroundFirstColor : chatbotSettings.sendButtonBackgroundSecondColor})`
                                        : 'none'
                                    }">
                            <div class="send-button">
                                <i :style="{
                                    'color': chatbotSettings.sendButtonIconColor, 'fontSize': '16px' }" class="fa-solid fa-paper-plane"></i>
                            </div>
                        </div>

                        <!-- POWERED BY -->
                        <div class="powered-by-container" v-if="chatbotSettings.showPoweredBy">
                            <a href="http://www.hosthub.ai" class="powered-by-text" target="_blank" :style="{ textDecoration: 'none' }">
                            <div class="d-flex align-items-center">
                                <span class="pt-3" :style="{ color: chatbotSettings.poweredByTextColor }">Powered by</span>
                                <img :src="chatbotSettings.poweredByImageSrc" style="width: 150px; height: 43px;">
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
                <h2 style="margin-top: 20px" class="text-center">Button and bubble preview</h2>
                <div style="display: flex; justify-content: center;">
                    <v-row justify="center">
                        <v-col>
                            <div  id="intro-bubble-container">
                                <div v-if="chatbotButtonSettings.showChatbuttonBubble" style="position: relative; padding: 10px;">
                                    <div ref="bubbleContainer" :style="{
                                        position: 'absolute',
                                        top: chatbotButtonSettings.chatbuttonBubbleTopPosition + 'px',
                                        right: chatbotButtonSettings.chatbuttonBubbleRightPosition + 'px',
                                        width: chatbotButtonSettings.chatbuttonBubbleWidth + 'px',
                                        background: chatbotButtonSettings.chatButtonBubbleBackgroundColorType === 'solid'
                                        ? chatbotButtonSettings.firstChatbuttonBubbleBackgroundColor
                                        : `linear-gradient(90deg, ${chatbotButtonSettings.firstChatbuttonBubbleBackgroundColor} 0%, ${chatbotButtonSettings.secondChatbuttonBubbleBackgroundColor} 75%)`,                                                                               
                                        color: chatbotButtonSettings.chatbuttonBubbleTextColor,
                                        padding: '20px',
                                        borderRadius: '25px',
                                        fontFamily: 'Arial, sans-serif'
                                    }">
                                        <div style="font-size: 16px; margin-bottom: 8px;">
                                            {{chatbotButtonSettings.chatbuttonBubbleText}}
                                        </div>
                                        <!-- Balloon Pointer -->
                                        <div :style="{
                                            position: 'absolute',
                                            bottom: '-15px',
                                            right: '10px',
                                            width: '40px',
                                            height: '30px',
                                            'background-color': chatbotButtonSettings.chatButtonBubbleBackgroundColorType === 'solid' 
                                            ? chatbotButtonSettings.firstChatbuttonBubbleBackgroundColor : chatbotButtonSettings.secondChatbuttonBubbleBackgroundColor,
                                            'clip-path': 'polygon(50% 100%, 0 0, 100% 0)'
                                        }"></div>
                                        <button :style="{
                                            position: 'absolute',
                                            top: '-10px',
                                            right: '-10px',
                                            width: '30px',
                                            height: '30px',
                                            'border-radius': '50%',
                                            border: 'none',
                                            'background-color': chatbotButtonSettings.chatbuttonBubbleCloseBtnColor,
                                            color: 'white',
                                            'font-size': '16px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            'align-items': 'center',
                                            'justify-content': 'center'
                                            }" onclick="this.parentElement.style.display='none';"><strong>x</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div id="chatbot-button"
                                :style="{
                                    border: chatbotButtonSettings.borderWidth + 'px solid ' + chatbotButtonSettings.borderColor,
                                    boxShadow: '0 0 ' + chatbotButtonSettings.shadowWidth + 'px ' + chatbotButtonSettings.shadowColor,
                                    width: chatbotButtonSettings.buttonWidth + 'px',
                                    height: chatbotButtonSettings.buttonHeight + 'px',
                                    borderRadius: chatbotButtonSettings.buttonBorderRadius + '%',
                                    backgroundImage: 'linear-gradient(to right, ' + chatbotButtonSettings.buttonBackgroundColor + ', ' + chatbotButtonSettings.buttonBackgroundColorB + ')',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }">
                                <i :style="{
                                    fontSize: chatbotButtonSettings.iconSize + 'px',
                                    color: chatbotButtonSettings.iconColor
                                }" class="fa-solid fa-message">
                                </i>
                            </div>
                           
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </div>
    </div>
</template>

  <script>
  import { chatbotSettings, chatbotButtonSettings } from './chatbotSettings.js';
  import '@fortawesome/fontawesome-free/css/all.min.css';
  export default {
    name: "ChatPreview",
    props: {
          bubbleEffects: Array
    },
    computed: {
        updateScrollBarColor() {
            return this.chatbotSettings.scrollBarColor;
        },
        updateScrollBarWidth() {
            return this.chatbotSettings.scrollBarWidth;
        }
    },

    watch: {
        'chatbotSettings.scrollBarColor'(newColor) {
            this.updateDocumentScrollbarColor(newColor);
        },
        'chatbotSettings.scrollBarWidth'(newWidth) {
            this.updateDocumentScrollbarWidth(newWidth);
        }
    },
    mounted() {
        this.updateDocumentScrollbarColor(this.chatbotSettings.scrollBarColor);
        this.updateDocumentScrollbarWidth(this.chatbotSettings.scrollBarWidth);
    },
    data() {
      return {
        chatbotSettings,
        chatbotButtonSettings,
        includeHeader: true,
      };
    },
    methods: {
        updateDocumentScrollbarColor(newColor) {
            document.documentElement.style.setProperty('--scrollbar-color', newColor);
            document.documentElement.style.setProperty('--scrollbar-thumb-color', newColor);
        },
        updateDocumentScrollbarWidth(newWidth) {
            document.documentElement.style.setProperty('--scrollbar-width', newWidth);
        },
        applyBubbleEffect(effect) {            
            const bubbleContainer = this.$refs.bubbleContainer; // Reference to the bubble element

            if (bubbleContainer) {
                // Remove all possible animation classes first
                this.bubbleEffects.forEach(effect => bubbleContainer.classList.remove(effect.value));

                // Add the selected effect class
                bubbleContainer.classList.add(effect);
            }
        }
    },
  };
  </script>

<style scoped>
:root {
    --scrollbar-width: 8px; /* Default scrollbar width */
    --scrollbar-color: #ccc; /* Default scrollbar color */
}

.chat-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

#chatbot-button {
    cursor: pointer;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.scrollable-step-content {
    height: 750px;
    overflow-y: auto;
    overflow-x: auto;
    display: flex;
    justify-content: center;
}
.chatbot-container {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-top: 8px;
    box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.5);
}
.chatbot-header {
    flex-direction: column;
    padding: 20px;
    clip-path: polygon(100% 0%, 0% 0% , 0.00% 93.38%, 4.00% 94.98%, 8.00% 96.40%, 12.00% 97.56%, 16.00% 98.39%, 20.00% 98.82%, 24.00% 98.85%, 28.00% 98.45%, 32.00% 97.67%, 36.00% 96.54%, 40.00% 95.14%, 44.00% 93.56%, 48.00% 91.90%, 52.00% 90.25%, 56.00% 88.73%, 60.00% 87.43%, 64.00% 86.43%, 68.00% 85.79%, 72.00% 85.56%, 76.00% 85.74%, 80.00% 86.34%, 84.00% 87.30%, 88.00% 88.57%, 92.00% 90.07%, 96.00% 91.71%, 100.00% 93.38%);
}

.chatbot-header-content {
    display: flex;
}

.chatbot-header-image {
    border-radius: 50%;
    margin-right: 10px;
}

.chatbot-header-content .chatbot-header-text {
    flex: 1;
}

.chatbot-header-content .chatbot-header-text h5{
    color: white;
    font-size: 12px;
}

.chatbot-status::before {
    content: "\A";
    width: 7px;
    height: 7px;
    margin-top: 10px;
    margin-right: 6px;
    border-radius: 50%;
    background: #00CF3D;
    display: inline-block;
}

.chatbot-header-content #chatbot-header-icon {
    margin-right: 10px;
    display: none;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 50%;
    background-color: red;
}

.chatbot-header-content svg {
    display: none;
    cursor: pointer;
    align-self: center;
    height: 28px;
    width: 28px;
}

.chatbot-content::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color); /* Use the dynamic color for the thumb */
  border-radius: 4px; /* Optional: Adds rounded corners to the scrollbar thumb */
}

/* Style for the scrollbar track */
.chatbot-content::-webkit-scrollbar-track {
  background: #f0f0f0; /* Provide a default light background color for the track */
}

/* Style for WebKit-based browsers */
.chatbot-content::-webkit-scrollbar {
    width: var(--scrollbar-width);
}

.chatbot-content::-webkit-scrollbar {
    height: var(--scrollbar-width);
}

.chatbot-content {
    flex: 1;
    padding: 0 0 10px 10px;
    overflow-y: scroll;
    scrollbar-color: var(--scrollbar-color) #f0f0f0; /* For Firefox */
}

.message-bubble {
    display: flex;
}

.message-bubble.user {
    margin: 0 0 10px 36px;
}

.message-bubble img{
    /* display: none; */
    width: 28px;
    height: 28px;
    border-radius: 50%;
    align-self: flex-end;
}

.message-bubble .message {
    display: flex;
    flex-direction: column;
    max-width: 85%;
    word-wrap: break-word;
}

.message-bubble.user {
    justify-content: flex-end;
}

.message-bubble.user-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 50%;
}

.message-bubble.bot {
    justify-content: flex-start;
}

.message-bubble.bot div{
    margin: 0 36px 10px 0;
}

.message-bubble .date{
    margin-top: 3px;
    font-size: 10px;
    font-style: italic;
    color: grey;
}

.message-bubble .date.bot{
    text-align: start;
}

.message-bubble .date.user{
    text-align: end;
}

.message-bubble p {
    padding: 14px;
    word-break: break-word;
    margin: 0;
}

.message-bubble div {
    padding: 10px;
    border-radius: 18px;
    margin: 5px 0;
}

.chatbot-footer {
    height: 130px;
    padding: 0px 10px 10px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chatbot-footer img {
    cursor: pointer;
    width: 19px;
    height: 19px;
}

.chatbot-footer textarea {
    font-size: 12px;
    line-height: 1.22;
    resize: none;
    border: none;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 1px 5px 12px rgba(0, 0, 0, 0.3);
    background: transparent;
}

.chatbot-footer textarea::-webkit-scrollbar {
    width: var(--scrollbar-width);
}

.chatbot-footer textarea::-webkit-scrollbar-track {
    background: transparent;
}

.chatbot-footer textarea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--scrollbar-thumb-color);
}

.chatbot-footer textarea:focus-visible {
    outline: none;
    box-shadow: 1px 5px 12px rgba(0, 0, 0, 0.4);
}
.chatbot-footer .send-button-container {

right: -30px;
/* top: 56px; */
top: 20px;
/* height: 50px;
width: 50px; */
height: 60px;
width: 60px;
cursor: pointer;
position: absolute;
border: none;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.4);
}

.chatbot-footer .send-button-container #send-button {
    margin-left: 4px;
    -webkit-mask:url("./icons/paper.svg") center/contain;
    mask:url("./icons/paper.svg") center/contain;
}

.powered-by-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: auto;
}

.powered-by-text {
    font-size: 12px;
}

.loading-dots {
    display: flex;
    justify-content: start;
    margin-top: 20px;
    height: 10px;
    width: 100%;
}

.loading-dots span {
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.loading-dots span:not(:last-child) {
    margin-right: 3px;
}

.loading-dots span:nth-child(1) {
    animation: loading .9s linear 0s infinite alternate;
}

.loading-dots span:nth-child(2) {
    animation: loading .9s linear .3s infinite alternate;
}

.loading-dots span:nth-child(3) {
    animation: loading .9s linear .6s infinite alternate;
}

@keyframes loading {
    from {transform: translateY(0)}
    to {transform: translateY(-10px)}
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
.fadeInEffect {
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
}
.bounceEffect {
    animation: bounce 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.7;
    }
    50% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.7;
    }
}

.pulseEffect {
    animation: pulse 2s infinite;
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slideInEffect {
    animation: slideIn 0.5s ease-out forwards;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.floatEffect {
    animation: float 3s ease-in-out infinite;
}

@keyframes typing {
    0% {
        width: 0;
    }
    100% {
        width: 100%; 
    }
}

@keyframes blink-caret {
    50% {
        border-color: transparent;
    }
}

.typingEffect {
    border-right: 2px solid;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 4s steps(50, end), blink-caret .75s step-end infinite;
}


@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
        transform: translateX(-10px);
    }
    20%, 40%, 60%, 80% {
        transform: translateX(10px);
    }
}

.shakeEffect {
    animation: shake 1s ease-in-out;
}

@keyframes popIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    60% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

.popInEffect {
    animation: popIn 0.5s ease forwards;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    14% {
        transform: scale(1.3);
    }
    28% {
        transform: scale(1);
    }
    42% {
        transform: scale(1.3);
    }
    70% {
        transform: scale(1);
    }
}

.heartbeatEffect {
    animation: heartbeat 2s infinite;
}

@keyframes swing {
    15% {
        transform: rotate(5deg);
    }
    30% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(3deg);
    }
    65% {
        transform: rotate(-3deg);
    }
    80% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.swingEffect {
    transform-origin: top center;
    animation: swing 1s infinite;
}

@keyframes spotlight {
    0% {
        box-shadow: 0 0 10px 5px rgba(255, 255, 0, 0.5);
    }
    100% {
        box-shadow: 0 0 10px 5px rgba(0, 255, 255, 0.5);
    }
}

.spotlightEffect {
    animation: spotlight 3s alternate infinite;
}

@keyframes flipIn {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
        opacity: 1;
    }
}

.flipInEffect {
    backface-visibility: hidden;
    animation: flipIn 0.5s forwards;
}

</style>
