<template>
    <v-card class="window-card">
      <div class="chart-title">ADMIN - Gemiddelde kosten
        <span v-if="isMessage">{{ $t('message').toLowerCase() }}</span>
        <span v-if="!isMessage">{{ $t('conversation').toLowerCase() }}</span>
      </div>
      <apex-chart :options="options" :series="series"></apex-chart>
    </v-card>
</template>

<script>
  import axios from 'axios';
  import { EventBus } from './../../eventBus.js';
  export default {
    name: 'AdminAverageMessagesAndCostsWidget',
    data() {
      return {
        isMessage: false,
        options: {
          chart: {
            type: 'bar',
            stacked: true,  // To show two sets of data
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          xaxis: {
            categories: []
          },
          yaxis: [{
            title: {
              text: this.$t('average_user_messages'),
            }
          }, {
            opposite: true,
            title: {
              text: this.$t('average_cost')
            }
          }]
        },
        series: [
          {
            name: this.$t('average_user_messages'),
            data: []
          },
          {
            name: this.$t('average_cost'),
            data: []
          }
        ]
      };
    },
    created() {
      EventBus.$on('reload-chart', this.loadData);
    },
    beforeDestroy() {
      EventBus.$off('reload-chart', this.loadData);
    },
    mounted() {
      this.loadData();
    },
    methods: {
      loadData() {
        const token = localStorage.getItem('userToken');
        if (!token) {
          console.error("No token found");
          return;
        }
        const widget_id = 'widget_adm_4'; // ID for the new backend function
        const isMessage = this.isMessage;
        axios.get(this.$config.configurationServer + '/dashboard/get_widget_data', {
          headers: { Authorization: `Bearer ${token}` },
          params: { widget_id, isMessage }
        })
        .then(response => {
          if (response.status === 200 && Array.isArray(response.data)) {
            this.options = {
              ...this.options,
              xaxis: {
                ...this.options.xaxis,
                categories: response.data.map(item => item.configuration_name)
              }
            };
            this.series[0].data = response.data.map(item => item.average_user_messages);
            this.series[1].data = response.data.map(item => item.average_cost);
          } else {
            console.error('Error loading data:', response);
          }
        })
        .catch(error => {
            EventBus.$emit('show-snackbar', {
              type: 'ERROR',
              message: 'Data retrieval unsuccessful: ' + error.message
            });
            console.error('Error fetching widget data:', error);
        });
      }
    },
    watch: {
      isMessage(newValue) {
        this.type = newValue ? this.$t('message') : this.$t('conversation');
      }
    }
  }
  </script>

  <style scoped>
  .window-card { }

  .chart-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  </style>
