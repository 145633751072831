<template>
  <v-container style="max-width:100%; padding:0px;">
    <v-row justify="center">
      <v-col cols="12">
        <v-card style="background-color: #FDF1F7; box-shadow: none;">
          <!-- Card Text/Content -->
          <v-card-text class="text-center">
            <v-row class="justify-center">
              <v-col cols="6" class="align-self-center">
                  <h2 class="h2-class" style="color:#E140A0; font-weight: 100; padding:10px;">{{$t('self_service_onboarding')}}</h2>
                  <h1 class="h1-class" style="padding:10px;"><span style="line-height: 1.5;">{{$t('welcome')}}!</span>{{$t('lets_get_started_with_your_ai_assistant')}}<span style="color:#E140A0;">.</span></h1>
                  <p class="p-class" v-html="$t('onboarding_message')"></p>
              </v-col>
            </v-row>
            <!-- Centered Language Selector if Not Selected -->
            <v-select
              v-if="!selectedLanguage"
              v-model="selectedLanguage"
              :items="languages"
              @change="changeLanguage"
              dense
              class="my-4"
              style="max-width: 150px; margin: 0 auto;"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StepOneWelcome",
  props: {
    configuration: Object
  },
  data() {
    return {
      // If these are not globally available, define them here
      //selectedLanguage: localStorage.getItem('userLanguage') || 'en', // default language
      selectedLanguage: null,
      configurationName: ''
    };
  },
  mounted() {
    if (this.configuration) {
      this.configurationName = this.configuration.name;
      const userLanguage = localStorage.getItem('userLanguage') || 'nl';
      this.selectedLanguage = userLanguage;
    }
  },
  watch: {
    configurationName(newName) {
      this.$emit('configuration-name-updated', newName);
    }
  },
  computed: {
    languages() {
      return [
        { text: this.$t('language.english'), value: 'en' },
        { text: this.$t('language.dutch'), value: 'nl' },
        { text: this.$t('language.german'), value: 'de' },
        { text: this.$t('language.french'), value: 'fr' },
        { text: this.$t('language.spanish'), value: 'es' },
        { text: this.$t('language.portuguese'), value: 'pt' }
      ];
    }
  },
  methods: {
    changeLanguage(newLang) {
      this.$i18n.locale = newLang;
      this.$vuetify.lang.current = newLang;
      localStorage.setItem('userLanguage', newLang);
    },
  },
};
</script>

<style scoped>

@media (min-width: 1000px) {
  .h1-class {
    font-size: 36px;
  }
  .h2-class {
    font-size: 30px;
  }
  .p-class {
    font-size: 20px;
  }
}

.card-shadow:hover {
  box-shadow: 0 8px 12px rgb(97, 97, 97) !important;
}

.card-shadow {
  transition: box-shadow .2s ease-in-out !important;
  border: 2px solid rgb(211, 211, 211);
  background-color: #fff;
}

.language-selector {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px; /* Adjust as needed */
  margin-right: 10px; /* Adjust as needed */
}

.card-title-bold {
  font-weight: bolder;
  padding-bottom: 0.5rem;
}

.card-text-padding {
  padding: 16px;
}
</style>
