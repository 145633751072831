<template>
  <v-card class="zoom-class relative-position" style="box-shadow: none;">
    <v-row justify="center">
      <v-col style="padding-right:0px;" cols="12" xl="2" lg="2" md="4" sm="3">
        <div>
          <h1 class="h1-class" style="padding:10px;">{{$t('test_here_your_ai_agent')}}<span class="pink-dot">.</span></h1>
          <div id="iframe-container" ref="iframeContainer"></div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { EventBus } from '../../eventBus.js';
export default {
  name: "StepFourTest",
  props: {
    configuration: Object
  },
  data() {
    return {
      chatbotHost: this.$config.chatbotHost, // Assuming this comes from a global config
    };
  },
  created() {
    EventBus.$on('execute-refresh-test', this.createIframe);
  },
  methods: {
    createIframe() {
      const configurationId = this.configuration._id;
      const iframeContainer = this.$refs.iframeContainer;
      iframeContainer.innerHTML = ''; // Clear previous iframe if any

      const chatbotHost = this.$config.chatbotHost;
      const iframe = document.createElement('iframe');

      iframe.id = 'host-hub-chatbot';
      //iframe.style.position = 'relative';
      iframe.style.height = '600px';
      iframe.style.width = '450px';
      iframe.style.overflow = 'hidden';
      //iframe.style.bottom = '0px;'
      iframe.src = `${chatbotHost}/chatbot.html?version=01&configId=${configurationId}&initialState=close&test=true`;
      iframeContainer.appendChild(iframe);
    },
    adjustIframeScale() {
    },
    handleMessage(event) {
      if (event.origin === this.chatbotHost) {
        //const iframe = document.getElementById('host-hub-chatbot');
        // Implement message handling logic here
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.adjustIframeScale);
    window.addEventListener('message', this.handleMessage);
    this.adjustIframeScale();

    if (this.configuration._id) {
      this.createIframe();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustIframeScale);
    window.removeEventListener('message', this.handleMessage);
  },
};
</script>

<style scoped>

.h1-class {
    font-size: 30px;
}

.pink-dot {
  color: hwb(322 8% 15%)
}


.iframe-wrapper {
  position: relative;
  /* ... other styles as needed ... */
}
.relative-position {
  position: relative;
  /* Ensure the v-card has a minimum height for the iframe to sit at the bottom */
  min-height: 600px; /* Adjust based on your needs */
}

.iframe-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  /* Set exact size for the iframe or make it responsive as needed */
  width: 130px; /* Example width */
  height: 130px; /* Example height */
}

@media (max-width: 600px) {
  .mobile-zoom{
    zoom: 90%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 85%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 80%
  }
}

@media (max-width: 500px) {
  .mobile-zoom{
    zoom: 75%
  }
}

@media (max-width: 450px) {
  .mobile-zoom{
    zoom: 70%
  }
}

@media (max-width: 400px) {
  .mobile-zoom{
    zoom: 65%
  }
}
</style>
