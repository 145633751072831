<template>
  <v-card class="window-card" outlined>
    <v-card-title class="chart-title pl-15">
      ADMIN - {{ $t('widgets.recurring_revenue_title') }}
    </v-card-title>
    <v-card-text>      
      <v-row justify="center" no-gutters>
        <v-col cols="3" class="revenue-item">
          <span class="label">{{ $t('monthly') }}</span>
        </v-col>
        <v-col cols="3" class="revenue-item">
          <span class="label">{{ $t('annual') }}</span>
        </v-col>
        <v-col cols="3" class="revenue-item">
          <span class="label">{{ $t('widgets.active_subscriptions') }}</span>
        </v-col>
      </v-row>

      <!-- Use a slower fade transition for when data appears -->
      <v-fade-transition mode="out-in" class="slow-fade">
        <div v-if="!loading" key="data">
          <v-row justify="center" no-gutters>
            <v-col cols="3" class="revenue-item">
              <span class="value">{{ monthlyRevenue }} EUR</span>
            </v-col>
            <v-col cols="3" class="revenue-item">
              <span class="value">{{ yearlyRevenue }} EUR</span>
            </v-col>
            <v-col cols="3" class="revenue-item">
              <span class="value">{{ activeSubscriptions }}</span>
            </v-col>
          </v-row>
        </div>

        <!-- Show a loading state until data is fetched -->
        <div v-else key="loading" class="loading-placeholder">
          <v-row justify="center" no-gutters>
            <v-col cols="3" class="revenue-item">
              <span class="value">Loading...</span>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EventBus } from '../../eventBus.js';
  import axios from 'axios';

  export default {
    name: 'AdminRecurringRevenueWidget',
    data() {
      return {
        monthlyRevenue: 0,
        yearlyRevenue: 0,
        activeSubscriptions: 0,
        loading: true // Loading state
      };
    },
    created() {
      EventBus.$on('reload-chart', () => {
        this.loadData();
      });
    },
    mounted() {
      this.loadData();
    },
    methods: {
      async loadData() {
        this.loading = true; // Set loading to true when data fetching starts
        const token = localStorage.getItem('userToken');
        if (token) {
          const widget_id = 'widget_adm_5';
          return axios.get(this.$config.configurationServer + '/dashboard/get_widget_data', {
            headers: { Authorization: `Bearer ${token}` },
            params: { widget_id }
          })
          .then(response => {
            const data = response.data;
            if (response.status === 200) {
              // Update stats
              this.monthlyRevenue = data.monthly_revenue_total;
              this.yearlyRevenue = data.yearly_revenue_total;
              this.activeSubscriptions = data.active_subscriptions;
            } else {
              console.log('*** error: ', response);
            }
          })
          .catch(error => {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('data_retrieval_unsuccessful') + error });
          })
          .finally(() => {
            this.loading = false; // Data fetching complete, hide loading state
          });
        } else {
          this.loading = false; // Handle case when no token is available
          return Promise.resolve([]);
        }
      }
    }
  };
</script>

<style scoped>
.window-card {
  padding: 20px;
  background-color: #f0f4ff; /* Light background color */
}

.revenue-item {
  text-align: center;
}

.label {
  font-weight: 600;
  font-size: 16px; /* Smaller header for label */
}

.value {
  font-size: 18px;
  margin-top: 8px;
}

.loading-placeholder {
  text-align: center;
  color: grey;
  font-style: italic;
}

/* Slower fade transition */
.slow-fade > * {
  transition: opacity 1.5s ease; /* Adjust the duration and easing here */
}
</style>
