<template>
  <v-container class="pt-5">
    <v-card flat>
      <v-row>
        <!-- Column for Vertical Tabs -->
        <v-col cols="2">
          <v-tabs v-model="currentTab" vertical  light>
            <v-tab>{{$t('functions')}}</v-tab>
            <v-tab>{{$t('workflow')}}</v-tab>
          </v-tabs>
        </v-col>

        <!-- Column for Tab Content -->
        <v-col cols="10">
          <v-tabs-items v-model="currentTab">
            <!-- First Tab Content -->
            <v-tab-item>
              <AifunctionsTabFunctions />
            </v-tab-item>

            <!-- Second Tab Content -->
            <v-tab-item>
              <AiFunctionsTabWorkflow />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
// import { EventBus } from '../../eventBus.js';
// import Vue from 'vue';
import AifunctionsTabFunctions from './aiFunctionsTabFunctions.vue';
import AiFunctionsTabWorkflow from './aiFunctionsTabWorkflow.vue';

export default {
  name: 'AiFunctionsTab',
  data: () => ({
    currentTab: 0,
  }),
  components: {
    AifunctionsTabFunctions,
    AiFunctionsTabWorkflow,
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {},
};
</script>

<style scoped>
/* Additional styling if necessary */
</style>
