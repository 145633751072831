<template>
  <div class="textarea-container">
    <v-textarea
      @input="inputValueChanged"
      style="padding-left:20px;"
      @blur="setInputValue"
      :placeholder="placeholder"
      v-model="inputValue" />
      <div class="button-container">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!loading"
                  :disabled="!inputValue"
                  @click="validateInput"
                  v-bind="attrs"
                  v-on="on"
                  fab
                  small
                  color="#3dd1f6">
              <span style="color:white;" class="mdi mdi-lightbulb-on mdi-24px"></span>
            </v-btn>
          </template>
          <span>{{$t('validate_your_input')}}</span>
        </v-tooltip>
      </div>

    <!-- v-bottom-sheet component -->
    <v-bottom-sheet v-model="sheet" persistent>
      <v-sheet class="bottom-sheet-content" height="auto">
        <div class="bottom-sheet-header">
          <v-btn icon @click="closeBottomSheet" class="close-button">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="bottom-sheet-inner-content">
          <!-- Your bottom sheet text and other contents here -->
          <!-- <img v-if="loading" src="../icons/robot.gif" alt="Processing Robot" class="robot-icon">
          <img v-else src="../icons/robot_done.gif" alt="Completed Robot" class="robot-icon"> -->
          <div v-if="loading" class="text-center">
            <v-progress-linear indeterminate color="pink lighten-1"></v-progress-linear>
            <span class="d-block">{{$t('we_are_validating')}}</span>
          </div>
          <div class="overlay-text" v-html="overlayText"></div>
          <v-btn
            v-if="!loading"
            large
            color="primary"
            class="copy-text-button"
            @click="copyExampleText">
            {{$t('use_this_example')}}         
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
//import { EventBus } from '../../eventBus.js';

export default {
    components: {
      //ClickableText
    },
    props: {
      placeholder: String,
      value: String,
      feedback: String,
      sessionId: String,
      socket: Object,
      identifier: String
    },
    data() {
      return {
        inputValue: '',
        // originalValue: this.value,
        showOverlay: false,
        loading: false,
        overlayText: '',
        // server: this.$config.chatServer,
        configurationId: this.$config.promptbotConfigurationId,
        showCopySuggestion: false,
        isValidated: false,
        isValidateEnabled: false,
        sheet: false,
        longestExampleText: '',
      };
    },
    mounted() {
      this.getInputValue();
    },
    methods: {
        inputValueChanged() {
          if(this.inputValue && this.inputValue.length > 0) {
            this.$emit('enable-next-button');
          }
          else {
            this.$emit('disable-next-button');
          }
        },

        getInputValue() {
           switch (this.identifier) {
            case 'companyDescription':
              this.inputValue = this.$globalState.wizardValues.companyDescription;
              break;
             case 'aiAssistantDescription':
              this.inputValue = this.$globalState.wizardValues.aiDescription;
              break;
            case 'goalDescription':
              this.inputValue = this.$globalState.wizardValues.goalDescription;
              break;
            case 'answerDescription':
              this.inputValue = this.$globalState.wizardValues.answerDescription;
              break;
            case 'generalInformationDescription':
              this.inputValue =this.$globalState.wizardValues.generalInformationDescription;
              break;
           }
        },

        setInputValue() {
          switch (this.identifier) {
            case 'companyDescription':
              this.$globalState.wizardValues.companyDescription = this.inputValue;
              break;
            case 'aiAssistantDescription':
              this.$globalState.wizardValues.aiDescription = this.inputValue;
              break;
            case 'goalDescription':
              this.$globalState.wizardValues.goalDescription = this.inputValue;
              break;
            case 'answerDescription':
              this.$globalState.wizardValues.answerDescription = this.inputValue;
              break;
            case 'generalInformationDescription':
              this.$globalState.wizardValues.generalInformationDescription = this.inputValue;
              break;
          }
        },
        validateInput() {
          this.showCopySuggestion = false;
          if (this.inputValue.trim() !== '' && this.inputValue !== this.originalValue) {
              this.isValidated = true;
              this.sendMessage();
              this.originalValue = this.inputValue;
              this.$emit('input-validated', { identifier: this.identifier, value: this.inputValue });
              this.sheet = true;
          }
        },
        closeBottomSheet() {
          this.sheet = false;
        },
        highlightText(event) {
          // Directly use the event target if available
          event.target.select();
        },
        closeOverlay() {
            //this.overlayText = this.$t('processing') + '...';
            this.overlayText = 'Wij valideren uw omschrijving...'
            this.showOverlay = false;
        },
        sendMessage() {
            let userMessage = this.inputValue.trim();
            if (userMessage === '') return;

            let contextMessage = "";

            switch (this.identifier) {
                case 'companyDescription':
                    contextMessage = this.$t('please_review_the_following_company_description');
                    break;
                case 'aiAssistantDescription':
                    contextMessage = this.$t('please_review_the_following_ai_assistant_description');
                    break;
                case 'goalDescription':
                    contextMessage = this.$t('please_review_the_following_goal_of_the_ai_assistant');
                    break;
                case 'answerDescription':
                    contextMessage = this.$t('please_review_the_following_approach_responses');
                    break;
                case 'generalInformationDescription':
                    contextMessage = this.$t('please_review_the_following_general_information');
                    break;
                case 'urlDescriptions':
                    contextMessage = "Please review the following general information of the company: ";
                    break;
                // Add additional cases if there are more text areas
                default:
                    contextMessage = "";
            }

            userMessage = contextMessage + userMessage;
            this.$emit('updateLastActive', this.identifier);

            // Send the message to the server via WebSocket
            if (this.socket && this.sessionId) {
                this.socket.emit('room_message', {
                    session_id: this.sessionId,
                    user_input: userMessage
                });

                this.showOverlay = true;
                this.loading = true;
            }
        },
        copyExampleText() {
          if (this.longestExampleText) {
            this.inputValue = this.longestExampleText;
            this.setInputValue();
            this.overlayText = false;
            this.sheet = false;
          }
        },
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue;
      },
      feedback(newFeedback) {
        if (newFeedback) {
          const regex = /"([^"]+)"/g;
          let match;
          let longestText = '';
          let longestLength = 0;

          // Find all text segments within quotes and determine the longest
          while ((match = regex.exec(newFeedback)) !== null) {
            if (match[1].length > longestLength) {
              longestText = match[1];
              longestLength = match[1].length;
            }
          }

          // Replace all quoted segments with inline style for the longest
          this.overlayText = newFeedback.replace(/"([^"]+)"/g, (match, p1) => {
            if (p1 === longestText) {
              return `<span style="color: #543790; font-weight: bolder;">"${p1}"</span>`;
            }
            return `"${p1}"`;
          });

          this.longestExampleText = longestText; // Store the longest text
          this.showCopySuggestion = longestLength > 0;
          this.loading = false;
          this.showOverlay = true;
        }
      },
    },
};
</script>

<style scoped>

.textarea-with-feedback {
  position: relative;
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 25px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4); /* Subtle shadow */
  z-index: 10;
  display: flex;
  flex-direction: column; /* Align content vertically */
  align-items: center; /* Center align items */
  padding: 10px;
  width: 80%; /* Example: 80% of the viewport width */
  max-width: 500px; /* Example: Max width of 500px */
  height: auto; /* Adjust height as needed, could be fixed or auto */
}

.overlay-content {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.robot-icon {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.overlay-text {
  text-align: left;
  color:rgb(91, 91, 91);
}

.overlay-close-button {
  margin-top: 10px; /* Spacing above the button */
}

.textarea-container {
  position: relative;
  border-radius: 35px;
  border: 1px solid black;
}

.button-container {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.overlay-button-container, .action-button-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 10px; /* Add some space between buttons */
}
/* sheet */

.bottom-sheet-header {
  display: flex;
  justify-content: flex-end; /* Align the close button to the right */
  padding: 10px; /* Padding around the close button */
}

.close-button {
  padding-right: 10px; /* Adjust if needed for alignment */
}

.bottom-sheet-content {
  max-width: 600px; /* Set maximum width of the bottom sheet content */
  margin-left: auto; /* Center the content horizontally */
  margin-right: auto;
}

.bottom-sheet-inner-content {
  padding: 20px; /* Adjust padding as needed */
  text-align: center; /* Center the text */
}

.copy-text-button {
  margin-top: 20px; /* Spacing above the button */
}

.highlighted-text {
  color: pink;
}

</style>
