<template>
    <v-dialog v-model="localDialog" persistent max-width="60vw">
      <v-card class="dialog-card">
        <v-card-title class="grey primary white--text">
          <!-- Title centered -->
          <div class="flex-grow-1 text-center">
            {{$t("create_custom_dataset")}}
          </div>
          <v-btn icon @click="close" class="close-button ml-auto">
            <v-icon color="white" size="36">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
  
        <v-card-text class="pt-5">
          <v-row no-gutters justify="center" v-if="isAdmin">
            <v-col cols="4">
              <v-text-field
                dense
                :label="$t('dataset_name')"
                :placeholder="$t('enter_dataset_name')"
                v-model="dataset.datasetName"
                persistent-placeholder
              ></v-text-field>
            </v-col>
          </v-row>
  
          <!-- Custom Table for Dataset -->
          <div ref="tableContainer" class="table-container">
            <table class="custom-table">
              <thead>
                <tr>
                  <th v-for="header in datasetHeaders" :key="header.value">
                    {{ header.text }}
                  </th>
                  <th v-if="dataset.datasetData.length > 0">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dataset.datasetData" :key="index">
                  <td v-for="header in datasetHeaders" :key="header.value">
                    <v-text-field
                      v-model="item[header.value]"
                      dense
                      hide-details
                    ></v-text-field>
                  </td>
                  <td v-if="dataset.datasetData.length > 0">
                    <v-btn icon @click="deleteRow(index)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
  
        <v-card-actions class="card-actions">
          <v-row no-gutters class="justify-center">
            <v-btn dense @click="addColumn" color="primary" class="mt-3" v-if="isAdmin">
              {{$t("add_column")}}
            </v-btn>
            <v-btn v-if="isAdmin"
              :disabled="datasetHeaders.length === 0"
              dense
              @click="addRecord"
              color="primary"
              class="mt-3"
            >
              {{$t("add_record")}}
            </v-btn>
            <v-btn
              :disabled="dataset.datasetData.length === 0 || !dataset.datasetName"
              dense
              @click="generateDataset"
              color="secondary"
              class="mt-3"
            >
              {{$t("generate_dataset")}}
            </v-btn>
            <v-btn dense color="red" @click="showDeleteConfirmation" class="mt-3" v-if="isAdmin">
              {{$t("delete_dataset")}}
            </v-btn>
          </v-row>         
        </v-card-actions>
      </v-card>
  
      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteConfirmationDialog" max-width="500px">
        <v-card>
          <v-card-title class="justify-center grey primary white--text">
            {{$t("confirm_delete")}}
          </v-card-title>
          <v-card-text class="dialog-content">
            {{$t("delete_dataset_confirmation")}}
          </v-card-text>
          <v-card-actions class="card-actions text-center">
            <v-row no-gutters justify="center">
              <v-btn color="secondary" @click="deleteConfirmationDialog = false">
                {{$t("cancel")}}
              </v-btn>
              <v-btn color="primary" class="white--text" @click="confirmDelete">
                {{$t("confirm")}}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </template>
  
  <script>
  import axios from 'axios';
  import { EventBus } from '@/eventBus';
  
  export default {
    props: {
      dialog: Boolean,
    },
    data() {
      return {
        localDialog: this.dialog,
  
        // Dataset object
        dataset: {
          datasetName: '',
          datasetData: [],
        },
        datasetHeaders: [],
        nextRecordId: 1,
        deleteConfirmationDialog: false, // To control the delete confirmation dialog
      }
    },
    computed: {
      selectedConfiguration() {
        return this.$store.getters.selectedConfiguration;
      },
      isAdmin() {                
        return this.$store.state.userRole === 'admin';
      }
    },
    watch: {
      dialog(newVal) {
        this.localDialog = newVal;
      },
      localDialog(newVal) {
        this.$emit('update:dialog', newVal);
      }
    },
    mounted() {
      this.initialize();
    },
    created() {
        EventBus.$on('clear', this.clearFields);         
    },
    methods: {
      close() {
        this.localDialog = false;
        this.$emit('close-dialog');
      },
  
      clearFields() {
        this.dataset.datasetName = '';
        this.dataset.datasetData = [];
        this.datasetHeaders = [];
        this.nextRecordId = 1;
        this.getDataSet();
      },
  
      async initialize() {
        await this.getDataSet();
      },
      async getDataSet() {
        try {
          const token = localStorage.getItem('userToken');

          const response = await axios.get(`${this.$config.configurationServer}/data/dataset`, {
            headers: {
              'Authorization': `Bearer ${token}`
            },
            params: {
              configurationId: this.selectedConfiguration._id
            }
          });
          
          if (response.data.status === 'OK') {
            const data = response.data.data;
            this.dataset.datasetName = data.dataset_name || '';
            this.dataset.datasetData = data.dataset_data || [];
            this.datasetHeaders = data.dataset_headers || []; // Load the saved headers

            // If no saved headers, create them based on dataset data
            if (!this.datasetHeaders.length && this.dataset.datasetData.length > 0) {
              const firstRecord = this.dataset.datasetData[0];
              this.datasetHeaders = Object.keys(firstRecord).map(key => ({
                text: key,
                value: key
              }));
            }
          }

        } catch (error) {
          console.error(error);
        }
      },

      async generateDataset() {
        EventBus.$emit('show-overlay');
        try {
          const token = localStorage.getItem('userToken');
          const datasetItem = {
            configurationId: this.selectedConfiguration._id,
            datasetName: this.dataset.datasetName,
            datasetData: this.dataset.datasetData,
            datasetHeaders: this.datasetHeaders // Add the headers to the dataset object
          };
          
          const response = await axios.post(`${this.$config.configurationServer}/data/dataset`, datasetItem, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          
          if (response.data.status === 'OK') {
            EventBus.$emit('show-snackbar', {
              type: 'SUCCESS',
              message: this.$t('dataset_saved_successfully')
            });
          } else {
            throw new Error(response.data.message);
          }
        } catch (error) {
          EventBus.$emit('show-snackbar', {
            type: 'ERROR',
            message: `${this.$t('error')} : ${error.message}`
          });
        } finally {
          EventBus.$emit('hide-overlay');
        }
      },

      async removeDataset() {
        EventBus.$emit('show-overlay');
        try {
            const token = localStorage.getItem('userToken');
            const response = await axios.delete(`${this.$config.configurationServer}/data/dataset`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    configurationId: this.selectedConfiguration._id
                }
            });
            if (response.data.status === 'OK') {
                EventBus.$emit('show-snackbar', {
                    type: 'SUCCESS',
                    message: this.$t('dataset_removed_successfully')
                });
                // Clear the local dataset after removal
                this.clearFields();
            } else if (response.data.status === 'NOT_FOUND') {
                EventBus.$emit('show-snackbar', {
                    type: 'WARNING',
                    message: this.$t('dataset_not_found')
                });
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            EventBus.$emit('show-snackbar', {
                type: 'ERROR',
                message: `${this.$t('error')} : ${error.message}`
            });
        } finally {
            EventBus.$emit('hide-overlay');
        }
      },

      showDeleteConfirmation() {
        this.deleteConfirmationDialog = true;
      },
      confirmDelete() {
        this.deleteConfirmationDialog = false;
        this.removeDataset();
      },
      addColumn() {
        const columnName = prompt('Enter column name');
        if (columnName) {
          this.datasetHeaders.push({ text: columnName, value: columnName });
          this.dataset.datasetData.forEach(record => {
            record[columnName] = '';
          });
        }
      },
      addRecord() {
        const newRecord = {};
        this.datasetHeaders.forEach(header => {
          newRecord[header.value] = '';
        });
        this.dataset.datasetData.push(newRecord);
  
        // Scroll to the bottom after adding a new record
        this.$nextTick(() => {
          const container = this.$refs.tableContainer;
          container.scrollTop = container.scrollHeight;
        });
      },
      deleteRow(index) {
        this.dataset.datasetData.splice(index, 1);
      }
    }
  }
  </script>
  
  <style scoped>
  .table-container {
    max-height: 200px;
    overflow-y: auto;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 8px;
    text-align: left;
  }
  
  .custom-table th {
    background-color: #f5f5f5;
    vertical-align: middle; /* Vertically center the header content */
    text-align: center; /* Horizontally center the header content */
  }
  
  .custom-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .center-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  