<template>
  <v-container class="pt-5">
    <v-card flat>
      <v-row no-gutters justify="center">
        <!-- Navigation drawer -->
        <v-col cols="4">
          <div>
            <v-navigation-drawer
            dark
              dense
              v-model="drawer"
              :mini-variant.sync="mini"
              floating
              permanent
              width="100%"
              height="50vh"
            >
            <v-list-item class="px-2">
              <v-list-item-avatar>
                <v-icon>mdi-cogs</v-icon>
              </v-list-item-avatar>
              <v-list-item-title><span><strong>{{currentSection.toUpperCase()}}</strong></span></v-list-item-title>
              <v-btn
                icon
                @click="currentSection = ''"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item @click="currentSection = 'templates'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'templates'}">
                  <v-list-item-title v-if="currentSection != 'templates'">{{$t('templates')}}</v-list-item-title>
                  <div v-if="currentSection == 'templates'">
                    <div style="display: flex; flex-direction: column; align-items: flex-start;">
                      <v-btn @click="setTemplateColors(1)" style="margin-bottom: 8px;">
                        <div style="display: flex; align-items: center; justify-content: center;">
                          <div style="width: 20px; height: 10px; background-color: #6d00e1; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #fff; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #00b5fd;"></div>
                        </div>
                      </v-btn>
                      <v-btn @click="setTemplateColors(2)" style="margin-bottom: 8px;">
                        <div style="display: flex; align-items: center; justify-content: center;">
                          <div style="width: 20px; height: 10px; background-color: #984BF4; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #fff; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #EF8F55;"></div>
                        </div>
                      </v-btn>
                      <v-btn @click="setTemplateColors(3)" style="margin-bottom: 8px;">
                        <div style="display: flex; align-items: center; justify-content: center;">
                          <div style="width: 20px; height: 10px; background-color: #2A45A4; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #fff; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #E764B8;"></div>
                        </div>
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'general'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'general'}">
                  <v-list-item-title v-if="currentSection != 'general'">{{$t('general')}}</v-list-item-title>
                  <div v-if="currentSection == 'general'">
                    <v-select
                      v-model="language"
                      :items="languages"
                      item-text="name"
                      item-value="value"
                      :label="$t('chat_ui_language')"
                      outlined
                      dense
                      @change="setLanguage"
                    ></v-select>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <v-checkbox dense
                          v-model="enableStaticStartMessage"
                          hide-details="true"
                          :label="$t('static_start_message')"
                          @change="toggleEnableStaticStartMessage">
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="enableStaticStartMessage">
                      <div class="pl-1">
                        <h5><strong style="margin-right: 10px;">{{$t('text')}}</strong></h5>
                        <v-text-field dense
                          @input="updateStartMessage"
                          v-model="startMessage">
                        </v-text-field>
                      </div>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <v-checkbox dense
                          v-model="enableOnlineMessage"
                          hide-details="true"
                          :label="$t('online_message')"
                          @change="toggleEnableOnlineMessage">
                        </v-checkbox>
                      </v-col>
                      <v-col v-if="enableOnlineMessage" cols="5" class="pl-8">
                        <div class="color-picker-wrapper">
                          <input
                            type="color"
                            v-model="onlineTextColor"
                            @input="updateOnlineTextColor"
                            class="color-picker"/>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <div v-if="enableOnlineMessage" class="pl-1">
                        <h5><strong style="margin-right: 10px;">{{$t('text')}}</strong></h5>
                        <v-text-field dense
                          @input="updateOnlineMessage"
                          v-model="onlineMessage">
                        </v-text-field>
                      </div>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <v-checkbox dense
                          v-model="showDateTime"
                          hide-details="true"
                          :label="$t('datetime')"
                          @change="toggleShowDateTime">
                        </v-checkbox>
                      </v-col>
                      <v-col v-if="showDateTime" cols="5" class="pl-8">
                        <div class="color-picker-wrapper">
                          <input
                            type="color"
                            v-model="datetimeColor"
                            @input="updateDatetimeColor"
                            class="color-picker"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="showDateTime">
                      <v-col cols="6" class="pl-1">
                        <h5><strong style="margin-right: 10px;">{{$t('size')}}:</strong></h5>
                        <v-text-field dense
                          @input="updateMessageDateFontSize"
                          v-model="messageDateFontSize">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-checkbox dense
                      v-model="allowPlaySounds"
                      hide-details="true"
                      :label="$t('play_sounds')">
                    </v-checkbox>
                    <v-select class="pt-2" v-if="allowPlaySounds"
                      v-model="playSoundSrc"
                      :items="messageSounds"
                      item-text="soundName"
                      item-value="soundSrc"
                      :label="$t('select_sound')"
                      outlined
                      dense
                    ></v-select>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <v-checkbox dense
                          v-model="showPoweredBy"
                          hide-details="true"
                          :label="$t('show_powered_by')"
                          @change="toggleShowPoweredBy">
                        </v-checkbox>
                      </v-col>
                      <v-col v-if="showPoweredBy" cols="5" class="pl-8">
                        <input
                          type="color"
                          v-model="poweredByTextColor"
                          @input="updatePoweredByTextColor"
                          class="color-picker"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="showPoweredBy">
                      <v-select class="pt-2"
                        v-model="poweredByImageSrc"
                        :items="poweredBySources"
                        item-text="name"
                        item-value="value"
                        :label="$t('select_source')"
                        outlined
                        dense
                        @change="changePoweredByImageSrc"
                      ></v-select>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <v-checkbox dense
                          v-model="streamingResponse"
                          hide-details="true"
                          :label="$t('streaming_response')"
                          @change="toggleStreamingResponse">
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <v-checkbox dense
                          v-model="showRating"
                          hide-details="true"
                          :label="$t('show_rating')"
                          @change="toggleShowRating">
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'chatcontainer'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'chatcontainer'}">
                  <v-list-item-title v-if="currentSection != 'chatcontainer'">{{$t('chatcontainer')}}</v-list-item-title>
                  <div v-if="currentSection == 'chatcontainer'">
                    <v-row>
                      <v-col>
                        <h5><strong style="margin-right: 10px;">{{$t('height')}}</strong></h5>
                        <v-text-field dense
                          @input="updateChatbotHeight"
                          v-model="chatbotHeight">
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <h5><strong style="margin-right: 10px;">{{$t('width')}}</strong></h5>
                        <v-text-field dense
                          @input="updateChatbotWidth"
                          v-model="chatbotWidth">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <h5><strong style="margin-right: 10px;">{{$t('border_radius')}}</strong></h5>
                        <v-text-field dense
                          @input="updateChatBlockBorderRadius"
                          v-model="chatBlockBorderRadius">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'header'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'header'}">
                  <v-list-item-title v-if="currentSection != 'header'">{{$t('header')}}</v-list-item-title>
                  <div v-if="currentSection == 'header'">
                    <v-row>
                      <v-col cols="6">
                        <h5><strong>{{$t('icon_size')}}</strong></h5>
                        <v-text-field
                          v-model="botImageIconSize"
                          @input="updateBotImageIconSize">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <h5><strong>{{$t('font')}}</strong></h5>
                        <v-select
                          :items="fontTypes"
                          v-model="headerFontType"
                          @change="updateHeaderFontType()"
                          item-text="label"
                          item-value="value"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row cols="6">
                      <v-col>
                        <h5><strong>{{$t('text')}}</strong></h5>
                        <v-text-field
                          v-model="headerText"
                          @input="updateHeaderText">
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <h5><strong>{{$t('size')}}</strong></h5>
                        <v-text-field
                          v-model="headerFontSize"
                          @input="updateHeaderFontSize">
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <div class="pt-3">
                          <input
                            type="color"
                            v-model="headerTextColor"
                            @input="updateHeaderTextColor"
                            class="color-picker"
                            />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <v-radio-group
                          v-model="headerBackgroundColorType"
                          @change="updateHeaderBackgroundColorType"
                        >
                          <v-radio :label="$t('solid_color')" value="solid"></v-radio>
                          <v-radio :label="$t('gradient')" value="gradient"></v-radio>

                        </v-radio-group>
                      </v-col>
                      <v-col>
                        <div v-if="headerBackgroundColorType === 'solid' || headerBackgroundColorType ==='gradient'" class="pt-5">
                            <v-row no-gutters>
                              <v-col>
                                <input
                                  type="color"
                                  v-model="firstHeaderBackgroundColor"
                                  @input="updateHeaderBackgroundColor"
                                  class="color-picker"
                                  />
                              </v-col>
                              <v-col v-if="headerBackgroundColorType === 'gradient'">
                                  <input
                                  type="color"
                                  v-model="secondHeaderBackgroundColor"
                                  @input="updateHeaderBackgroundColor"
                                  class="color-picker"
                                  />
                              </v-col>
                            </v-row>
                          </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'footer'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'footer'}">
                  <v-list-item-title v-if="currentSection != 'footer'">{{$t('footer')}}</v-list-item-title>
                  <div v-if="currentSection == 'footer'">
                    <v-row no-gutters>
                        <v-col>
                          <v-radio-group
                            v-model="footerBackgroundColorType"
                            @change="updateFooterBackgroundColorType"
                          >
                            <v-radio :label="$t('solid_color')" value="solid"></v-radio>
                            <v-radio :label="$t('gradient')" value="gradient"></v-radio>

                          </v-radio-group>
                        </v-col>
                        <v-col>
                          <div v-if="footerBackgroundColorType === 'solid' || footerBackgroundColorType ==='gradient'" class="pt-5">
                              <v-row no-gutters>
                                <v-col>
                                  <div class="color-picker-wrapper">
                                    <input
                                      type="color"
                                      v-model="firstFooterBackgroundColor"
                                      @input="updateFooterBackgroundColor"
                                      class="color-picker"
                                      />
                                  </div>
                                </v-col>
                                <v-col v-if="footerBackgroundColorType === 'gradient'">
                                  <div class="color-picker-wrapper">
                                      <input
                                      type="color"
                                      v-model="secondFooterBackgroundColor"
                                      @input="updateFooterBackgroundColor"
                                      class="color-picker"
                                      />
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                        </v-col>
                    </v-row>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'avatar'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'avatar'}">
                  <v-list-item-title v-if="currentSection != 'avatar'">{{$t('avatar')}}</v-list-item-title>
                  <div v-if="currentSection == 'avatar'">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <v-checkbox dense class="pt-4"
                          v-model="showChatImages"
                          hide-details="true"
                          :label="$t('show_avatars')"
                          @change="toggleShowChatImages">
                        </v-checkbox>
                      </v-col>
                      <v-col cols="6" v-if="showChatImages">
                          <v-file-input hide-input
                            :label="$t('select_image')"
                            prepend-icon="mdi-image"
                            accept="image/*"
                            v-model="imageFile"
                            @change="uploadImage(1)"
                          ></v-file-input>
                        </v-col>
                    </v-row>
                    <div v-if="showChatImages" class="pt-5">
                      <v-row no-gutters>
                        <v-col cols="4">
                          <h5><strong>{{$t('avatar_size')}}</strong></h5>
                          <v-text-field
                            v-model="avatarChatSize"
                            @input="updateAvatarChatSize">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="4">
                        </v-col>
                        <v-col cols="4">
                          <h3 >{{$t('user_avatar')}}</h3>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="pt-3">
                        <v-col cols=6>
                          <div class="color-picker-wrapper">
                            <h5><strong style="margin-right: 10px;">{{$t('av_bg')}}</strong></h5>
                              <input
                              type="color"
                              v-model="userAvatarBackgroundColor"
                              @input="updateUserAvatarBackgroundColor"
                              class="color-picker"
                              />
                          </div>
                        </v-col>
                        <v-col cols=6>
                          <div class="color-picker-wrapper">
                            <h5><strong style="margin-right: 10px;">{{$t('av_bc')}}</strong></h5>
                              <input
                              type="color"
                              v-model="userAvatarBorderColor"
                              @input="updateUserAvatarBorderColor"
                              class="color-picker"
                              />
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no gutters>
                        <v-col cols=6>
                          <div class="color-picker-wrapper">
                            <h5><strong style="margin-right: 10px;">{{$t('av_ic')}}</strong></h5>
                              <input
                              type="color"
                              v-model="userAvatarIconColor"
                              @input="updateUserAvatarIconColor"
                              class="color-picker"
                              />
                          </div>
                        </v-col>
                        <v-col cols=4>
                          <div class="color-picker-wrapper">
                            <h5><strong style="margin-right: 10px;">{{$t('av_is')}}</strong></h5>
                            <v-text-field
                              v-model="userAvatarIconSize"
                              @input="updateUserAvatarIconSize">
                          </v-text-field>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'background'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'background'}">
                  <v-list-item-title v-if="currentSection != 'background'">{{$t('background')}}</v-list-item-title>
                  <div v-if="currentSection == 'background'">
                    <v-radio-group
                      v-model="chatBackgroundType"
                      @change="updateBackgroundType"
                    >
                      <v-radio :label="$t('solid_color')" value="solid"></v-radio>
                      <v-radio :label="$t('gradient')" value="gradient"></v-radio>
                      <v-radio :label="$t('image')" value="image"></v-radio>
                      <div v-if="chatBackgroundType === 'solid' || chatBackgroundType ==='gradient'">
                        <v-row>
                          <v-col cols="2">
                            <input
                              type="color"
                              v-model="firstChatBackgroundColor"
                              @input="updateChatBackgroundColor"
                              class="color-picker"
                              />
                          </v-col>
                          <v-col cols="2" v-if="chatBackgroundType === 'gradient'">
                              <input
                              type="color"
                              v-model="secondChatBackgroundColor"
                              @input="updateChatBackgroundColor"
                              class="color-picker"
                              />
                          </v-col>
                        </v-row>
                      </div>
                      <v-row v-if="chatBackgroundType === 'image'">
                        <v-col cols="3">
                          <v-file-input class="pl-4" hide-input
                            :label="$t('select_image')"
                            prepend-icon="mdi-image"
                            accept="image/*"
                            v-model="imageFile"
                            @change="uploadImage(2)"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="6">
                        <h5><strong style="margin-right: 10px;">{{$t('transparency')}}</strong></h5>
                          <v-text-field
                            v-model="chatBackgroundImageTransparency"
                            @input="updateChatBackgroundImageTransparency">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'speechbubbles'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'speechbubbles'}">
                  <v-list-item-title v-if="currentSection != 'speechbubbles'">{{$t('speech_bubbles')}}</v-list-item-title>
                  <v-col v-if="currentSection == 'speechbubbles'">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-radio-group
                          v-model="speechBubbleBotBackgroundColorType"
                          @change="updateSpeechBubbleBotBackgroundColorType"
                          row
                        >
                          <v-radio :label="$t('solid_color')" value="solid"></v-radio>
                          <v-radio :label="$t('gradient')" value="gradient"></v-radio>

                        </v-radio-group>
                      </v-col>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('bot_bubble')}}</strong></h5>
                          <v-row class="pt-4">
                            <input
                              type="color"
                              v-model="speechBubbleBotBackgroundFirstColor"
                              @input="updateSpeechBubbleBotBackgroundFirstColor"
                              class="color-picker"
                            />
                            <input v-if="speechBubbleBotBackgroundColorType === 'gradient'"
                              type="color"
                              v-model="speechBubbleBotBackgroundSecondColor"
                              @input="updateSpeechBubbleBotBackgroundSecondColor"
                              class="color-picker"
                            />
                          </v-row>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('bot_text')}}</strong></h5>
                          <input
                            type="color"
                            v-model="botFontColor"
                            @input="updateBotFontColor"
                            class="color-picker"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-radio-group
                          v-model="speechBubbleUserBackgroundColorType"
                          @change="updateSpeechBubbleUserBackgroundColorType"
                          row
                        >
                          <v-radio :label="$t('solid_color')" value="solid"></v-radio>
                          <v-radio :label="$t('gradient')" value="gradient"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <strong style="margin-right: 10px;">{{$t('user_bubble')}}</strong>
                          <v-row class="pt-4">
                            <input
                              type="color"
                              v-model="speechBubbleUserBackgroundFirstColor"
                              @input="updateSpeechBubbleUserBackgroundFirstColor"
                              class="color-picker"
                            />
                            <input v-if="speechBubbleUserBackgroundColorType === 'gradient'"
                                type="color"
                                v-model="speechBubbleUserBackgroundSecondColor"
                                @input="updateSpeechBubbleUserBackgroundSecondColor"
                                class="color-picker"
                            />
                          </v-row>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <strong style="margin-right: 10px;">{{$t('user_text')}}</strong>
                          <input
                            type="color"
                            v-model="userFontColor"
                            @input="updateUserFontColor"
                            class="color-picker"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <h5><strong>{{$t('font_size')}}</strong></h5>
                        <v-text-field
                          v-model="speechBubbleFontSize"
                          @input="updateSpeechBubbleFontSize">
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <h5><strong>{{$t('font')}}</strong></h5>
                        <v-select
                          :items="fontTypes"
                          v-model="speechBubbleFontType"
                          @change="updateSpeechbubbleFontType()"
                          item-text="label"
                          item-value="value"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <h5><strong>{{$t('bubble_radius')}}</strong></h5>
                        <v-text-field
                          v-model="speechBubbleBorderRadius"
                          @input="updateSpeechBubbleBorderRadius">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'input'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'input'}">
                  <v-list-item-title v-if="currentSection != 'input'">{{$t('input')}}</v-list-item-title>
                  <v-col v-if="currentSection == 'input'">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <h5><strong>{{$t('width')}}</strong></h5>
                        <v-text-field
                          v-model="userInputWidth"
                          @input="updateUserInputWidth">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <h5><strong>{{$t('height')}}</strong></h5>
                        <v-text-field
                          v-model="userInputHeight"
                          @input="updateUserInputHeight">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <h5><strong>{{$t('size')}}</strong></h5>
                        <v-text-field
                          v-model="userInputFontSize"
                          @input="updateUserInputFontSize">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <h5><strong>{{$t('radius')}}</strong></h5>
                        <v-text-field
                          v-model="userInputBorderRadius"
                          @input="updateUserInputBorderRadius">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('radius')}}</strong></h5>
                            <input
                              type="color"
                              v-model="userInputBackgroundColor"
                              @input="updateUserInputBackgroundColor"
                              class="color-picker"
                            />
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <h5><strong>{{$t('placeholder_text')}}</strong></h5>
                        <v-text-field
                          v-model="userInputPlaceholderText"
                          @input="updateUserInputPlaceholderText">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'miscellaneous'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'miscellaneous'}">
                  <v-list-item-title v-if="currentSection != 'miscellaneous'">{{$t('miscellaneous')}}</v-list-item-title>
                  <v-col v-if="currentSection == 'miscellaneous'">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <h2>{{$t('send_button')}}</h2>
                        <v-radio-group
                          v-model="sendButtonBackgroundColorType"
                          @change="updateSendButtonBackgroundColorType"
                          row
                        >
                          <v-radio :label="$t('solid_color')" value="solid"></v-radio>
                          <v-radio :label="$t('gradient')" value="gradient"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('bg_color')}}</strong></h5>
                          <v-row class="pt-4">
                            <input
                              type="color"
                              v-model="sendButtonBackgroundFirstColor"
                              @input="updateSendButtonBackgroundFirstColor"
                              class="color-picker"
                            />
                            <input v-if="sendButtonBackgroundColorType === 'gradient'"
                              type="color"
                              v-model="sendButtonBackgroundSecondColor"
                              @input="updateSendButtonBackgroundSecondColor"
                              class="color-picker"
                            />
                            <!-- Textarea for -->
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-5">
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <strong style="margin-right: 10px;">{{$t('scrollbar_color')}}</strong>
                          <input
                            type="color"
                            v-model="scrollBarColor"
                            @input="updateScrollBarColor"
                            class="color-picker"
                          />
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <h5><strong>{{$t('scrollbar_width')}}</strong></h5>
                        <v-text-field
                          v-model="scrollBarWidth"
                          @input="updateScrollBarWidth">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <strong style="margin-right: 10px;">{{$t('sendbutton_icon')}}:</strong>
                          <input
                            type="color"
                            v-model="sendButtonIconColor"
                            @input="updateSendButtonIconColor"
                            class="color-picker"
                          />
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <strong style="margin-right: 10px;">{{$t('loading_dots')}}:</strong>
                          <input
                            type="color"
                            v-model="sendLoadingDotsIconColor"
                            @input="updateSendLoadingDotsIconColor"
                            class="color-picker"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-textarea
                          :label="$t('disclaimer_html')"
                          v-model="disclaimerHtml">

                        </v-textarea>
                        
                      </v-col>
                    </v-row>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'chat-button'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'chat-button'}">
                  <v-list-item-title v-if="currentSection != 'chat-button'">{{$t('chat_button')}}</v-list-item-title>
                  <v-col v-if="currentSection == 'chat-button'">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <h5><strong>{{$t('border_width')}}</strong></h5>
                        <v-text-field
                          v-model="buttonBorderWidth"
                          @input="updateButtonBorderWidth">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('border_color')}}</strong></h5>
                          <input
                            type="color"
                            v-model="buttonBorderColor"
                            @input="updateButtonBorderColor"
                            class="color-picker"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <h5><strong>{{$t('button_size')}}</strong></h5>
                        <v-text-field
                          v-model="buttonSize"
                          @input="updateButtonSize">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <h5><strong>{{$t('button_radius')}}</strong></h5>
                        <v-text-field
                          v-model="buttonBorderRadius"
                          @input="updateButtonBorderRadius">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <h5><strong>{{$t('button_shadow')}}</strong></h5>
                        <v-text-field
                          v-model="buttonShadowWidth"
                          @input="updateButtonShadowWidth">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('shadow_color')}}</strong></h5>
                          <input
                            type="color"
                            v-model="buttonShadowColor"
                            @input="updateButtonShadowColor"
                            class="color-picker"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <h5><strong>{{$t('icon_size')}}</strong></h5>
                        <v-text-field
                          v-model="buttonIconSize"
                          @input="updateButtonIconSize">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('icon_color')}}:</strong></h5>
                          <input
                            type="color"
                            v-model="buttonIconColor"
                            @input="updateButtonIconColor"
                            class="color-picker"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('background_color')}}</strong></h5>
                          <v-row class="pt-4">
                            <input
                              type="color"
                              v-model="buttonBackgroundColor"
                              @input="updateChatButtonBackgroundColor"
                              class="color-picker"
                            />
                            <input
                              type="color"
                              v-model="buttonBackgroundColorB"
                              @input="updateChatButtonBackgroundColor"
                              class="color-picker"
                            />
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="currentSection = 'chat-button-bubble'">
                <v-list-item-content :class="{'selected-vlist-item': currentSection === 'chat-button-bubble'}">
                  <v-list-item-title v-if="currentSection != 'chat-button-bubble'">{{$t('chat_button_bubble')}}</v-list-item-title>
                   <v-col v-if="currentSection == 'chat-button-bubble'">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-checkbox dense
                          v-model="showChatbuttonBubble"
                          hide-details="true"
                          :label="$t('chat_button_bubble')"
                          @change="toggleShowChatbuttonBubble">
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="showChatbuttonBubble">
                      <v-col cols="4">
                        <h5><strong style="margin-right: 10px;">{{$t('width')}}</strong></h5>
                        <v-text-field dense
                          @input="updateChatbuttonBubbleWidth()"
                          v-model="chatbuttonBubbleWidth">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <h5><strong style="margin-right: 10px;">{{$t('right_pos')}}</strong></h5>
                        <v-text-field dense
                          @input="updateChatbuttonBubbleRightPosition()"
                          v-model="chatbuttonBubbleRightPosition">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <h5><strong style="margin-right: 10px;">{{$t('top_pos')}}</strong></h5>
                        <v-text-field dense
                          @input="updateChatbuttonBubbleTopPosition()"
                          v-model="chatbuttonBubbleTopPosition">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="showChatbuttonBubble">
                      <v-col>
                        <v-radio-group
                          v-model="chatButtonBubbleBackgroundColorType"
                          @change="updateChatButtonBubbleBackgroundColorType"
                        >
                          <v-radio :label="$t('solid_color')" value="solid"></v-radio>
                          <v-radio :label="$t('gradient')" value="gradient"></v-radio>

                        </v-radio-group>
                      </v-col>
                      <v-col>
                        <div v-if="chatButtonBubbleBackgroundColorType === 'solid' || chatButtonBubbleBackgroundColorType ==='gradient'" class="pt-5">
                            <v-row no-gutters>
                              <v-col>
                                <input
                                  type="color"
                                  v-model="firstChatbuttonBubbleBackgroundColor"
                                  @input="updateChatbuttonBubbleBackgroundColor"
                                  class="color-picker"
                                  />
                              </v-col>
                              <v-col v-if="chatButtonBubbleBackgroundColorType === 'gradient'">
                                  <input
                                  type="color"
                                  v-model="secondChatbuttonBubbleBackgroundColor"
                                  @input="updateChatbuttonBubbleBackgroundColor"
                                  class="color-picker"
                                  />
                              </v-col>
                            </v-row>
                          </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="showChatbuttonBubble">
                      <v-col>
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('text_color')}}</strong></h5>
                          <input
                            type="color"
                            v-model="chatbuttonBubbleTextColor"
                            @input="updateChatbuttonBubbleTextColor"
                            class="color-picker"/>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="color-picker-wrapper">
                          <h5><strong>{{$t('close_btn_color')}}</strong></h5>
                          <input
                            type="color"
                            v-model="chatbuttonBubbleCloseBtnColor"
                            @input="updateChatbuttonBubbleCloseBtnColor"
                            class="color-picker"/>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="pt-3" no-gutters v-if="showChatbuttonBubble">
                      <v-col>
                        <h5><strong>{{$t('text')}}</strong></h5>
                        <v-textarea v-model="chatbuttonBubbleText"
                          @input="updateChatbuttonBubbleText">
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="showChatbuttonBubble">
                      <v-col cols="12">
                        <v-select
                          v-model="selectedBubbleEffect"
                          :items="bubbleEffects"
                          item-text="name"
                          item-value="value"
                          :label="$t('bubble_effects')"
                          outlined
                          dense
                          @change="setBubbleEffect"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="showChatbuttonBubble">
                      <v-col cols="4">
                        <h5><strong style="margin-right: 10px;">{{$t('delay')}}</strong></h5>
                        <v-text-field dense
                          @input="updateChatbuttonBubbleDelay()"
                          v-model="chatbuttonBubbleDelay">
                        </v-text-field>
                      </v-col>
                    </v-row>
                   </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            </v-navigation-drawer>
            <v-row class="pt-5" justify="center">
              <v-btn @click="saveDesign">{{$t('save')}}</v-btn>
            </v-row>
          </div>
        </v-col>
        <!-- PREVIEW -->
        <v-col cols="8" style="position: relative;">
          <v-card style="box-shadow:none; overflow:hidden;">
            <ChatPreview :bubble-effects="bubbleEffects" ref="chatPreview" />
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  import ChatPreview from '../wizard/designer/ChatPreview.vue';
    import { EventBus } from '../../eventBus.js';
    import axios from 'axios';
    export default {
        name: 'ChatDesignTab',
        components: {
          ChatPreview
        },
        data() {
          return {
            initialChatbotSettings: null,
            initialChatBotButtonSettings: null,
            topic: '',
            language: '',
            headerBackgroundColorType: '',
            firstHeaderBackgroundColor: '',
            secondHeaderBackgroundColor: '',
            footerBackgroundColorType: '',
            firstFooterBackgroundColor: '',
            secondFooterBackgroundColor: '',
            footerBackgroundColor: '',
            botImageIconSize: '',
            avatarChatSize: '',
            userAvatarBackgroundColor: '',
            userAvatarBorderColor: '',
            userAvatarIconColor: '',
            userAvatarIconSize: '',
            speechBubbleBotBackgroundColorType: '',
            speechBubbleBotBackgroundFirstColor: '',
            speechBubbleBotBackgroundSecondColor: '',
            botFontColor: '',
            speechBubbleUserBackgroundColorType: '',
            speechBubbleUserBackgroundFirstColor: '',
            speechBubbleUserBackgroundSecondColor: '',
            sendButtonBackgroundColorType: '',
            sendButtonBackgroundFirstColor: '',
            sendButtonBackgroundSecondColor: '',
            scrollBarColor: '',
            scrollBarWidth: '',
            sendButtonIconColor: '',
            sendLoadingDotsIconColor: '',
            buttonBorderWidth: '',
            userFontColor: '',
            speechBubbleFontSize: '',
            firstChatBackgroundColor: '',
            secondChatBackgroundColor: '',
            speechBubbleBorderRadius: '',
            userInputWidth: '',
            userInputHeight: '',
            userInputFontSize: '',
            userInputBorderRadius: '',
            userInputBackgroundColor: '',
            userInputPlaceholderText: '',
            sendButtonColor: '',
            miscColor: '',
            sliderWidth: 350,  // Default width, adjust as needed
            sliderHeight: 510, // Default height, adjust as needed
            configurationServer: this.$config.configurationServer,
            imageFile: null,
            chatBackgroundType: '',
            chatBackgroundImageTransparency: '',
            headerText: '',
            headerFontSize: '',
            headerTextColor: '',

            disclaimerHtml: '',

            buttonBorderColor: '',
            buttonSize: '',
            buttonBorderRadius: '',
            buttonShadowWidth: '',
            buttonShadowColor: '',
            buttonIconSize: '',
            buttonIconColor: '',
            buttonBackgroundColor: '',
            buttonBackgroundColorB: '',

            chatbuttonBubbleWidth:'',
            chatbuttonBubbleRightPosition: '',
            chatbuttonBubbleTopPosition: '',
            chatButtonBubbleBackgroundColorType: '',
            firstChatbuttonBubbleBackgroundColor:'',
            secondChatbuttonBubbleBackgroundColor: '',
            chatbuttonBubbleCloseBtnColor: '',
            chatbuttonBubbleTextColor: '',
            chatbuttonBubbleText: '',
            showChatbuttonBubble: false,
            chatbuttonBubbleDelay: 0,

            drawer: null,
            mini: false,
            currentSection: '',
            render_preview: false,
            selectedChatButtonColorIndex: -1,
            selectedChatButtonColor: '',
            enableOnlineMessage: true,
            enableStaticStartMessage: false,
            startMessage: '',
            showDateTime: true,
            showChatImages: true,
            onlineMessage: '',
            onlineTextColor: '#fff',
            datetimeColor: '#hhhhhh',
            messageDateFontSize: 8,
            chatbotHeight: 400,
            chatbotWidth: 350,
            chatBlockBorderRadius: 15,
            allowPlaySounds: false,
            playSoundSrc: '',
            showPoweredBy: true,
            streamingResponse: false,
            showRating: false,
            poweredByTextColor: '#fff',
            poweredByImageSrc: '',
            fontTypes: [{value: 'Times New Roman',label: 'Times New Roman'},{value: 'Georgia',label: 'Georgia'},{value: 'Times',label: 'Times'},{value: 'serif',label: 'Serif'},{value: 'Arial',label: 'Arial'},{value: 'Helvetica',label: 'Helvetica'},{value: 'Verdana',label: 'Verdana'},{value: 'sans-serif',label: 'Sans serif'},{value: 'Courier New',label: 'Courier New'},{value: 'Courier',label: 'Courier'},{value: 'monospace',label: 'Monospace'},{value: 'Comic Sans MS',label: 'Comic Sans MS'},{value: 'cursive',label: 'Cursive'},{value: 'Impact',label: 'Impact'},{value: 'fantasy',label: 'Fantasy'}],
            chatButtonColorOptions: [
                { colorA: '#D755556', colorB: '#8A48E4' },
                { colorA: '#6064E6', colorB: '#E764B8' },
                { colorA: '#2A45A4', colorB: '#BA6AD2' },
                { colorA: '#8A4AE8', colorB: '#70B1ED' },
                { colorA: '#5ACED9', colorB: '#2947A2' },
                { colorA: '#8A4AE9', colorB: '#01B667' },
                { colorA: '#2CB7D3', colorB: '#E6DD68' },
                { colorA: '#EDD45C', colorB: '#EFDC60' },
                { colorA: '#FADC5B', colorB: '#FC9750' },
                { colorA: '#FC6CBF', colorB: '#FBDA60' },
                { colorA: '#984BF4', colorB: '#EF8F55' }
            ],
            languages: [
              {name: 'English', value: 'en'},
              {name: 'Nederlands', value: 'nl'},
            ],
            messageSounds: [
              {soundName: 'First', soundSrc: '/sounds/message_sound_first.wav'},
              {soundName: 'Second', soundSrc: '/sounds/message_sound_second.wav'},
              {soundName: 'Third', soundSrc: '/sounds/message_sound_third.wav'}
            ],
            poweredBySources: [
              {name: 'HOSTHUB_WHITE', value: 'https://hosthubstoragetest.blob.core.windows.net/hosthub-blob/public/images/logo_white.png'},
              {name: 'HOSTHUB_COLOR', value: 'https://hosthubstoragetest.blob.core.windows.net/hosthub-blob/public/images/logo_color.png'}
            ],
            selectedBubbleEffect: null,
            bubbleEffects: [
              { name: 'None', value: 'none' },
              { name: 'Fade In', value: 'fadeInEffect' },
              { name: 'Bounce', value: 'bounceEffect' },
              { name: 'Pulse', value: 'pulseEffect' },
              { name: 'Slide In', value: 'slideInEffect' },
              { name: 'Float', value: 'floatEffect' },
              { name: 'Typing', value: 'typingEffect' },
              { name: 'Shake', value: 'shakeEffect' },
              { name: 'Pop In', value: 'popInEffect' },
              { name: 'Heartbeat', value: 'heartbeatEffect' },
              { name: 'Swing', value: 'swingEffect' },
              { name: 'Spotlight', value: 'spotlightEffect' },
              { name: 'Flip In', value: 'flipInEffect' }
            ]
          };
        },
        computed: {
          configuration: {
            get() {
              return this.$store.getters.selectedConfiguration;
            }
          },
        },
        async mounted() {
          this.initialize();
        },
        created() {
          EventBus.$on('set-configuration', () => {
            this.initialize();
          })
        },
        methods: {
          async initialize() {
            let chatbotSettings = this.$refs.chatPreview.chatbotSettings;
            chatbotSettings.headerText = this.$t('header_text');
            let chatbotButtonSettings = this.$refs.chatPreview.chatbotButtonSettings;
            try {
              // Attempt to fetch chatbot settings
              let existingChatbotSettings = await this.getChatbotSettings();
              this.$refs.chatPreview.chatbotSettings.headerText = this.configuration.companyName + ' chat';
              // If no settings are retrieved, use the ones from $refs
              if (existingChatbotSettings) {
                chatbotSettings = existingChatbotSettings;
                this.$refs.chatPreview.chatbotSettings.enableAIStartMessage = chatbotSettings.enableAIStartMessage;
                this.$refs.chatPreview.chatbotSettings.startMessage = chatbotSettings.startMessage;

                this.$refs.chatPreview.chatbotSettings.headerText = chatbotSettings.headerText;

                chatbotButtonSettings = existingChatbotSettings.button_settings;
              }
              else {
                this.$refs.chatPreview.chatbotSettings.headerText = this.configuration.companyName;
                this.setTemplateColors(1);
              }
            } catch (error) {
              console.error('Error fetching chatbot settings:', error);
            }

            this.headerFontSize = chatbotSettings.headerFontSize;
            this.$refs.chatPreview.chatbotSettings.headerFontSize = this.headerFontSize;

            this.headerTextColor = chatbotSettings.headerTextColor;
            this.$refs.chatPreview.chatbotSettings.headerTextColor = this.headerTextColor;

            this.language = chatbotSettings.language;
            this.$refs.chatPreview.chatbotSettings.language = this.language;

            this.headerBackgroundColorType = chatbotSettings.headerBackgroundColorType;

            this.firstHeaderBackgroundColor = chatbotSettings.firstHeaderBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = this.firstHeaderBackgroundColor;

            this.secondHeaderBackgroundColor = chatbotSettings.secondHeaderBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = this.secondHeaderBackgroundColor;

            this.footerBackgroundColorType = chatbotSettings.footerBackgroundColorType;

            this.firstFooterBackgroundColor = chatbotSettings.firstFooterBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = this.firstFooterBackgroundColor;

            this.secondFooterBackgroundColor = chatbotSettings.secondFooterBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = this.secondFooterBackgroundColor;

            this.avatarChatSize = chatbotSettings.avatarChatSize;
            this.$refs.chatPreview.chatbotSettings.avatarChatSize = this.avatarChatSize;

            this.userAvatarBackgroundColor = chatbotSettings.userAvatarBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.userAvatarBackgroundColor = this.userAvatarBackgroundColor;

            this.userAvatarBorderColor = chatbotSettings.userAvatarBorderColor;
            this.$refs.chatPreview.chatbotSettings.userAvatarBorderColor = this.userAvatarBorderColor;

            this.userAvatarIconColor = chatbotSettings.userAvatarIconColor;
            this.$refs.chatPreview.chatbotSettings.userAvatarIconColor = this.userAvatarIconColor;

            this.userAvatarIconSize = chatbotSettings.userAvatarIconSize;
            this.$refs.chatPreview.chatbotSettings.userAvatarIconSize = this.userAvatarIconSize;

            this.botImageIconSize = chatbotSettings.botImageIconSize;
            this.$refs.chatPreview.chatbotSettings.botImageIconSize = this.botImageIconSize;

            if (chatbotSettings.headerBackgroundColorType === 'gradient') {
              this.$refs.chatPreview.chatbotSettings.headerBackgroundColorType = 'gradient';
              this.$refs.chatPreview.chatbotSettings.headerGradientAngle = chatbotSettings.headerGradientAngle;
            }

            this.footerBackgroundColor = chatbotSettings.firstFooterBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = this.footerBackgroundColor;

            if (chatbotSettings.footerBackgroundColorType === 'gradient') {
              this.$refs.chatPreview.chatbotSettings.footerBackgroundColorType = 'gradient';
              this.$refs.chatPreview.chatbotSettings.footerGradientAngle = chatbotSettings.footerGradientAngle;
              this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = chatbotSettings.secondFooterBackgroundColor;
            }

            this.speechBubbleBotBackgroundColorType = chatbotSettings.speechBubbleBotBackgroundColorType;

            this.speechBubbleBotBackgroundFirstColor = chatbotSettings.speechBubbleBotBackgroundFirstColor;
            this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = this.speechBubbleBotBackgroundFirstColor;

            this.speechBubbleBotBackgroundSecondColor = chatbotSettings.speechBubbleBotBackgroundSecondColor;
            this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundSecondColor = chatbotSettings.speechBubbleBotBackgroundSecondColor;

            this.botFontColor = chatbotSettings.botFontColor;
            this.$refs.chatPreview.chatbotSettings.botFontColor = this.botFontColor;

            this.userFontColor = chatbotSettings.userFontColor;
            this.$refs.chatPreview.chatbotSettings.userFontColor = this.userFontColor;

            this.speechBubbleUserBackgroundColorType = chatbotSettings.speechBubbleUserBackgroundColorType;

            this.speechBubbleUserBackgroundFirstColor = chatbotSettings.speechBubbleUserBackgroundFirstColor;
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = this.speechBubbleUserBackgroundFirstColor;

            this.sendButtonBackgroundColorType = chatbotSettings.sendButtonBackgroundColorType;
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundColorType = this.sendButtonBackgroundColorType;

            this.sendButtonBackgroundFirstColor = chatbotSettings.sendButtonBackgroundFirstColor;
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = this.sendButtonBackgroundFirstColor;

            this.sendButtonBackgroundSecondColor = chatbotSettings.sendButtonBackgroundSecondColor;
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = this.sendButtonBackgroundSecondColor;

            this.scrollBarColor = chatbotSettings.scrollBarColor;
            this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.scrollBarColor;

            this.scrollBarWidth = chatbotSettings.scrollBarWidth;
            this.$refs.chatPreview.chatbotSettings.scrollBarWidth = this.scrollBarWidth;

            this.sendButtonIconColor = chatbotSettings.sendButtonIconColor;
            this.$refs.chatPreview.chatbotSettings.sendButtonIconColor = this.sendButtonIconColor;

            this.sendLoadingDotsIconColor = chatbotSettings.sendLoadingDotsIconColor;
            this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.sendLoadingDotsIconColor;

            this.speechBubbleUserBackgroundSecondColor = chatbotSettings.speechBubbleUserBackgroundSecondColor;
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = this.speechBubbleUserBackgroundSecondColor;

            this.speechBubbleFontSize = chatbotSettings.speechBubbleFontSize;
            this.$refs.chatPreview.chatbotSettings.speechBubbleFontSize = this.speechBubbleFontSize;

            this.speechBubbleBorderRadius = chatbotSettings.speechBubbleBorderRadius;
            this.$refs.chatPreview.chatbotSettings.speechBubbleBorderRadius = this.speechBubbleBorderRadius;

            this.userInputWidth = chatbotSettings.userInputWidth;
            this.$refs.chatPreview.chatbotSettings.userInputWidth = this.userInputWidth;

            this.userInputHeight = chatbotSettings.userInputHeight;
            this.$refs.chatPreview.chatbotSettings.userInputHeight = this.userInputHeight;

            this.userInputFontSize = chatbotSettings.userInputFontSize;
            this.$refs.chatPreview.chatbotSettings.userInputFontSize = this.userInputFontSize;

            this.userInputBorderRadius = chatbotSettings.userInputBorderRadius;
            this.$refs.chatPreview.chatbotSettings.userInputBorderRadius = this.userInputBorderRadius;

            this.userInputBackgroundColor = chatbotSettings.userInputBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.userInputBackgroundColor = this.userInputBackgroundColor;

            this.userInputPlaceholderText = chatbotSettings.userInputPlaceholderText;
            this.$refs.chatPreview.chatbotSettings.userInputPlaceholderText = this.userInputPlaceholderText;

            this.chatBackgroundType = chatbotSettings.chatBackgroundType;
            this.$refs.chatPreview.chatbotSettings.chatBackgroundType = this.chatBackgroundType;

            this.chatBackgroundImageTransparency = chatbotSettings.chatBackgroundImageTransparency;
            this.$refs.chatPreview.chatbotSettings.chatBackgroundImageTransparency = this.chatBackgroundImageTransparency;

            this.firstChatBackgroundColor = chatbotSettings.firstChatBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = this.firstChatBackgroundColor;

            this.secondChatBackgroundColor = chatbotSettings.secondChatBackgroundcolor
            this.$refs.chatPreview.chatbotSettings.secondChatBackgroundcolor = this.secondChatBackgroundColor;

            this.headerText = chatbotSettings.headerText;
            this.$refs.chatPreview.chatbotSettings.headerText = this.headerText;

            this.$refs.chatPreview.chatbotSettings.botImageSrc = chatbotSettings.botImageSrc;
            this.$refs.chatPreview.chatbotSettings.chatBackgroundImageSrc = chatbotSettings.chatBackgroundImageSrc;

            this.enableOnlineMessage = chatbotSettings.enableOnlineMessage;
            this.$refs.chatPreview.chatbotSettings.enableOnlineMessage = this.enableOnlineMessage;

            this.enableStaticStartMessage = chatbotSettings.enableStaticStartMessage;
            this.$refs.chatPreview.chatbotSettings.enableStaticStartMessage = this.enableStaticStartMessage;

            this.startMessage = chatbotSettings.startMessage;
            this.$refs.chatPreview.chatbotSettings.startMessage = chatbotSettings.startMessage;

            this.showDateTime = chatbotSettings.showDateTime;
            this.$refs.chatPreview.chatbotSettings.showDateTime = this.showDateTime;

            this.showChatImages = chatbotSettings.showChatImages;
            this.$refs.chatPreview.chatbotSettings.showChatImages = this.showChatImages;

            this.onlineMessage = chatbotSettings.onlineMessage;
            this.$refs.chatPreview.chatbotSettings.onlineMessage = this.onlineMessage;

            this.onlineTextColor = chatbotSettings.onlineTextColor;
            this.$refs.chatPreview.chatbotSettings.onlineTextColor = this.onlineTextColor;

            this.datetimeColor = chatbotSettings.datetimeColor;
            this.$refs.chatPreview.chatbotSettings.datetimeColor = this.datetimeColor;

            this.messageDateFontSize = chatbotSettings.messageDateFontSize;
            this.$refs.chatPreview.chatbotSettings.messageDateFontSize = this.messageDateFontSize;

            this.chatbotHeight = chatbotSettings.chatbotHeight;
            this.$refs.chatPreview.chatbotSettings.chatbotHeight = this.chatbotHeight;

            this.chatbotWidth = chatbotSettings.chatbotWidth;
            this.$refs.chatPreview.chatbotSettings.chatbotWidth = this.chatbotWidth;

            this.chatBlockBorderRadius = chatbotSettings.chatBlockBorderRadius;
            this.$refs.chatPreview.chatbotSettings.chatBlockBorderRadius = this.chatBlockBorderRadius;

            this.allowPlaySounds = chatbotSettings.allowPlaySounds;
            this.playSoundSrc = chatbotSettings.playSoundSrc;

            this.showPoweredBy = chatbotSettings.showPoweredBy;
            this.$refs.chatPreview.chatbotSettings.showPoweredBy = this.showPoweredBy;

            this.streamingResponse = chatbotSettings.streamingResponse;
            this.$refs.chatPreview.chatbotSettings.streamingResponse = this.streamingResponse;

            this.showRating = chatbotSettings.showRating;
            this.$refs.chatPreview.chatbotSettings.showRating = this.showRating;

            this.poweredByTextColor = chatbotSettings.poweredByTextColor;
            this.$refs.chatPreview.chatbotSettings.poweredByTextColor = this.poweredByTextColor;

            this.poweredByImageSrc = chatbotSettings.poweredByImageSrc;
            this.$refs.chatPreview.chatbotSettings.poweredByImageSrc = this.poweredByImageSrc;

            this.speechBubbleFontType = chatbotSettings.speechBubbleFontType;
            this.headerFontType = chatbotSettings.headerFontType;

            this.disclaimerHtml = chatbotSettings.disclaimerHtml;

            // chatbot button
            this.buttonBackgroundColor = chatbotButtonSettings.buttonBackgroundColor;
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = this.buttonBackgroundColor;

            this.buttonBackgroundColorB = chatbotButtonSettings.buttonBackgroundColorB;
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = this.buttonBackgroundColorB;

            this.buttonBorderWidth = chatbotButtonSettings.borderWidth;
            this.$refs.chatPreview.chatbotButtonSettings.borderWidth = this.buttonBorderWidth;

            this.buttonBorderColor = chatbotButtonSettings.borderColor;
            this.$refs.chatPreview.chatbotButtonSettings.borderColor = this.buttonBorderColor;

            this.buttonSize = chatbotButtonSettings.buttonHeight;
            this.$refs.chatPreview.chatbotButtonSettings.buttonHeight = this.buttonSize;
            this.$refs.chatPreview.chatbotButtonSettings.buttonWidth = this.buttonSize;

            this.buttonBorderRadius = chatbotButtonSettings.buttonBorderRadius;
            this.$refs.chatPreview.chatbotButtonSettings.buttonBorderRadius = this.buttonBorderRadius;

            this.buttonShadowWidth = chatbotButtonSettings.shadowWidth;
            this.$refs.chatPreview.chatbotButtonSettings.shadowWidth = this.buttonShadowWidth;

            this.buttonShadowColor = chatbotButtonSettings.shadowColor;
            this.$refs.chatPreview.chatbotButtonSettings.shadowColor = this.buttonShadowColor;

            this.buttonIconSize = chatbotButtonSettings.iconSize;
            this.$refs.chatPreview.chatbotButtonSettings.iconSize = this.buttonIconSize;

            this.buttonIconColor = chatbotButtonSettings.iconColor;
            this.$refs.chatPreview.chatbotButtonSettings.iconColor = this.buttonIconColor;

            this.shadowColor = chatbotButtonSettings.shadowColor;
            this.$refs.chatPreview.chatbotButtonSettings.shadowColor = this.shadowColor;

            this.buttonIconColor = chatbotButtonSettings.iconColor;
            this.$refs.chatPreview.chatbotButtonSettings.iconColor = this.buttonIconColor;            

            // button bubble
            this.showChatbuttonBubble = chatbotButtonSettings.showChatbuttonBubble;
            this.$refs.chatPreview.chatbotButtonSettings.showChatbuttonBubble = this.showChatbuttonBubble;

            this.chatbuttonBubbleDelay = chatbotButtonSettings.chatbuttonBubbleDelay / 1000;
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleDelay = this.chatbuttonBubbleDelay * 1000;

            this.chatbuttonBubbleWidth = chatbotButtonSettings.chatbuttonBubbleWidth;
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleWidth = this.chatbuttonBubbleWidth;

            this.chatbuttonBubbleRightPosition = chatbotButtonSettings.chatbuttonBubbleRightPosition;
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleRightPosition = this.chatbuttonBubbleRightPosition;

            this.chatbuttonBubbleTopPosition = chatbotButtonSettings.chatbuttonBubbleTopPosition;
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleTopPosition = this.chatbuttonBubbleTopPosition;

            this.chatButtonBubbleBackgroundColorType = chatbotButtonSettings.chatButtonBubbleBackgroundColorType;

            if (chatbotButtonSettings.chatButtonBubbleBackgroundColorType === 'gradient') {
              this.$refs.chatPreview.chatbotButtonSettings.chatButtonBubbleBackgroundColorType = 'gradient';
            }

            this.firstChatbuttonBubbleBackgroundColor = chatbotButtonSettings.firstChatbuttonBubbleBackgroundColor;
            this.$refs.chatPreview.chatbotButtonSettings.firstChatbuttonBubbleBackgroundColor = this.firstChatbuttonBubbleBackgroundColor;

            this.secondChatbuttonBubbleBackgroundColor = chatbotButtonSettings.secondChatbuttonBubbleBackgroundColor;
            this.$refs.chatPreview.chatbotButtonSettings.secondChatbuttonBubbleBackgroundColor = this.secondChatbuttonBubbleBackgroundColor;

            this.chatbuttonBubbleCloseBtnColor = chatbotButtonSettings.chatbuttonBubbleCloseBtnColor;
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleCloseBtnColor = this.chatbuttonBubbleCloseBtnColor;

            this.chatbuttonBubbleTextColor = chatbotButtonSettings.chatbuttonBubbleTextColor;
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleTextColor = this.chatbuttonBubbleTextColor;

            this.chatbuttonBubbleText = chatbotButtonSettings.chatbuttonBubbleText;
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleText = this.chatbuttonBubbleText;

            this.selectedBubbleEffect = chatbotButtonSettings.selectedBubbleEffect;
            this.$refs.chatPreview.chatbotButtonSettings.selectedBubbleEffect = this.selectedBubbleEffect;

          },
          setTemplateColors(templateChoice) {
            const themeAColorA = '#6d00e1';
            const themeAColorB = '#00b5fd';
            const themeBColorA = '#984BF4';
            const themeBColorB = '#EF8F55';
            const themeCColorA = '#2A45A4';
            const themeCColorB = '#E764B8';

            switch (templateChoice) {
                case 1:
                  this.$refs.chatPreview.chatbotSettings.headerBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.headerGradientAngle = 145;
                  this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = themeAColorA;
                  this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = themeAColorB;

                  this.$refs.chatPreview.chatbotSettings.footerBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.footerGradientAngle = 145;
                  this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = themeAColorA;
                  this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = themeAColorB;

                  this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = '#EFF2F8';

                  this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = themeAColorA;
                  this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = themeAColorB;

                  this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = '#FFFFFF';
                  this.$refs.chatPreview.chatbotSettings.secondChatBackgroundColor = '#FFFFFF';

                  this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = themeAColorA;
                  this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = themeAColorB;

                  this.$refs.chatPreview.chatbotSettings.botImageSrc = 'https://hosthubconfigurationtest.azurewebsites.net//chatbot/settings/image/download?configuration_id=3263368c-8eaa-4288-abf5-fd329b2fd318&filename=b2663643-c3e8-4054-aa1b-d69628757008'

                  this.$refs.chatPreview.chatbotSettings.userAvatarBackgroundColor = 'themeAColorB';

                  this.miscColor = themeAColorB;
                  this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.miscColor;
                  this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.miscColor;

                  this.$refs.chatPreview.chatbotButtonSettings.borderColor = themeAColorA;
                  this.$refs.chatPreview.chatbotButtonSettings.shadowColor = themeAColorA;
                  this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = themeAColorA; //keep A so original color will work for non transient buttons
                  this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = themeAColorB;

                  break;
                  case 2:
                  this.$refs.chatPreview.chatbotSettings.headerBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.headerGradientAngle = 145;
                  this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = themeBColorA;
                  this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = themeBColorB;

                  this.$refs.chatPreview.chatbotSettings.footerBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.footerGradientAngle = 145;
                  this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = themeBColorA;
                  this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = themeBColorB;

                  this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = '#EFF2F8';

                  this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = themeBColorA;
                  this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = themeBColorB;

                  this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = '#FFFFFF';
                  this.$refs.chatPreview.chatbotSettings.secondChatBackgroundColor = '#FFFFFF';

                  this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = themeBColorA;
                  this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = themeBColorB;

                  this.$refs.chatPreview.chatbotSettings.botImageSrc = 'https://hosthubconfigurationtest.azurewebsites.net//chatbot/settings/image/download?configuration_id=3263368c-8eaa-4288-abf5-fd329b2fd318&filename=b2663643-c3e8-4054-aa1b-d69628757008'

                  this.$refs.chatPreview.chatbotSettings.userAvatarBackgroundColor = themeBColorB;

                  this.miscColor = themeBColorB;
                  this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.miscColor;
                  this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.miscColor;

                  this.$refs.chatPreview.chatbotButtonSettings.borderColor = themeBColorA;
                  this.$refs.chatPreview.chatbotButtonSettings.shadowColor = themeBColorA;
                  this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = themeBColorA; //keep A so original color will work for non transient buttons
                  this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = themeBColorB;
                  break;
                case 3:
                  this.$refs.chatPreview.chatbotSettings.headerBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.headerGradientAngle = 145;
                  this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = themeCColorA;
                  this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = themeCColorB;

                  this.$refs.chatPreview.chatbotSettings.footerBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.footerGradientAngle = 145;
                  this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = themeCColorA;
                  this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = themeCColorB;

                  this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = '#EFF2F8';

                  this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = themeCColorA;
                  this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = themeCColorB;

                  this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = '#FFFFFF';
                  this.$refs.chatPreview.chatbotSettings.secondChatBackgroundColor = '#FFFFFF';

                  this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundColorType = 'gradient';
                  this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = themeCColorA;
                  this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = themeCColorB;

                  this.$refs.chatPreview.chatbotSettings.botImageSrc = 'https://hosthubconfigurationtest.azurewebsites.net//chatbot/settings/image/download?configuration_id=3263368c-8eaa-4288-abf5-fd329b2fd318&filename=b2663643-c3e8-4054-aa1b-d69628757008'

                  this.$refs.chatPreview.chatbotSettings.userAvatarBackgroundColor = themeCColorB;

                  this.miscColor = themeCColorB;
                  this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.miscColor;
                  this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.miscColor;

                  this.$refs.chatPreview.chatbotButtonSettings.borderColor = themeCColorA;
                  this.$refs.chatPreview.chatbotButtonSettings.shadowColor = themeCColorA;
                  this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = themeCColorA; //keep A so original color will work for non transient buttons
                  this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = themeCColorB;
                  break;
                }
          },
          getChatbotSettings() {
            const token = localStorage.getItem('userToken');
            const configurationId = this.configuration._id;

            return axios.get(`${this.configurationServer}/chatbot/settings/byConfigurationId`, {
              headers: { 'Authorization': `Bearer ${token}` },
              params: { configuration_id: configurationId }
            })
            .then(response => {
              return response.data;
            })
            .catch(error => {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: error});
            });
          },
          setLanguage() {
            this.$refs.chatPreview.chatbotSettings.language = this.language;
          },
          updateBotImageIconSize() {
            this.$refs.chatPreview.chatbotSettings.botImageIconSize = this.botImageIconSize;
          },
          updateFooterColor() {
            this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = this.footerBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = this.footerBackgroundColor;
          },
          updateAvatarChatSize() {
            this.$refs.chatPreview.chatbotSettings.avatarChatSize = this.avatarChatSize;
          },
          updateUserAvatarBackgroundColor() {
            this.$refs.chatPreview.chatbotSettings.userAvatarBackgroundColor = this.userAvatarBackgroundColor;
          },
          updateUserAvatarBorderColor() {
            this.$refs.chatPreview.chatbotSettings.userAvatarBorderColor = this.userAvatarBorderColor;
          },
          updateUserAvatarIconColor() {
            this.$refs.chatPreview.chatbotSettings.userAvatarIconColor = this.userAvatarIconColor;
          },
          updateUserAvatarIconSize() {
            this.$refs.chatPreview.chatbotSettings.userAvatarIconSize = this.userAvatarIconSize;
          },
          updateChatBackgroundColor() {
            this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = this.firstChatBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.secondChatBackgroundColor = this.secondChatBackgroundColor;
          },
          updateSpeechBubbleBotBackgroundFirstColor() {
            this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = this.speechBubbleBotBackgroundFirstColor;
          },
          updateSpeechBubbleBotBackgroundSecondColor() {
            this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundSecondColor = this.speechBubbleBotBackgroundSecondColor;
          },
          updateBotFontColor() {
            this.$refs.chatPreview.chatbotSettings.botFontColor = this.botFontColor;
          },
          updateUserFontColor() {
            this.$refs.chatPreview.chatbotSettings.userFontColor = this.userFontColor;
          },
          updateSpeechBubbleUserBackgroundFirstColor() {
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = this.speechBubbleUserBackgroundFirstColor;
          },
          updateSpeechBubbleUserBackgroundSecondColor() {
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = this.speechBubbleUserBackgroundSecondColor;
          },
          updateSpeechBubbleFontSize() {
            this.$refs.chatPreview.chatbotSettings.speechBubbleFontSize = this.speechBubbleFontSize;
          },
          updateSpeechBubbleBorderRadius() {
            this.$refs.chatPreview.chatbotSettings.speechBubbleBorderRadius = this.speechBubbleBorderRadius;
          },
          updateUserInputWidth() {
            this.$refs.chatPreview.chatbotSettings.userInputWidth = this.userInputWidth;
          },
          updateUserInputHeight() {
            this.$refs.chatPreview.chatbotSettings.userInputHeight = this.userInputHeight;
          },
          updateUserInputFontSize() {
            this.$refs.chatPreview.chatbotSettings.userInputFontSize = this.userInputFontSize;
          },
          updateUserInputBorderRadius() {
            this.$refs.chatPreview.chatbotSettings.userInputBorderRadius = this.userInputBorderRadius;
          },
          updateUserInputBackgroundColor() {
            this.$refs.chatPreview.chatbotSettings.userInputBackgroundColor = this.userInputBackgroundColor;
          },
          updateUserInputPlaceholderText() {
            this.$refs.chatPreview.chatbotSettings.userInputPlaceholderText = this.userInputPlaceholderText;
          },
          updateButtonBackgroundColor(object) {
            const index = this.chatButtonColorOptions.findIndex(option =>
              option.colorA === object.colorA && option.colorB === object.colorB
            );
            if (index !== -1) {
              // Assuming selectedChatButtonColor has colorA and colorB
              const selectedOption = this.chatButtonColorOptions[index];
              this.buttonBackgroundColor = selectedOption.colorA;
              this.buttonBackgroundColor = selectedOption.colorB;
              this.$refs.chatPreview.chatbotButtonSettings.borderColor = selectedOption.colorA
              this.$refs.chatPreview.chatbotButtonSettings.shadowColor = selectedOption.colorA;
              this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = selectedOption.colorA; //keep A so original color will work for non transient buttons
              this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = selectedOption.colorB;
            }
          },
          updateBackgroundType(value) {
            this.chatBackgroundType = value; // Update local data property
            this.$refs.chatPreview.chatbotSettings.chatBackgroundType = value; // Update child component
          },
          updateHeaderText(){
            this.$refs.chatPreview.chatbotSettings.headerText = this.headerText;
          },
          updateHeaderFontSize() {
            this.$refs.chatPreview.chatbotSettings.headerFontSize = this.headerFontSize;
          },
          updateHeaderTextColor() {
            this.$refs.chatPreview.chatbotSettings.headerTextColor = this.headerTextColor;
          },
          toggleShowPoweredBy() {
            this.$refs.chatPreview.chatbotSettings.showPoweredBy = this.showPoweredBy;
          },
          toggleStreamingResponse() {
            this.$refs.chatPreview.chatbotSettings.streamingResponse = this.streamingResponse;
          },
          toggleShowRating() {
            this.$refs.chatPreview.chatbotSettings.showRating = this.showRating;
          },
          toggleEnableOnlineMessage() {
            this.$refs.chatPreview.chatbotSettings.enableOnlineMessage = this.enableOnlineMessage;
          },
          toggleEnableStaticStartMessage() {
            this.$refs.chatPreview.chatbotSettings.enableStaticStartMessage = this.enableStaticStartMessage
          },
          updateStartMessage() {
            this.$refs.chatPreview.chatbotSettings.startMessage = this.startMessage;
          },
          updateOnlineMessage() {
            this.$refs.chatPreview.chatbotSettings.onlineMessage = this.onlineMessage;
          },
          toggleShowDateTime() {
            this.$refs.chatPreview.chatbotSettings.showDateTime = this.showDateTime;
          },
          toggleShowChatImages() {
            this.$refs.chatPreview.chatbotSettings.showChatImages = this.showChatImages;
          },
          changePoweredByImageSrc() {
            this.$refs.chatPreview.chatbotSettings.poweredByImageSrc = this.poweredByImageSrc;
          },
          updatePoweredByTextColor() {
            this.$refs.chatPreview.chatbotSettings.poweredByTextColor = this.poweredByTextColor;
          },
          updateOnlineTextColor() {
            this.$refs.chatPreview.chatbotSettings.onlineTextColor = this.onlineTextColor;
          },
          updateDatetimeColor() {
            this.$refs.chatPreview.chatbotSettings.datetimeColor = this.datetimeColor;
          },
          updateMessageDateFontSize() {
            this.$refs.chatPreview.chatbotSettings.messageDateFontSize = this.messageDateFontSize;
          },
          updateChatbotHeight() {
            this.$refs.chatPreview.chatbotSettings.chatbotHeight = this.chatbotHeight;
          },
          updateChatbotWidth() {
            this.$refs.chatPreview.chatbotSettings.chatbotWidth = this.chatbotWidth;
          },
          updateChatBlockBorderRadius() {
            this.$refs.chatPreview.chatbotSettings.chatBlockBorderRadius = this.chatBlockBorderRadius;
          },
          updateChatBackgroundImageTransparency() {
            this.$refs.chatPreview.chatbotSettings.chatBackgroundImageTransparency = this.chatBackgroundImageTransparency;
          },
          updateHeaderBackgroundColorType(value) {
            this.headerBackgroundColorType = value;
            this.$refs.chatPreview.chatbotSettings.headerBackgroundColorType = this.headerBackgroundColorType;
          },
          updateHeaderBackgroundColor() {
            this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = this.firstHeaderBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = this.secondHeaderBackgroundColor;
          },
          updateFooterBackgroundColorType(value) {
            this.footerBackgroundColorType = value;
            this.$refs.chatPreview.chatbotSettings.footerBackgroundColorType = this.footerBackgroundColorType;
          },
          updateFooterBackgroundColor() {
            this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = this.firstFooterBackgroundColor;
            this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = this.secondFooterBackgroundColor;
          },
          updateSpeechbubbleFontType() {
            this.$refs.chatPreview.chatbotSettings.speechBubbleFontType = this.speechBubbleFontType;
          },
          updateSpeechBubbleBotBackgroundColorType(value) {
            this.speechBubbleBotBackgroundColorType = value;
            this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundColorType = this.speechBubbleBotBackgroundColorType;
          },
          updateSpeechBubbleUserBackgroundColorType(value) {
            this.speechBubbleUserBackgroundColorType = value;
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundColorType = this.speechBubbleUserBackgroundColorType;
          },
          updateSendButtonBackgroundColorType(value) {
            this.sendButtonBackgroundColorType = value;
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundColorType = this.sendButtonBackgroundColorType;
          },
          updateSendButtonBackgroundFirstColor() {
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = this.sendButtonBackgroundFirstColor;
          },
          updateSendButtonBackgroundSecondColor() {
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = this.sendButtonBackgroundSecondColor;
          },
          updateScrollBarColor() {
            this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.scrollBarColor;
          },
          updateScrollBarWidth() {
            this.$refs.chatPreview.chatbotSettings.scrollBarWidth = this.scrollBarWidth;
          },
          updateSendButtonIconColor() {
            this.$refs.chatPreview.chatbotSettings.sendButtonIconColor = this.sendButtonIconColor;
          },
          updateSendLoadingDotsIconColor() {
            this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.sendLoadingDotsIconColor;
          },
          updateHeaderFontType() {
            this.$refs.chatPreview.chatbotSettings.headerFontType = this.headerFontType;
          },          
          toggleShowChatbuttonBubble() {
            this.$refs.chatPreview.chatbotButtonSettings.showChatbuttonBubble = this.showChatbuttonBubble;
          },
          updateChatbuttonBubbleDelay() {
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleDelay = this.chatbuttonBubbleDelay * 1000;
          },
          updateChatbuttonBubbleWidth() {
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleWidth = this.chatbuttonBubbleWidth;
          },
          updateChatbuttonBubbleRightPosition() {
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleRightPosition = this.chatbuttonBubbleRightPosition;
          },
          updateChatbuttonBubbleTopPosition() {
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleTopPosition = this.chatbuttonBubbleTopPosition;
          },
          updateChatButtonBubbleBackgroundColorType() {
            this.$refs.chatPreview.chatbotButtonSettings.chatButtonBubbleBackgroundColorType = this.chatButtonBubbleBackgroundColorType;
          },
          updateChatbuttonBubbleBackgroundColor() {
            this.$refs.chatPreview.chatbotButtonSettings.firstChatbuttonBubbleBackgroundColor = this.firstChatbuttonBubbleBackgroundColor;
            this.$refs.chatPreview.chatbotButtonSettings.secondChatbuttonBubbleBackgroundColor = this.secondChatbuttonBubbleBackgroundColor;
          },
          updateChatbuttonBubbleCloseBtnColor() {
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleCloseBtnColor = this.chatbuttonBubbleCloseBtnColor;
          },
          updateChatbuttonBubbleTextColor() {
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleTextColor = this.chatbuttonBubbleTextColor;
          },
          updateChatbuttonBubbleText() {
            this.$refs.chatPreview.chatbotButtonSettings.chatbuttonBubbleText = this.chatbuttonBubbleText;
          },
          setBubbleEffect() {
            this.$refs.chatPreview.chatbotButtonSettings.selectedBubbleEffect = this.selectedBubbleEffect;
            this.$refs.chatPreview.applyBubbleEffect(this.selectedBubbleEffect); //  calling the method to apply the effect
          },
          async uploadImage(imageType) {
            if (this.imageFile) {
              const configurationId = this.configuration._id;

              if (!configurationId) {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: 'No congifId :(' });
                return;
              }
              const formData = new FormData();
              formData.append("file", this.imageFile, this.imageFile.name);
              formData.append("configuration_id", configurationId);

              // Retrieve the token from localStorage
              const token = localStorage.getItem('userToken');
              if (!token) {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('authentication_required') });
                return;
              }

              try {
                const response = await axios.post(
                  this.$config.configurationServer + '/chatbot/settings/image/upload',
                  formData,
                  {
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data'
                    }
                  }
                );

                if (response.status === 200 && response.data.filename) {
                  this.downloadImage(this.configuration._id, response.data.filename, imageType); // Assuming 1 is for bot image
                } else {
                  // Handle non-successful responses
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('upload_fail') });
                }
              } catch (error) {
                // Handle errors in the request
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });
              } finally {
                // Reset the file input after handling the upload
                this.imageFile = null;
              }
            }
          },
          downloadImage(configurationId, filename, imageType) {
            const downloadUrl = `${this.$config.configurationServer}/chatbot/settings/image/download?configuration_id=` + encodeURIComponent(configurationId) + '&filename=' + encodeURIComponent(filename);
            this.changeImagePropertyChatObject(downloadUrl, imageType);
          },
          changeImagePropertyChatObject(imageUrl, imageType) {
            // Update your chatbot settings with the new image URL
            if (imageType === 1) {
              // Update the bot avatar image URL in your settings
              this.$refs.chatPreview.chatbotSettings.botImageSrc = imageUrl;
            }
            else if (imageType === 2) {
              this.$refs.chatPreview.chatbotSettings.chatBackgroundImageSrc = imageUrl;
            }
          },
          updateChatbotSize(dimension, value) {
            if (dimension === 'width') {
              this.$refs.chatPreview.chatbotSettings.chatbotWidth = value;
            } else if (dimension === 'height') {
              this.$refs.chatPreview.chatbotSettings.chatbotHeight = value;
            }
          },
          updateButtonBorderWidth() {
            this.$refs.chatPreview.chatbotButtonSettings.borderWidth = this.buttonBorderWidth;
          },
          updateButtonBorderColor() {
            this.$refs.chatPreview.chatbotButtonSettings.borderColor = this.buttonBorderColor;
          },
          updateButtonSize() {
            this.$refs.chatPreview.chatbotButtonSettings.buttonHeight = this.buttonSize;
            this.$refs.chatPreview.chatbotButtonSettings.buttonWidth = this.buttonSize;
          },
          updateButtonBorderRadius() {
            this.$refs.chatPreview.chatbotButtonSettings.buttonBorderRadius = this.buttonBorderRadius;
          },
          updateButtonShadowWidth() {
            this.$refs.chatPreview.chatbotButtonSettings.shadowWidth = this.buttonShadowWidth;
          },
          updateButtonShadowColor() {
            this.$refs.chatPreview.chatbotButtonSettings.shadowColor = this.buttonShadowColor;
          },
          updateButtonIconSize() {
            this.$refs.chatPreview.chatbotButtonSettings.iconSize = this.buttonIconSize;
          },
          updateButtonIconColor() {
            this.$refs.chatPreview.chatbotButtonSettings.iconColor = this.buttonIconColor;
          },
          updateChatButtonBackgroundColor() {
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = this.buttonBackgroundColor;
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = this.buttonBackgroundColorB;
          },
          saveDesign() {
            const chatbotSettings = {
              ...this.$refs.chatPreview.chatbotSettings,
              _id: null,
              configuration_id: this.configuration._id,
              isTemplate: false,
              templateName: null,
              disclaimerHtml: this.disclaimerHtml              
            };
            const chatbotButtonSettings = {
              ...this.$refs.chatPreview.chatbotButtonSettings
            }
            const data = {
              chatbotSettings: chatbotSettings,
              chatbotButtonSettings: chatbotButtonSettings
            };
            const token = localStorage.getItem('userToken');
            axios.post(`${this.configurationServer}/chatbot/settings`, data, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then(response => {
              console.log(response);
              EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('save_success')});
              EventBus.$emit('reload-test-tab');
            })
            .catch(error => {
              EventBus.$.emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });
            });
          }
        }
    }
</script>

<style scoped>

.selected-vlist-item {
  border: 1px solid #636363;
}

.test {
  color: #636363;
}

.color-picker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.color-picker {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid #717171;
  padding: 4px;
  -webkit-appearance: none;
  cursor: pointer;
}

/* This is to target the color picker's indicator in some browsers */
.color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-picker::-webkit-color-swatch {
  border: none; /* Removes any default border */
}
</style>

