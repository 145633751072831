<template>
  <v-card class="zoom-class custom-scroll" style="box-shadow: none; overflow:hidden;">
    <v-row justify="center">
      <!-- Navigation drawer -->
      <v-col style="padding-right:0px;" xl="2" lg="3" md="7" sm="6" xs="12">
        <div>
          <v-navigation-drawer
              dense
              v-model="drawer"
              :mini-variant.sync="mini"
              floating
              permanent
              width="100%"
            >
            <v-list-item class="px-2">
              <v-list-item-avatar>
                <v-icon>mdi-cogs</v-icon>
              </v-list-item-avatar>
              <v-list-item-title><span><strong>{{currentSection.toUpperCase()}}</strong></span></v-list-item-title>
              <v-btn
                icon
                @click.stop="mini = !mini"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list>
              <v-list-item @click="currentSection = 'templates'">
                <v-list-item-icon>
                  <v-icon>mdi-palette-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="currentSection != 'templates'">{{$t('templates')}}</v-list-item-title>
                  <div v-if="currentSection == 'templates'">
                    <div style="display: flex; flex-direction: column; align-items: flex-start;">
                      <v-btn @click="setTemplateColors(1)" style="margin-bottom: 8px;">
                        <div style="display: flex; align-items: center; justify-content: center;">
                          <div style="width: 20px; height: 10px; background-color: #6d00e1; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #fff; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #00b5fd;"></div>
                        </div>
                      </v-btn>
                      <v-btn @click="setTemplateColors(2)" style="margin-bottom: 8px;">
                        <div style="display: flex; align-items: center; justify-content: center;">
                          <div style="width: 20px; height: 10px; background-color: #984BF4; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #fff; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #EF8F55;"></div>
                        </div>
                      </v-btn>
                      <v-btn @click="setTemplateColors(3)" style="margin-bottom: 8px;">
                        <div style="display: flex; align-items: center; justify-content: center;">
                          <div style="width: 20px; height: 10px; background-color: #2A45A4; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #fff; margin-right: 8px;"></div>
                          <div style="width: 20px; height: 10px; background-color: #E764B8;"></div>
                        </div>
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-list-item :disabled="configuration.current_product === 'entry'" @click="currentSection = 'header'">
                      <v-list-item-icon>
                        <v-icon>mdi-format-header-1</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="currentSection != 'header'">{{$t('header')}}</v-list-item-title>
                        <div v-if="currentSection == 'header'">
                          <strong>Text</strong>
                          <v-text-field
                            v-model="headerText"
                            @input="updateHeaderText">
                          </v-text-field>
                          <div style="display: flex; align-items: center;">
                            <strong style="margin-right: 10px;">{{$t('color')}}:</strong>
                            <input
                              type="color"
                              id="headerColorPicker"
                              v-model="headerBackgroundColor"
                              @input="updateHeaderColor"
                              class="color-picker"
                            />
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :disabled="configuration.current_product === 'entry'" @click="currentSection = 'footer'">
                      <v-list-item-icon>
                        <v-icon>mdi-page-layout-footer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="currentSection != 'footer'">{{$t('footer')}}</v-list-item-title>
                        <div v-if="currentSection == 'footer'" style="display: flex; align-items: center;">
                          <strong style="margin-right: 10px;">{{$t('color')}}:</strong>
                          <input
                            type="color"
                            v-model="footerBackgroundColor"
                            @input="updateFooterColor"
                            class="color-picker"
                          />
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :disabled="configuration.current_product === 'entry'" @click="currentSection = 'avatar'">
                      <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="currentSection != 'avatar'">{{$t('avatar')}}</v-list-item-title>
                        <div v-if="currentSection == 'avatar'">
                          <v-file-input hide-input
                            label="Select an image"
                            prepend-icon="mdi-upload-box"
                            accept="image/*"
                            v-model="imageFile"
                            @change="uploadImage(1)"
                          ></v-file-input>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :disabled="configuration.current_product === 'entry'" @click="currentSection = 'background'">
                      <v-list-item-icon>
                        <v-icon>mdi-image-area</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="currentSection != 'background'">{{$t('background')}}</v-list-item-title>
                        <div v-if="currentSection == 'background'">
                          <v-radio-group
                            v-model="chatBackgroundType"
                            @change="updateBackgroundType"
                          >
                            <v-radio label="Solid Color" value="solid"></v-radio>
                            <v-radio label="Background Image" value="image"></v-radio>
                            <div v-if="chatBackgroundType === 'solid'" style="display: flex; align-items: center;">
                              <strong style="margin-right: 10px;">{{$t('color')}}:</strong>
                              <input
                                type="color"
                                v-model="backgroundColor"
                                @input="updateBackgroundColor"
                                class="color-picker"
                                />
                            </div>
                            <v-file-input hide-input v-if="chatBackgroundType === 'image'"
                              label="Select an image"
                              prepend-icon="mdi-upload-box"
                              accept="image/*"
                              v-model="imageFile"
                              @change="uploadImage(2)"
                            ></v-file-input>
                          </v-radio-group>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :disabled="configuration.current_product === 'entry'" @click="currentSection = 'speechbubbles'">
                      <v-list-item-icon>
                        <v-icon>mdi-chat</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="currentSection != 'speechbubbles'">{{$t('speech_bubbles')}}</v-list-item-title>
                        <v-col v-if="currentSection == 'speechbubbles'">
                          <div  style="display: flex; align-items: center;">
                            <strong style="margin-right: 10px;">{{$t('bot_color')}}:</strong>
                            <input
                              type="color"
                              v-model="botSpeechBubbleColor"
                              @input="updateBotSpeechBubbleColor"
                              class="color-picker"
                            />
                          </div>
                          <div  style="display: flex; align-items: center;">
                            <strong style="margin-right: 10px;">{{$t('user_color')}}:</strong>
                            <input
                              type="color"
                              v-model="userSpeechBubbleColor"
                              @input="updateUserSpeechBubbleColor"
                              class="color-picker"
                            />
                          </div>
                        </v-col>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :disabled="configuration.current_product === 'entry'" @click="currentSection = 'miscellaneous'">
                      <v-list-item-icon>
                        <v-icon>mdi-alpha-m-box</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="currentSection != 'miscellaneous'">{{$t('miscellaneous')}}</v-list-item-title>
                        <div v-if="currentSection == 'miscellaneous'" style="display: flex; align-items: center;">
                          <strong style="margin-right: 10px;">{{$t('color')}}:</strong>
                          <input
                            type="color"
                            v-model="miscColor"
                            @input="updateMiscColor"
                            class="color-picker"
                          />
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :disabled="configuration.current_product === 'entry'" @click="currentSection = 'chat-button'">
                      <v-list-item-icon>
                        <v-icon>mdi-card-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="currentSection != 'chat-button'">{{$t('chat_button')}}</v-list-item-title>
                        <div v-if="currentSection == 'chat-button'" style="display: flex; align-items: center;">
                          <div style="width:60px;">
                            <v-row>
                              <v-select
                              @change="updateButtonBackgroundColor($event)"
                                :items="chatButtonColorOptions"
                                v-model="selectedChatButtonColorIndex"
                              >
                                <!-- Custom rendering for items in dropdown -->
                                <template v-slot:item="{ item }">
                                  <div class="color-preview" :style="{ marginLeft: '0px', background: `linear-gradient(to right, ${item.colorA}, ${item.colorB})` }">
                                  </div>
                                </template>

                                <template v-slot:selection="{ item }">
                                  <div class="color-preview" :style="{ marginLeft: '15px', background: `linear-gradient(to right, ${item.colorA}, ${item.colorB})` }"></div>
                                </template>

                              </v-select>
                            </v-row>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
                <span>{{$t('these_settings_are_available')}}</span>
              </v-tooltip>
            </v-list>
          </v-navigation-drawer>
        </div>
      </v-col>
      <!-- Preview -->
      <v-col style="padding-left:0px;" xl="1" lg="5" md="5" sm="6" xs="12" class="mobile-zoom">
        <v-card style="box-shadow:none; overflow:hidden;">
          <ChatPreview ref="chatPreview" />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ChatPreview from './designer/ChatPreview.vue';
import axios from 'axios';
import { EventBus } from '../../eventBus.js';

export default {
  name: "StepThreeDesign",
  components: {
    ChatPreview
  },
  props: {
    configuration: Object
  },
  data() {
    return {
      topic: '',
      headerBackgroundColor: '',
      footerBackgroundColor: '',
      botSpeechBubbleColor: '',
      userSpeechBubbleColor: '',
      backgroundColor: '',
      sendButtonColor: '',
      miscColor: '',
      sliderWidth: 350,  // Default width, adjust as needed
      sliderHeight: 510, // Default height, adjust as needed
      configurationServer: this.$config.configurationServer,
      imageFile: null,
      chatBackgroundType: '',
      headerText: '',
      buttonBorderColor: '',
      buttonBackgroundColor: '',
      buttonBackgroundColorB: '',
      buttonIconColor: '',
      drawer: null,
      mini: false,
      currentSection: '',
      render_preview: false,
      selectedChatButtonColorIndex: -1,
      selectedChatButtonColor: '',
      chatButtonColorOptions: [
          { colorA: '#D755556', colorB: '#8A48E4' },
          { colorA: '#6064E6', colorB: '#E764B8' },
          { colorA: '#2A45A4', colorB: '#BA6AD2' },
          { colorA: '#8A4AE8', colorB: '#70B1ED' },
          { colorA: '#5ACED9', colorB: '#2947A2' },
          { colorA: '#8A4AE9', colorB: '#01B667' },
          { colorA: '#2CB7D3', colorB: '#E6DD68' },
          { colorA: '#EDD45C', colorB: '#EFDC60' },
          { colorA: '#FADC5B', colorB: '#FC9750' },
          { colorA: '#FC6CBF', colorB: '#FBDA60' },
          { colorA: '#984BF4', colorB: '#EF8F55' }
      ],
    };
  },
  created() {
     //EventBus.$on('execute-save-design', this.saveDesign);
  },
  async mounted() {
    let chatbotSettings = this.$refs.chatPreview.chatbotSettings;
    chatbotSettings.headerText = this.$t('header_text');
    let chatbotButtonSettings = this.$refs.chatPreview.chatbotButtonSettings;

    if (this.configuration._id) {
      try {
        // Attempt to fetch chatbot settings
        let existingChatbotSettings = await this.getChatbotSettings();
        this.$refs.chatPreview.chatbotSettings.headerText = this.configuration.companyName + ' chat';
        // If no settings are retrieved, use the ones from $refs
        if (existingChatbotSettings) {
          chatbotSettings = existingChatbotSettings;
          this.$refs.chatPreview.chatbotSettings.enableAIStartMessage = chatbotSettings.enableAIStartMessage;
          this.$refs.chatPreview.chatbotSettings.startMessage = chatbotSettings.startMessage;
          this.$refs.chatPreview.chatbotSettings.headerText = chatbotSettings.headerText;
          chatbotButtonSettings = existingChatbotSettings.button_settings;
        }
        else {
          this.$refs.chatPreview.chatbotSettings.headerText = this.configuration.companyName;
          this.setTemplateColors(1);
        }
      } catch (error) {
        console.error('Error fetching chatbot settings:', error);
      }
    }

    this.headerBackgroundColor = chatbotSettings.firstHeaderBackgroundColor;
    this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = this.headerBackgroundColor;

    if (chatbotSettings.headerBackgroundColorType === 'gradient') {
      this.$refs.chatPreview.chatbotSettings.headerBackgroundColorType = 'gradient';
      this.$refs.chatPreview.chatbotSettings.headerGradientAngle = chatbotSettings.headerGradientAngle;
      this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = chatbotSettings.secondHeaderBackgroundColor;
    }

    this.footerBackgroundColor = chatbotSettings.firstFooterBackgroundColor;
    this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = this.footerBackgroundColor;

    if (chatbotSettings.footerBackgroundColorType === 'gradient') {
      this.$refs.chatPreview.chatbotSettings.footerBackgroundColorType = 'gradient';
      this.$refs.chatPreview.chatbotSettings.footerGradientAngle = chatbotSettings.footerGradientAngle;
      this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = chatbotSettings.secondFooterBackgroundColor;
    }

    this.botSpeechBubbleColor = chatbotSettings.speechBubbleBotBackgroundFirstColor;
    this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = this.botSpeechBubbleColor;

    this.userSpeechBubbleColor = chatbotSettings.speechBubbleUserBackgroundFirstColor;
    this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = this.userSpeechBubbleColor;

    if (chatbotSettings.speechBubbleUserBackgroundColorType === 'gradient') {
      this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundColorType = chatbotSettings.speechBubbleUserBackgroundColorType;
      this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = chatbotSettings.speechBubbleUserBackgroundSecondColor;
    }

    this.chatBackgroundType = chatbotSettings.chatBackgroundType;
    this.$refs.chatPreview.chatbotSettings.chatBackgroundType = this.chatBackgroundType;

    this.backgroundColor = chatbotSettings.firstChatBackgroundColor;
    this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = this.backgroundColor;

    this.sendButtonColor = chatbotSettings.sendButtonBackgroundFirstColor;
    this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = this.sendButtonColor;

    if (chatbotSettings.sendButtonBackgroundColorType === 'gradient') {
      this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundColorType = 'gradient';
      this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = chatbotSettings.sendButtonBackgroundSecondColor;
    }

    this.miscColor = chatbotSettings.sendButtonBackgroundFirstColor;

    this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.miscColor;
    this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.miscColor;

    this.headerText = chatbotSettings.headerText;
    this.$refs.chatPreview.chatbotSettings.headerText = this.headerText;

    this.$refs.chatPreview.chatbotSettings.botImageSrc = chatbotSettings.botImageSrc;
    this.$refs.chatPreview.chatbotSettings.chatBackgroundImageSrc = chatbotSettings.chatBackgroundImageSrc;

    // chatbot button
    this.buttonBackgroundColor = chatbotButtonSettings.buttonBackgroundColor;
    this.buttonBackgroundColorB = chatbotButtonSettings.buttonBackgroundColorB;

    const defaultIndex = this.chatButtonColorOptions.findIndex(option =>
      option.colorA === this.buttonBackgroundColor && option.colorB === this.buttonBackgroundColorB
    );

    if (defaultIndex !== -1) {
      this.selectedChatButtonColorIndex = defaultIndex;
    } else {
      // If no default found, you can set a default index or leave it as is
      this.selectedChatButtonColorIndex = 0; // or any default you prefer
    }

    this.buttonBorderColor = chatbotButtonSettings.borderColor;
    this.$refs.chatPreview.chatbotButtonSettings.borderColor = this.buttonBorderColor;

    this.buttonBackgroundColor = chatbotButtonSettings.buttonBackgroundColor;
    this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = this.buttonBackgroundColor;

    this.buttonBackgroundColor = chatbotButtonSettings.buttonBackgroundColor;
    this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = this.buttonBackgroundColor;

    this.buttonBackgroundColorB = chatbotButtonSettings.buttonBackgroundColorB;
    this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = this.buttonBackgroundColorB;

    this.shadowColor = chatbotButtonSettings.shadowColor;
    this.$refs.chatPreview.chatbotButtonSettings.shadowColor = this.shadowColor;

    this.buttonIconColor = chatbotButtonSettings.iconColor;
    this.$refs.chatPreview.chatbotButtonSettings.iconColor = this.buttonIconColor;
  },
  computed: {
    chatbotSettings() {
      if (this.$refs.chatPreview && this.$refs.chatPreview.chatbotSettings) {
        return this.$refs.chatPreview.chatbotSettings;
      }
      return {}; // Return an empty object or some default settings as a fallback
    },
    chatbotButtonSettings() {
      if (this.$refs.chatPreview && this.$refs.chatPreview.chatbotButtonSettings) {
        return this.$refs.chatPreview.chatbotButtonSettings;
      }
      return {};
    }
  },
  methods: {
    initialize() {

    },
    setTemplateColors(templateChoice) {
      const themeAColorA = '#6d00e1';
      const themeAColorB = '#00b5fd';
      const themeBColorA = '#984BF4';
      const themeBColorB = '#EF8F55';
      const themeCColorA = '#2A45A4';
      const themeCColorB = '#E764B8';

      switch (templateChoice) {
          case 1:
            this.$refs.chatPreview.chatbotSettings.headerBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.headerGradientAngle = 145;
            this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = themeAColorA;
            this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = themeAColorB;

            this.$refs.chatPreview.chatbotSettings.footerBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.footerGradientAngle = 145;
            this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = themeAColorA;
            this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = themeAColorB;

            this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = '#EFF2F8';

            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = themeAColorA;
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = themeAColorB;

            this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = '#FFFFFF';

            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = themeAColorA;
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = themeAColorB;

            this.$refs.chatPreview.chatbotSettings.botImageSrc = 'https://hosthubconfigurationtest.azurewebsites.net//chatbot/settings/image/download?configuration_id=3263368c-8eaa-4288-abf5-fd329b2fd318&filename=b2663643-c3e8-4054-aa1b-d69628757008'

            this.$refs.chatPreview.chatbotSettings.userAvatarBackgroundColor = themeAColorB;

            this.miscColor = themeAColorB;
            this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.miscColor;
            this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.miscColor;

            this.$refs.chatPreview.chatbotButtonSettings.borderColor = themeAColorA;
            this.$refs.chatPreview.chatbotButtonSettings.shadowColor = themeAColorA;
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = themeAColorA; //keep A so original color will work for non transient buttons
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = themeAColorB;

            break;
            case 2:
            this.$refs.chatPreview.chatbotSettings.headerBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.headerGradientAngle = 145;
            this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = themeBColorA;
            this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = themeBColorB;

            this.$refs.chatPreview.chatbotSettings.footerBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.footerGradientAngle = 145;
            this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = themeBColorA;
            this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = themeBColorB;

            this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = '#EFF2F8';

            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = themeBColorA;
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = themeBColorB;

            this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = '#FFFFFF';

            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = themeBColorA;
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = themeBColorB;

            this.$refs.chatPreview.chatbotSettings.botImageSrc = 'https://hosthubconfigurationtest.azurewebsites.net//chatbot/settings/image/download?configuration_id=3263368c-8eaa-4288-abf5-fd329b2fd318&filename=b2663643-c3e8-4054-aa1b-d69628757008'

            this.$refs.chatPreview.chatbotSettings.userAvatarBackgroundColor = themeBColorB;

            this.miscColor = themeBColorB;
            this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.miscColor;
            this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.miscColor;

            this.$refs.chatPreview.chatbotButtonSettings.borderColor = themeBColorA;
            this.$refs.chatPreview.chatbotButtonSettings.shadowColor = themeBColorA;
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = themeBColorA; //keep A so original color will work for non transient buttons
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = themeBColorB;
            break;
          case 3:
            this.$refs.chatPreview.chatbotSettings.headerBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.headerGradientAngle = 145;
            this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = themeCColorA;
            this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = themeCColorB;

            this.$refs.chatPreview.chatbotSettings.footerBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.footerGradientAngle = 145;
            this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = themeCColorA;
            this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = themeCColorB;

            this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = '#EFF2F8';

            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = themeCColorA;
            this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = themeCColorB;

            this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = '#FFFFFF';

            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundColorType = 'gradient';
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = themeCColorA;
            this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = themeCColorB;

            this.$refs.chatPreview.chatbotSettings.botImageSrc = 'https://hosthubconfigurationtest.azurewebsites.net//chatbot/settings/image/download?configuration_id=3263368c-8eaa-4288-abf5-fd329b2fd318&filename=b2663643-c3e8-4054-aa1b-d69628757008'

            this.$refs.chatPreview.chatbotSettings.userAvatarBackgroundColor = themeCColorB;

            this.miscColor = themeCColorB;
            this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.miscColor;
            this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.miscColor;

            this.$refs.chatPreview.chatbotButtonSettings.borderColor = themeCColorA;
            this.$refs.chatPreview.chatbotButtonSettings.shadowColor = themeCColorA;
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = themeCColorA; //keep A so original color will work for non transient buttons
            this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = themeCColorB;
            break;
          }
    },
    showPromoSnackbar() {
      const message = this.configuration.wizardCompleted ? this.$t('promo.design_text_two') : this.$t('promo.design_text_one')
      const hideButtons = this.configuration.wizardCompleted ? false : true

      EventBus.$emit('show-promosnackbar', {
        message: message,
        header: this.$t('promo.design_header'),
        timeout: 10000,
        hideButtons: hideButtons
      });
    },
    getChatbotSettings() {
      const token = localStorage.getItem('userToken');
      const configurationId = this.configuration._id;

      return axios.get(`${this.configurationServer}/chatbot/settings/byConfigurationId`, {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { configuration_id: configurationId }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: error});
      });
    },
    updateHeaderColor() {
      this.$refs.chatPreview.chatbotSettings.firstHeaderBackgroundColor = this.headerBackgroundColor;
      this.$refs.chatPreview.chatbotSettings.secondHeaderBackgroundColor = this.headerBackgroundColor;
    },
    updateFooterColor() {
      this.$refs.chatPreview.chatbotSettings.firstFooterBackgroundColor = this.footerBackgroundColor;
      this.$refs.chatPreview.chatbotSettings.secondFooterBackgroundColor = this.footerBackgroundColor;
    },
    updateBackgroundColor() {
      this.$refs.chatPreview.chatbotSettings.firstChatBackgroundColor = this.backgroundColor;
    },
    updateBotSpeechBubbleColor() {
      this.$refs.chatPreview.chatbotSettings.speechBubbleBotBackgroundFirstColor = this.botSpeechBubbleColor;
    },
    updateUserSpeechBubbleColor() {
      this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundFirstColor = this.userSpeechBubbleColor;
      this.$refs.chatPreview.chatbotSettings.speechBubbleUserBackgroundSecondColor = this.userSpeechBubbleColor;
    },
    updateMiscColor() {
      this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundFirstColor = this.miscColor;
      this.$refs.chatPreview.chatbotSettings.sendButtonBackgroundSecondColor = this.miscColor;
      this.$refs.chatPreview.chatbotSettings.scrollBarColor = this.miscColor;
      this.$refs.chatPreview.chatbotSettings.sendLoadingDotsIconColor = this.miscColor;
    },
    updateButtonBackgroundColor(object) {
      const index = this.chatButtonColorOptions.findIndex(option =>
        option.colorA === object.colorA && option.colorB === object.colorB
      );
      if (index !== -1) {
        // Assuming selectedChatButtonColor has colorA and colorB
        const selectedOption = this.chatButtonColorOptions[index];
        this.buttonBackgroundColor = selectedOption.colorA;
        this.buttonBackgroundColor = selectedOption.colorB;
        this.$refs.chatPreview.chatbotButtonSettings.borderColor = selectedOption.colorA
        this.$refs.chatPreview.chatbotButtonSettings.shadowColor = selectedOption.colorA;
        this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColor = selectedOption.colorA; //keep A so original color will work for non transient buttons
        this.$refs.chatPreview.chatbotButtonSettings.buttonBackgroundColorB = selectedOption.colorB;
      }
    },
    updateBackgroundType(value) {
      this.chatBackgroundType = value; // Update local data property
      this.$refs.chatPreview.chatbotSettings.chatBackgroundType = value; // Update child component
    },
    updateHeaderText(){
      this.$refs.chatPreview.chatbotSettings.headerText = this.headerText;
    },
    async uploadImage(imageType) {
      if (this.imageFile) {
        const configurationId = this.configuration._id;

        if (!configurationId) {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: 'No congifId :(' });
          return;
        }
        const formData = new FormData();
        formData.append("file", this.imageFile, this.imageFile.name);
        formData.append("configuration_id", configurationId);

        // Retrieve the token from localStorage
        const token = localStorage.getItem('userToken');
        if (!token) {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('authentication_required') });
          return;
        }

        try {
          const response = await axios.post(
            this.$config.configurationServer + '/chatbot/settings/image/upload',
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          if (response.status === 200 && response.data.filename) {
            this.downloadImage(this.configuration._id, response.data.filename, imageType); // Assuming 1 is for bot image
          } else {
            // Handle non-successful responses
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('upload_fail') });
          }
        } catch (error) {
          // Handle errors in the request
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });
        } finally {
          // Reset the file input after handling the upload
          this.imageFile = null;
        }
      }
    },
    downloadImage(configurationId, filename, imageType) {
      const downloadUrl = `${this.$config.configurationServer}/chatbot/settings/image/download?configuration_id=` + encodeURIComponent(configurationId) + '&filename=' + encodeURIComponent(filename);
      this.changeImagePropertyChatObject(downloadUrl, imageType);
    },
    changeImagePropertyChatObject(imageUrl, imageType) {
      // Update your chatbot settings with the new image URL
      if (imageType === 1) {
        // Update the bot avatar image URL in your settings
        this.$refs.chatPreview.chatbotSettings.botImageSrc = imageUrl;
      }
      else if (imageType === 2) {
        this.$refs.chatPreview.chatbotSettings.chatBackgroundImageSrc = imageUrl;
      }
    },
    updateChatbotSize(dimension, value) {
      if (dimension === 'width') {
        this.$refs.chatPreview.chatbotSettings.chatbotWidth = value;
      } else if (dimension === 'height') {
        this.$refs.chatPreview.chatbotSettings.chatbotHeight = value;
      }
    },
    saveDesign() {

      const chatbotSettings = {
        ...this.$refs.chatPreview.chatbotSettings,
        _id: null,
        configuration_id: this.configuration._id,
        isTemplate: false,
        templateName: null,
        enableAIStartMessage: this.configuration.enableAIStartMessage, // these 2 values are part of the design originally not of configuration
        startMessage: this.configuration.startMessage
      };
      const chatbotButtonSettings = {
        ...this.$refs.chatPreview.chatbotButtonSettings
      }
      const data = {
        chatbotSettings: chatbotSettings,
        chatbotButtonSettings: chatbotButtonSettings
      };

      // Retrieve the token from localStorage
      const token = localStorage.getItem('userToken');
      if (!token) {
        // Handle the case where there is no token
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('authentication_required') });
        return;
      }

      axios.post(`${this.configurationServer}/chatbot/settings`, data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        console.log(response);
        EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('save_success')});
        this.$emit('chatbotsettings-saved');
        EventBus.$emit('execute-refresh-test');
      })
      .catch(error => {
        EventBus.$.emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });
      });
    }
  },
};
</script>

<style scoped>
  .custom-scroll {
      overflow-y: hidden; /* Hide the scrollbar by default */
  }
  .custom-label {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: #333;
}

.color-picker-container {
  display: flex;
  align-items: center;
}

.color-picker-label {
  margin-right: 10px;
}

.color-picker {
  width: 25px;
  height: 25px;
  cursor: pointer;
  padding: 0; /* Remove any default padding */
  -webkit-appearance: none; /* Removes default styles in WebKit browsers */
  outline: none; /* Remove default focus outline */
  background-size: cover;
}

.color-preview {
  width: 35px;
  height: 35px;
  border-radius: 50%; /* This makes the element circular */
}

.centered-content {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content (if needed) */
  width: 100%; /* Ensure the container takes full width */
}

@media (max-width: 750px) {
  .zoom-class{
    zoom: 90%
  }
}
@media (max-width: 700px) {
  .zoom-class{
    zoom: 85%
  }
}
@media (max-width: 650px) {
  .zoom-class{
    zoom: 80%
  }
}

@media (max-width: 600px) {
  .mobile-zoom{
    zoom: 90%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 85%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 80%
  }
}

@media (max-width: 500px) {
  .mobile-zoom{
    zoom: 75%
  }
}

@media (max-width: 450px) {
  .mobile-zoom{
    zoom: 70%
  }
}

@media (max-width: 400px) {
  .mobile-zoom{
    zoom: 65%
  }
}
</style>
