<template>
    <div>
        <!-- First row for selecting the webshop -->
        <v-row no-gutters justify="center">
            <v-col cols="4">
                <v-select
                    v-model="webshopSettings.selectedWebshop"
                    :items="webshopOptions"
                    :label="$t('webshop.label')"
                    outlined
                ></v-select>
            </v-col>
        </v-row>

        <!-- Webshop Key and Secret Input -->
        <v-row v-if="webshopSettings._id" no-gutters justify="center">
            <v-col cols="3">
                <v-text-field
                    label="Url"
                    v-model="webshopUrl"
                    :placeholder="webshopUrlPlaceholder"
                    persistent-placeholder
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="3" class="pl-1">
                <v-text-field
                    label="Key"
                    v-model="webshopKey"
                    :placeholder="webshopKeyPlaceholder"
                    persistent-placeholder
                    type="password"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col v-if="webshopSettings.selectedWebshop != 'shopify'" cols="3" class="pl-1">
                <v-text-field                    
                    label="Secret"
                    v-model="webshopSecret"
                    :placeholder="webshopSecretPlaceholder"
                    persistent-placeholder
                    type="password"
                    outlined
                    dense                    
                ></v-text-field>
            </v-col>
            <v-col cols="2" class="pl-1">
                <v-btn :disabled="!webshopUrl || !webshopKey || (webshopSettings.selectedWebshop !== 'shopify' && !webshopSecret)" 
                    outlined 
                    @click="saveWebshopCredentials">
                    {{$t('save')}}
                </v-btn>
                <v-btn :disabled="!credentialsExist" outlined @click="testWebShopConnection">
                    {{$t('test')}}
                </v-btn>
            </v-col>
        </v-row>

        <!-- Combobox for selecting fields -->
        <v-row no-gutters v-if="webshopSettings._id" justify="center">
            <v-col cols="6" class="pl-3">
                <v-combobox
                    v-model="webshopSettings.selectedOptions"
                    :items="flattenedOptions"
                    item-value="value"
                    item-text="text"
                    clearable
                    multiple
                    small-chips
                    :label="webshopSettings.selectedWebshop === 'shopify' ? $t('webshop.shopify.fields.label') : $t('webshop.woocommerce.fields.label')"
                    outlined
                    :disabled-item="isDisabled"
                    @input="updateSelection"
                >
                    <template v-slot:item="data">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="data.item.text"></v-list-item-title>
                                    </v-list-item-content>
                                </div>
                            </template>
                            <span>{{ data.item.description }}</span>
                        </v-tooltip>
                    </template>
                </v-combobox>
            </v-col>
        </v-row>

        <!-- Second row for displaying the JSON preview -->
        <v-row no-gutters justify="center" v-if="webshopSettings._id">
            <v-col cols="9">
                <v-card outlined>
                    <v-card-title>{{ $t('webshop.woocommerce.fields.preview') }}</v-card-title>
                    <v-card-text style="max-height: 250px; overflow-y: auto;">
                        <pre>{{ sampleJson }}</pre>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters justify="center" class="pt-3">
            <v-col cols="4">
                <h2>
                    Indexed products: {{ indexedProductsCount }}
                    <v-icon
                        v-if="indexedProductsCount > 0"
                        @click="openDeleteDialog"
                        class="ml-2"
                        color="red"
                    >mdi-delete</v-icon>
                </h2>
            </v-col>

            <v-col cols="2">
                <v-select
                    v-model="selectedPage"
                    :items="pageOptions"
                    :label="$t('start_from_page')"
                    outlined
                ></v-select>
                <v-row no-gutters>
                    <v-text-field v-if="webshopSettings.selectedWebshop === 'shopify'"
                        label="vendor"
                        v-model="webshopSettings.selectedVendor"                       
                        persistent-placeholder
                        outlined
                        dense
                    ></v-text-field>
                    <v-btn v-if="webshopSettings.selectedWebshop === 'woov3'" :disabled="!totalProducts || indexedProductsCount > 0" outlined @click="indexWoocommerceProducts">
                        {{$t('index_products')}}
                    </v-btn>
                    <v-btn v-if="webshopSettings.selectedWebshop === 'shopify'" :disabled="!totalProducts || indexedProductsCount > 0" outlined @click="indexShopifyProducts">
                        {{$t('index_products')}}
                    </v-btn>
                </v-row>
                
            </v-col>

            <v-col cols="4">
                <h2>Total products: {{ totalProducts }}</h2>
            </v-col>

            <!-- Delete Confirmation Dialog -->
            <v-dialog v-model="deleteDialog" max-width="400">
                <v-card>
                    <v-card-title class="justify-center grey primary white--text">{{ $t('delete_product_catalogue_confirmation') }}</v-card-title>
                    <v-card-text class="pt-4 text-center">{{ $t('delete_product_catalogue_confirmation_message') }}</v-card-text>
                    <v-card-actions class="justify-center">                        
                        <v-btn color="primary" text @click="confirmDelete">{{ $t('confirm') }}</v-btn>
                        <v-btn color="secondary" text @click="deleteDialog = false">{{ $t('cancel') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row no-gutters justify="center" class="pt-3">
            <v-btn color="primary" :disabled="!webshopSettings.selectedWebshop" outlined @click="saveWebshopSettings">
                {{$t('save')}}
            </v-btn>
        </v-row>
    </div>
</template>

<script>
import { EventBus } from '@/eventBus';
import axios from 'axios';

export default {
    name: 'WebShopSettings',
    data() {
        return {
            webshopOptions: [],
            checkboxGroups: [],
            sampleJson: '',
            webshopUrl: '',
            webshopKey: '',
            webshopSecret: '',
            webshopSettings: {
                selectedWebshop: '',
                selectedOptions: [],
                selectedVendor: null
            },
            totalProducts: null,
            credentialsExist: false,
            webshopUrlPlaceholder: this.$t('enter_webshop_url'),
            webshopKeyPlaceholder: this.$t('enter_webshop_key'),
            webshopSecretPlaceholder: this.$t('enter_webshop_secret'),
            indexedProductsCount: null,
            selectedPage: 1, // Default selected page
            pageOptions: [], // Options for the dropdown
            productsPerPage: 100,  // Number of products per page
            deleteDialog: false,            
        };
    },
    computed: {
        selectedConfiguration() {
            return this.$store.getters.selectedConfiguration;
        },
        // Flatten the checkboxGroups into a single array for use in the combobox
        flattenedOptions() {
            const flattened = this.checkboxGroups.flat().map(option => ({
                text: option.text,
                value: option.value,
                description: option.description
            }));
            return flattened;
        },
    },
    async mounted() {
        await this.initialize();        
        this.updateCheckboxGroups(this.webshopSettings.selectedWebshop);
    },
    created() {
        EventBus.$on('clear', this.initialize);
        this.initializeTranslations();
        this.setMandatoryFields();
        this.updateSampleJson(); // Initialize the sample JSON on load
    },
    methods: {
        clearFields() {
            this.webshopUrl = '';
            this.webshopKey = '';
            this.webshopSecret = '';
            this.webshopSettings = {
                selectedWebshop: '',
                selectedOptions: [],
            };
            this.totalProducts = null;
            this.credentialsExist = false;
            this.indexedProductsCount = null;
            this.selectedPage = 1;
            this.pageOptions = [];
            this.sampleJson = '';
            this.deleteDialog = false;
        },
        async initialize() {
            this.clearFields()
            await this.getWebshopSettings();            
            if (this.webshopSettings.selectedWebshop) {                       
                await this.checkWebshopCredentialsExist();
                await this.updateSampleJson();
                await this.getIndexedProductsCount();
                if (this.credentialsExist) {
                    await this.testWebShopConnection();
                    this.updatePageOptions(); 
                }
            }
        },

        async getWebshopSettings() {
            EventBus.$emit('show-overlay');
            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.get(`${this.$config.configurationServer}/webshop/webshopsettings`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        configurationId: this.selectedConfiguration._id
                    }
                });
                
                if (response.data.status === 'OK') {
                    this.webshopSettings = response.data.data;
                    EventBus.$emit('show-snackbar', {
                        type: 'SUCCESS',
                        message: this.$t('settings_loaded_successfully')
                    });
                } else if (response.data.status === 'WARN') {
                    // Don't show for now
                    // Handle the WARN status
                    // EventBus.$emit('show-snackbar', {
                    //     type: 'INFO',
                    //     message: this.$t('webshop.settings_not_found') // Assuming you have a translation key for this
                    // });
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                EventBus.$emit('show-snackbar', {
                    type: 'ERROR',
                    message: `${this.$t('error')} : ${error.message}`
                });
            } finally {
                EventBus.$emit('hide-overlay');
            }
        },

        async saveWebshopCredentials() {
            EventBus.$emit('show-overlay');
            try {
                const token = localStorage.getItem('userToken');

                // Prepare the credentials data
                const credentialsData = {
                    configurationId: this.selectedConfiguration._id,
                    webshopUrl: this.webshopUrl,
                    webshopKey: this.webshopKey,
                    webshopSecret: this.webshopSecret, 
                    webshoptType: this.webshopSettings.selectedWebshop
                };

                const response = await axios.post(`${this.$config.configurationServer}/webshop/webshopcredentials`, credentialsData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.data.status === 'OK') {
                    EventBus.$emit('show-snackbar', {
                        type: 'SUCCESS',
                        message: this.$t('credentials_saved_successfully')
                    });
                    await this.checkWebshopCredentialsExist();
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                EventBus.$emit('show-snackbar', {
                    type: 'ERROR',
                    message: `${this.$t('error')} : ${error.message}`
                });
            } finally {
                EventBus.$emit('hide-overlay');
            }
        },

        async checkWebshopCredentialsExist() {
            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.get(`${this.$config.configurationServer}/webshop/webshopcredentials/exists`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        configurationId: this.selectedConfiguration._id
                    }
                });

                if (response.data.status === 'OK') {
                    // If credentials exist, enable the test button and set placeholders
                    this.credentialsExist = response.data.exists;
                    if (this.credentialsExist) {
                        this.webshopUrlPlaceholder = this.$t('click_to_update');
                        this.webshopKeyPlaceholder = this.$t('click_to_update');
                        this.webshopSecretPlaceholder = this.$t('click_to_update');
                    } else {
                        this.webshopUrlPlaceholder = this.$t('enter_webshop_url');
                        this.webshopKeyPlaceholder = this.$t('enter_webshop_key');
                        this.webshopSecretPlaceholder = this.$t('enter_webshop_secret');
                    }
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                EventBus.$emit('show-snackbar', {
                    type: 'ERROR',
                    message: `${this.$t('error')} : ${error.message}`
                });
            }
        },

        async saveWebshopSettings() {
            EventBus.$emit('show-overlay');
            try {
                const token = localStorage.getItem('userToken');

                const response = await axios.post(`${this.$config.configurationServer}/webshop/webshopsettings`, {
                    configurationId: this.selectedConfiguration._id,
                    webshopSettings: this.webshopSettings
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.data.status === 'OK') {
                    this.webshopSettings = response.data.data;
                    EventBus.$emit('show-snackbar', {
                        type: 'SUCCESS',
                        message: this.$t('save_success')
                    });
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                EventBus.$emit('show-snackbar', {
                    type: 'ERROR',
                    message: `${this.$t('error')} : ${error.message}`
                });
            } finally {
                EventBus.$emit('hide-overlay');
            }
        },

        async testWebShopConnection() {
            EventBus.$emit('show-overlay');
            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.get(`${this.$config.configurationServer}/webshop/test`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        configurationId: this.selectedConfiguration._id
                    }
                });

                const responseData = response.data;

                if (responseData && responseData.status === 'OK') {
                    this.totalProducts = responseData.total_count;
                    EventBus.$emit('show-snackbar', {
                        type: 'SUCCESS',
                        message: `${this.$t('test_success')} : ${responseData.message}, ${this.$t('total_products')}: ${this.totalProducts}`
                    });
                    this.wooTestOk = true;
                } else {
                    EventBus.$emit('show-snackbar', {
                        type: 'ERROR',
                        message: `${this.$t('test_unsuccess')} : ${responseData.message}`
                    });
                }
            } catch (error) {
                EventBus.$emit('show-snackbar', {
                    type: 'ERROR',
                    message: `${this.$t('test_unsuccess')} : ${error.message}`
                });
            } finally {
                EventBus.$emit('hide-overlay');
            }
        },

        async getIndexedProductsCount() {
            EventBus.$emit('show-overlay');
            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.get(`${this.$config.configurationServer}/webshop/indexedproductscount`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        configurationId: this.selectedConfiguration._id
                    }
                });                

                const responseData = response.data;
                if (responseData && responseData.status === 'OK') {
                    // Set the indexed products count
                    this.indexedProductsCount = responseData.data;
                    EventBus.$emit('show-snackbar', {
                        type: 'SUCCESS',
                        message: `${this.$t('indexed_products_count_success')} : ${this.indexedProductsCount}`
                    });
                } else if (responseData.status === 'WARN') {
                    // Handle the WARN status
                    EventBus.$emit('show-snackbar', {
                        type: 'INFO',
                        message: `${this.$t('indexed_products_count_warn')}: ${responseData.message}`
                    });
                } else {
                    throw new Error(responseData.message);
                }
            } catch (error) {
                EventBus.$emit('show-snackbar', {
                    type: 'ERROR',
                    message: `${this.$t('error')} : ${error.message}`
                });
            } finally {
                EventBus.$emit('hide-overlay');
            }
        },
        
        async indexShopifyProducts() {
            EventBus.$emit('show-overlay');
            try {
                const token = localStorage.getItem('userToken');

                const response = await axios.get(`${this.$config.configurationServer}/webshop/index_shopify_products`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        configurationId: this.selectedConfiguration._id,
                        selectedVendor: this.webshopSettings.selectedVendor
                    }
                });

                if (response.data.status === 'OK') {
                    EventBus.$emit('show-snackbar', {
                        type: 'SUCCESS',
                        message: `${this.$t('index completed')}`
                    });

                    // Update the indexed products count after each page
                    await this.getIndexedProductsCount();
                    
                } else {
                    EventBus.$emit('show-snackbar', {
                        type: 'ERROR',
                        message: response.data.data || this.$t('unexpectedError')
                    });                    
                }
               
            } catch (error) {
                EventBus.$emit('show-snackbar', {
                    type: 'ERROR',
                    message: `${this.$t('error')} : ${error.message}`
                });
            } finally {
                EventBus.$emit('hide-overlay');
            }

        },

        async indexWoocommerceProducts() {
            EventBus.$emit('show-overlay');
            try {
                const token = localStorage.getItem('userToken');
                let totalPages = Math.ceil(this.totalProducts / this.productsPerPage);
                let currentPage = this.selectedPage;
                
                //totalPages = 1; // only one page to test

                for (let page = currentPage; page <= totalPages; page++) {
                    // Call the indexing function for each page
                    const response = await axios.get(`${this.$config.configurationServer}/webshop/index_woocommerce_products`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        },
                        params: {
                            configurationId: this.selectedConfiguration._id,                           
                            page: page,
                            per_page: this.productsPerPage
                        }
                    });

                    if (response.data.status === 'OK') {
                        EventBus.$emit('show-snackbar', {
                            type: 'SUCCESS',
                            message: `${this.$t('indexed_page_success')} ${page}`
                        });

                        // Update the indexed products count after each page
                        await this.getIndexedProductsCount();
                        
                    } else {
                        EventBus.$emit('show-snackbar', {
                            type: 'ERROR',
                            message: response.data.data || this.$t('unexpectedError')
                        });
                        break; // Stop further indexing if there's an error
                    }
                }
            } catch (error) {
                EventBus.$emit('show-snackbar', {
                    type: 'ERROR',
                    message: `${this.$t('error')} : ${error.message}`
                });
            } finally {
                EventBus.$emit('hide-overlay');
            }
        },

        updateCheckboxGroups(selectedWebshop) {
            if(selectedWebshop === 'shopify') {
                this.checkboxGroups = [
                    [
                        { text: this.$t('webshop.shopify.fields.id'), value: 'id', description: this.$t('webshop.shopify.fields.id_description') },
                        { text: this.$t('webshop.shopify.fields.name'), value: 'name', description: this.$t('webshop.shopify.fields.name_description') },  // Maps to 'title' in Shopify
                        { text: this.$t('webshop.shopify.fields.short_description'), value: 'short_description', description: this.$t('webshop.shopify.fields.short_description_description') },  // Maps to 'body_html'
                        { text: this.$t('webshop.shopify.fields.tags'), value: 'tags', description: this.$t('webshop.shopify.fields.tags_description') },
                        { text: this.$t('webshop.shopify.fields.price'), value: 'price', description: this.$t('webshop.shopify.fields.price_description') },
                        { text: this.$t('webshop.shopify.fields.inventory_quantity'), value: 'inventory_quantity', description: this.$t('webshop.shopify.fields.inventory_quantity_description') },
                        { text: this.$t('webshop.shopify.fields.in_stock'), value: 'in_stock', description: this.$t('webshop.shopify.fields.in_stock_description') },
                        { text: this.$t('webshop.shopify.fields.weight'), value: 'weight', description: this.$t('webshop.shopify.fields.weight_description') },
                        { text: this.$t('webshop.shopify.fields.image'), value: 'image', description: this.$t('webshop.shopify.fields.image_description') },
                        { text: this.$t('webshop.shopify.fields.product_url'), value: 'product_url', description: this.$t('webshop.shopify.fields.product_url_description') },
                        { text: this.$t('webshop.shopify.fields.attributes'), value: 'attributes', description: this.$t('webshop.shopify.fields.attributes_description') },
                        { text: this.$t('webshop.shopify.fields.dimensions'), value: 'dimensions', description: this.$t('webshop.shopify.fields.dimensions_description') }
                    ]
                ];
            } else {
                this.checkboxGroups = [
                    [
                        { text: this.$t('webshop.woocommerce.fields.id'), value: 'id', description: this.$t('webshop.woocommerce.fields.id_description') },
                        { text: this.$t('webshop.woocommerce.fields.category'), value: 'category', description: this.$t('webshop.woocommerce.fields.category_description') },
                        { text: this.$t('webshop.woocommerce.fields.name'), value: 'name', description: this.$t('webshop.woocommerce.fields.name_description') },
                        { text: this.$t('webshop.woocommerce.fields.short_description'), value: 'short_description', description: this.$t('webshop.woocommerce.fields.short_description_description') },
                        { text: this.$t('webshop.woocommerce.fields.images'), value: 'images', description: this.$t('webshop.woocommerce.fields.images_description') },
                        { text: this.$t('webshop.woocommerce.fields.attributes'), value: 'attributes', description: this.$t('webshop.woocommerce.fields.attributes_description') },
                        { text: this.$t('webshop.woocommerce.fields.in_stock'), value: 'in_stock', description: this.$t('webshop.woocommerce.fields.in_stock_description') },
                        { text: this.$t('webshop.woocommerce.fields.average_rating'), value: 'average_rating', description: this.$t('webshop.woocommerce.fields.average_rating_description') },
                        { text: this.$t('webshop.woocommerce.fields.rating_count'), value: 'rating_count', description: this.$t('webshop.woocommerce.fields.rating_count_description') },
                        { text: this.$t('webshop.woocommerce.fields.recent_reviews'), value: 'recent_reviews', description: this.$t('webshop.woocommerce.fields.recent_reviews_description') },
                        { text: this.$t('webshop.woocommerce.fields.variations'), value: 'variations', description: this.$t('webshop.woocommerce.fields.variations_description') }
                    ],
                    [
                        { text: this.$t('webshop.woocommerce.fields.stock_quantity'), value: 'stock_quantity', description: this.$t('webshop.woocommerce.fields.stock_quantity_description') },
                        { text: this.$t('webshop.woocommerce.fields.backorders_allowed'), value: 'backorders_allowed', description: this.$t('webshop.woocommerce.fields.backorders_allowed_description') },
                        { text: this.$t('webshop.woocommerce.fields.backordered'), value: 'backordered', description: this.$t('webshop.woocommerce.fields.backordered_description') },
                        { text: this.$t('webshop.woocommerce.fields.shipping_class'), value: 'shipping_class', description: this.$t('webshop.woocommerce.fields.shipping_class_description') },
                        { text: this.$t('webshop.woocommerce.fields.dimensions'), value: 'dimensions', description: this.$t('webshop.woocommerce.fields.dimensions_description') },
                        { text: this.$t('webshop.woocommerce.fields.weight'), value: 'weight', description: this.$t('webshop.woocommerce.fields.weight_description') },
                        { text: this.$t('webshop.woocommerce.fields.tags'), value: 'tags', description: this.$t('webshop.woocommerce.fields.tags_description') },
                        { text: this.$t('webshop.woocommerce.fields.cross_sell_ids'), value: 'cross_sell_ids', description: this.$t('webshop.woocommerce.fields.cross_sell_ids_description') },
                        { text: this.$t('webshop.woocommerce.fields.upsell_ids'), value: 'upsell_ids', description: this.$t('webshop.woocommerce.fields.upsell_ids_description') },
                        { text: this.$t('webshop.woocommerce.fields.sku'), value: 'sku', description: this.$t('webshop.woocommerce.fields.sku_description') }
                    ],
                    [
                        { text: this.$t('webshop.woocommerce.fields.date_on_sale_from'), value: 'date_on_sale_from', description: this.$t('webshop.woocommerce.fields.date_on_sale_from_description') },
                        { text: this.$t('webshop.woocommerce.fields.date_on_sale_to'), value: 'date_on_sale_to', description: this.$t('webshop.woocommerce.fields.date_on_sale_to_description') },
                        { text: this.$t('webshop.woocommerce.fields.sale_percentage'), value: 'sale_percentage', description: this.$t('webshop.woocommerce.fields.sale_percentage_description') },
                        { text: this.$t('webshop.woocommerce.fields.meta_data'), value: 'meta_data', description: this.$t('webshop.woocommerce.fields.meta_data_description') },
                        { text: this.$t('webshop.woocommerce.fields.downloads'), value: 'downloads', description: this.$t('webshop.woocommerce.fields.downloads_description') },
                        { text: this.$t('webshop.woocommerce.fields.external_url'), value: 'external_url', description: this.$t('webshop.woocommerce.fields.external_url_description') },
                        { text: this.$t('webshop.woocommerce.fields.product_url'), value: 'product_url', description: this.$t('webshop.woocommerce.fields.product_url_description') },
                        { text: this.$t('webshop.woocommerce.fields.grouped_products'), value: 'grouped_products', description: this.$t('webshop.woocommerce.fields.grouped_products_description') }
                    ]
                ];
            }
        },

        initializeTranslations() {
            this.webshopOptions = [
                { text: this.$t('webshop.woocommerce_name'), value: 'woov3' },
                { text: this.$t('webshop.shopify_name'), value: 'shopify' },
                // { text: this.$t('webshop.wix'), value: 'wix' }
            ];
        },

        setMandatoryFields() {
            this.webshopSettings.selectedOptions.push('id');
        },

        isDisabled(item) {
            return item.value === 'id';
        },

        updateSelection(values) {
            this.webshopSettings.selectedOptions = values.map(value => {
                return typeof value === 'object' && value !== null ? value.value : value;
            });

            if (!this.webshopSettings.selectedOptions.includes('id')) {
                this.webshopSettings.selectedOptions.push('id');
            }

            this.updateSampleJson();
        },

        updateSampleJson() {
            const sampleData = {};
            this.webshopSettings.selectedOptions.forEach(field => {
                switch (this.webshopSettings.selectedWebshop) {
                    case 'shopify':
                        switch (field) {
                            case 'id':
                                sampleData[field] = 241423;
                                break;
                            case 'title':
                                sampleData[field] = "Sample Shopify Product";
                                break;
                            case 'body_html':
                                sampleData[field] = "This is a description of a sample product.";
                                break;
                            case 'vendor':
                                sampleData[field] = "Sample Vendor";
                                break;
                            case 'price':
                                sampleData[field] = 29.99;
                                break;
                            case 'inventory_quantity':
                                sampleData[field] = 50;
                                break;
                            case 'sku':
                                sampleData[field] = "SAMPLE-SKU-123";
                                break;
                            default:
                                sampleData[field] = `Sample data for ${field}`;
                        }
                        break;                    
                    case 'woov3':
                        switch (field) {
                            case 'id':
                                sampleData[field] = 241423;
                                break;
                            case 'category':
                                sampleData[field] = "Tablets, Second Chance Products";
                                break;
                            case 'name':
                                sampleData[field] = "Samsung Galaxy Tab S9 FE";
                                break;
                            case 'short_description':
                                sampleData[field] = "This tablet is simple, with a single lens on the back for all your photos and videos.";
                                break;
                            case 'attributes':
                                sampleData[field] = "Display: 10.9 inches; Processor: Samsung Exynos 1380; Storage: 128 GB";
                                break;
                            case 'in_stock':
                                sampleData[field] = false;
                                break;
                            case 'average_rating':
                                sampleData[field] = 4.5;
                                break;
                            case 'rating_count':
                                sampleData[field] = 24;
                                break;
                            case 'recent_reviews':
                                sampleData[field] = "Great tablet for everyday use!";
                                break;
                            case 'variations':
                                sampleData[field] = "Color: Black; Memory: 6 GB";
                                break;
                            case 'stock_quantity':
                                sampleData[field] = 0;
                                break;
                            case 'backorders_allowed':
                                sampleData[field] = true;
                                break;
                            case 'backordered':
                                sampleData[field] = true;
                                break;
                            case 'shipping_class':
                                sampleData[field] = "Standard";
                                break;
                            case 'dimensions':
                                sampleData[field] = "Height: 165.8 mm; Width: 254.3 mm; Depth: 6.5 mm";
                                break;
                            case 'weight':
                                sampleData[field] = "523 g";
                                break;
                            case 'tags':
                                sampleData[field] = "Samsung, Tablet";
                                break;
                            case 'images':
                                sampleData[field] = ['https://domain/image1.jpeg', 'https://domain/image2.png'];
                                break;
                            case 'cross_sell_ids':
                                sampleData[field] = [12345, 67890];
                                break;
                            case 'upsell_ids':
                                sampleData[field] = [11223, 44556];
                                break;
                            case 'sku':
                                sampleData[field] = "SAMSUNG-TAB-S9-FE";
                                break;
                            case 'date_on_sale_from':
                                sampleData[field] = "2024-01-01";
                                break;
                            case 'date_on_sale_to':
                                sampleData[field] = "2024-01-31";
                                break;
                            case 'sale_percentage':
                                sampleData[field] = "10%";
                                break;
                            case 'meta_data':
                                sampleData[field] = "Additional warranty included.";
                                break;
                            case 'downloads':
                                sampleData[field] = "User manual, Warranty card";
                                break;
                            case 'external_url':
                                sampleData[field] = "https://external-site.com/product/samsung-tab-s9";
                                break;
                            case 'product_url':
                                sampleData[field] = "https://tvoutlet.tv/product/samsung-galaxy-tab-s9-fe-samsung-exynos-128-gb-6gb-tweedekans/";
                                break;
                            case 'grouped_products':
                                sampleData[field] = [22334, 55678];
                                break;
                            default:
                                sampleData[field] = `Sample data for ${field}`;
                        }
                        break;
                }
            });
            this.sampleJson = JSON.stringify(sampleData, null, 2);
        }, 

        updatePageOptions() {
            if (this.totalProducts && this.indexedProductsCount !== null) {
                const totalPages = Math.ceil(this.totalProducts / this.productsPerPage);
                const indexedPages = Math.ceil(this.indexedProductsCount / this.productsPerPage);
                this.pageOptions = [];

                for (let i = indexedPages + 1; i <= totalPages; i++) {
                    this.pageOptions.push({ text: `Page ${i}`, value: i });
                }

                // Default to the first page if nothing is indexed
                this.selectedPage = indexedPages + 1;
            }
        },

        openDeleteDialog() {
            this.deleteDialog = true;
        },

        async confirmDelete() {
            this.deleteDialog = false; // Close the dialog
            EventBus.$emit('show-overlay');
            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.delete(`${this.$config.configurationServer}/webshop/delete_all_products`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        configurationId: this.selectedConfiguration._id
                    }
                });
               
                if (response.data.status === 'OK') {
                    this.indexedProductsCount = 0; // Reset the count
                    this.updatePageOptions(); // Update page options
                    EventBus.$emit('show-snackbar', {
                        type: 'SUCCESS',
                        message: response.data.data // Assuming 'data' contains the success message
                    });
                } else {
                    EventBus.$emit('show-snackbar', {
                        type: 'ERROR',
                        message: response.data.data || this.$t('unexpectedError')
                    });
                }
            } catch (error) {
                EventBus.$emit('show-snackbar', {
                    type: 'ERROR',
                    message: `${this.$t('error')} : ${error.message}`
                });
            } finally {
                EventBus.$emit('hide-overlay');
            }
        }
    }
}
</script>
