<template>
  <v-row style="padding:20px;" justify="center">
    <v-card>
      <h1 class="h1-class" style="line-height: 1.5;">{{$t('congratualations_place_your_free')}}<span class="pink-dot">!</span></h1>
      <v-card-text class="text-center">
           <v-btn @click="generateIframeCode"
            elevation="5"
            class="pink-btn"
            color="#E140A0">
            {{$t('generate_your_unique_code')}}
          </v-btn>

        <div class="textarea-button-container">
          <v-textarea style="font-style: italic;" outlined class="iframe-textarea"
            v-model="iframeCode"
            :placeholder="$t('copy_this_code_later')"
            readonly
          ></v-textarea>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click.stop="copyToClipboard"
                :disabled="!iframeCode"
                v-bind="attrs"
                v-on="on"
                fab
                small
                color="#3dd1f6">
                <span style="color:white;" class="mdi mdi-content-copy mdi-24px"></span>
              </v-btn>
            </template>
            <span>{{$t('copy_the_code_of_your')}}</span>
          </v-tooltip>

        </div>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import { EventBus } from '../../eventBus.js';
export default {
  name: "StepFiveFinalize",
  props: {
    configuration: Object
  },
  data() {
    return {
      iframeCode: '',
    };
  },
  mounted() {
    //this.generateIframeCode();
  },
  methods: {
    generateIframeCode() {
      const configurationId = this.configuration._id;
      const chatbotHost = this.$config.chatbotHost;
      let iframeElement = `<iframe id="host-hub-chatbot" allowfullscreen allow="fullscreen" style="position: fixed; bottom: 0px; right: 0px; height: 130px; width: 130px" src="${chatbotHost}/chatbot.html?version=01&configId=${configurationId}&initialState='closed'&test=false" frameborder="0"></iframe>`;
      let scriptContent = [
          // Define function to send viewport state
          "function sendViewportState() {",
          "const iframe = document.getElementById('host-hub-chatbot');",
          "let viewportState = 'desktop';",
          "if (window.innerWidth <= 425) {",
          "viewportState = 'mobile';",
          "} else if (window.innerWidth <= 768) {",
          "viewportState = 'tablet';",
          "}",
          "iframe.contentWindow.postMessage({ type: 'viewportState', state: viewportState }, '*');", // Use the actual origin of the iframe instead of '*' in production
          "}",

          // Event listener for receiving messages from the iframe
          "window.addEventListener('message', function(event) {",
          `if(event.origin === '${chatbotHost}') {`,
          "const iframe = document.getElementById('host-hub-chatbot');",
          "if(event.data.type === 'hostHubExtendChatBot') {",
          "iframe.style.width = '475px';",
          "iframe.style.height = '630px';",
          "} else if(event.data.type === 'goFullScreen') {",
          "iframe.style.width = '100vw';",
          "iframe.style.height = '100vh';",
          "iframe.style.paddingLeft = '15px';",
          "} else if(event.data.type === 'exitFullScreen') {",
          "iframe.style.width = '475px';",
          "iframe.style.height = '630px';",
          "} else {",
          "iframe.style.width = '100px';",
          "iframe.style.height = '100px';",
          "}}});",

          // Add event listeners for load and resize to send the viewport state
          "const iframe = document.getElementById('host-hub-chatbot');",
          "iframe.addEventListener('load', sendViewportState);",
          "window.addEventListener('resize', sendViewportState);",

          // Call sendViewportState initially to set the correct viewport state
          "sendViewportState();",
      ].join("");

      let scriptStart = '<script>';
      let scriptEnd = '<' + '/script>';
      this.iframeCode = iframeElement + scriptStart + scriptContent + scriptEnd;
    },
    copyToClipboard() {
      if (this.iframeCode) {
        navigator.clipboard.writeText(this.iframeCode)
          .then(() => {
            EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('the_iframe_code_has_been') });
          })
          .catch(err => {
            // Handle possible errors
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error_copying_the_iframe') + err });
          });
      }
    }
  }
};
</script>

<style scoped>

.h1-class {
    font-size: 30px;
}

.pink-dot {
  color: hwb(322 8% 15%)
}

.custom-label {
  font-size: 1.2em; /* Larger font size */
  font-weight: bold; /* Bold text */
  margin-bottom: 0.5em; /* Spacing between label and input */
  color: #333; /* Label text color */
}

.pink-btn {
  border: 2px solid #AF2C7B;
  border-radius: 15px;
  color: white;
  font-weight: bolder;
  background-color: #E140A0;

}

</style>
