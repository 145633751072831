<template>
    <v-dialog v-model="dialog" persistent :max-width="dialogMaxWidth" style="overflow-y:auto;">
    <div class="zoom">
      <div v-if="creatingAccount" class="dialog-content">
        <!-- this div should be shown when creating account == true-->
        <div style="backgroundColor: #E4FFF9; padding: 20px;">
          <v-row>
            <!-- left pane should be shown when creating account == true-->
            <v-col>
              <h1 style="color:#E140A0;" class="h1-class">{{$t('start_today_with_your_ai')}}</h1>
              <v-row style="padding-top:30px;">
                <div class=circle-icon>
                  <span class="mdi mdi-check-bold"></span>
                </div>
                <span class="bullet-text" style="padding-left:15px" v-html="$t('save_up_to')"></span>
              </v-row>
              <v-row style="padding-top:10px;">
                <div class=circle-icon>
                  <span class="mdi mdi-check-bold"></span>
                </div>
                <span class="bullet-text" style="padding-left:15px" v-html="$t('easy_integration_within')"></span>
              </v-row>
              <v-row style="padding-top:10px;">
                <div class=circle-icon>
                  <span class="mdi mdi-check-bold"></span>
                </div>
                <span class="bullet-text" style="padding-left:15px" v-html="$t('answer_247_automatic')"></span>
              </v-row>
              <div style="padding-top: 50px;" >
                <iframe id="videoElement"
                  style="width: 425px; height: 250px;"
                  src="https://www.youtube.com/embed/LOy65EsYbYk"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
                </iframe>
              </div>
            </v-col>
            <!-- right pane should always be shown-->
            <v-col>
              <v-card style="border-radius:50px;">
                <v-card-title class="dialog-header bold-text" style="background-color:white; border:none;">
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <v-img
                      alt="HostHub Logo"
                      src="@/assets/logo_color.png"
                      width="300"
                    />
                  </v-col>
                  <v-select
                      v-if="selectedLanguage"
                      v-model="selectedLanguage"
                      :items="languages"
                      @change="changeLanguage"
                      style="max-width: 50px;"
                      class="language-selector"
                      flat
                    >
                    <template v-slot:item="{ item }">
                        <div v-html="item.text"></div>
                    </template>
                    <template v-slot:selection="{ item }">
                        <div v-html="item.text"></div>
                    </template>
                  </v-select>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <v-form ref="form">
                    <v-subheader v-if="creatingAccount" style="font-weight: bolder;">{{$t('your_details')}}</v-subheader>
                    <v-text-field
                      v-if="creatingAccount"
                      v-model="name"
                      :label="$t('name')"
                      :rules="nameRules"
                      name="name"
                      prepend-icon="mdi-account"
                      required
                      validate-on-blur
                    ></v-text-field>
                    <v-text-field
                      v-if="creatingAccount"
                      v-model="companyName"
                      :label="$t('company_name')"
                      :rules="companyNameRules"
                      name="companyName"
                      prepend-icon="mdi-domain"
                      required
                      validate-on-blur
                    ></v-text-field>
                    <v-text-field
                      v-if="creatingAccount"
                      v-model="website"
                      :label="$t('website')"
                      :rules="websiteRules"
                      name="web"
                      prepend-icon="mdi-web"
                      required
                      validate-on-blur
                    ></v-text-field>

                    <v-subheader v-if="creatingAccount" style="font-weight: bolder;">{{$t('login_details')}}</v-subheader>

                    <v-text-field
                      v-model="email"
                      :label="$t('email')"
                      :rules="emailRules"
                      name="email"
                      prepend-icon="mdi-email"
                      type="email"
                      validate-on-blur
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      :label="$t('password')"
                      :rules="passwordRules"
                      name="password"
                      prepend-icon="mdi-lock"
                      type="password"
                      validate-on-blur
                    ></v-text-field>
                    <v-text-field v-if="creatingAccount"
                      v-model="password2"
                      :label="$t('repeat_password')"
                      :rules="[...passwordRules, passwordMatchRule]"
                      name="password2"
                      prepend-icon="mdi-lock"
                      type="password"
                      validate-on-blur
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions style="background-color:white; border:none;" class="dialog-footer">
                  <v-layout column align-center>
                    <v-flex>
                      <v-btn
                        elevation="5"
                        :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                        v-if="!creatingAccount"
                        color="#E140A0"
                        @click="handleLogin">{{$t('login')}}
                      </v-btn>
                      <v-btn
                        elevation="5"
                        :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                        v-if="creatingAccount"
                        color="#E140A0"
                        @click="handleCreateAccount">
                        {{$t('begin_free')}}
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <!-- <v-btn
                        v-if="!creatingAccount"
                        class="my-1" text small
                        @click="creatingAccount = true">{{$t('create_account')}}
                      </v-btn> -->
                      <v-btn
                        v-if="creatingAccount" class="my-1" text small @click="creatingAccount = false">{{$t('login')}}</v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-actions>
              </v-card>
              <div :style="{
                    display: creatingAccount ? '' : 'none',
                    'justify-content': 'center',
                    width: '100%',
                    display: 'flex'
                  }">
                <img
                  alt="HostHub Logo"
                  src="@/assets/news_logos.png"
                  style="max-width: 350px; width: 100%; margin-top: 20px;"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- login -->
      <v-layout column align-center >
        <v-col class="login-ui" style="padding: 10px;" v-if="!creatingAccount">
            <v-card style="border-radius:50px; box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);">
              <v-card-title class="dialog-header bold-text" style="background-color:white; border:none;">
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-img
                    alt="HostHub Logo"
                    src="@/assets/logo_color.png"
                    width="300"
                  />
                </v-col>
                <v-select
                    v-if="selectedLanguage"
                    v-model="selectedLanguage"
                    :items="languages"
                    @change="changeLanguage"
                    style="max-width: 50px;"
                    class="language-selector"
                    flat
                  >
                  <template v-slot:item="{ item }">
                      <div v-html="item.text"></div>
                  </template>
                  <template v-slot:selection="{ item }">
                      <div v-html="item.text"></div>
                  </template>
                </v-select>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    v-model="email"
                    :label="$t('email')"
                    :rules="emailRules"
                    name="email"
                    prepend-icon="mdi-email"
                    type="email"
                    validate-on-blur
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :label="$t('password')"
                    :rules="passwordRules"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    validate-on-blur
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions style="background-color:white; border:none;" class="dialog-footer">
                <v-layout column align-center>
                  <v-flex>
                    <v-btn
                      elevation="5"
                      :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                      v-if="!creatingAccount"
                      color="#E140A0"
                      @click="handleLogin">{{$t('login')}}
                    </v-btn>
                    <v-btn
                      elevation="5"
                      :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:25px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                      v-if="creatingAccount"
                      color="#E140A0"
                      @click="handleCreateAccount">
                      {{$t('begin_free')}}
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <!-- <v-btn
                      v-if="!creatingAccount"
                      class="my-1" text small
                      @click="creatingAccount = true">{{$t('create_account')}}
                    </v-btn> -->
                    <v-btn
                      v-if="creatingAccount" class="my-1" text small @click="creatingAccount = false">{{$t('login')}}</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </v-card>
        </v-col>
      </v-layout>
    </div>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { EventBus } from '../../eventBus.js';

export default {
  data: () => ({
    dialog: true,
    email: '',
    password: '',
    creatingAccount: false,
    password2: '',
    selectedLanguage: '',
    name:'',
    companyName:'',
    website: ''
  }),
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const languageCode = urlParams.get('language_code') || localStorage.getItem('userLanguage') || 'en';
    const url = urlParams.get('url');
    const email = urlParams.get('email');
    const name = urlParams.get('name');
    this.selectedLanguage = languageCode;
    this.changeLanguage(languageCode);
    this.email = email;
    this.website = url;
    this.name = name;

    const creatingAccountParam = urlParams.get('creatingAccount');
    this.creatingAccount = creatingAccountParam === 'true';

    const email_address = urlParams.get('email_address');
    if (email_address) {
      this.email = email_address;
    }
  },

  computed: {
    dialogMaxWidth() {
      // Responsive max-width for the dialog
      let result = 0;
      if (window.innerWidth > 989) {
        this.creatingAccount ? result = '1050px' : result = '500px';
      } else if (window.innerWidth > 700) {
        return '550px';
      } else if (window.innerWidth > 530) {
        return '490px';
      } else {
        return '95%'; // 90% of viewport width on smaller screens
      }
      return result;
    },
    languages() {
      return [
        { text: this.getFlagIcon('us'), value: 'en' },
        { text: this.getFlagIcon('nl'), value: 'nl' },
        { text: this.getFlagIcon('de'), value: 'de' },
        { text: this.getFlagIcon('fr'), value: 'fr' },
        { text: this.getFlagIcon('es'), value: 'es' },
        { text: this.getFlagIcon('pt'), value: 'pt' }
      ];
    },
    nameRules() {
      return [
        v => !!v || this.$t('field_required'),
      ];
    },
    companyNameRules() {
      return [
        v => !!v || this.$t('field_required'),
      ];
    },
    websiteRules() {
      return [
        v => !!v || this.$t('field_required'),
        v => /^(https?:\/\/)?([\da-z.-]+)\.([a-z]{2,6})([\w .-]*)*\/?$/.test(v) || this.$t('invalid_website'),
      ];
    },
    emailRules() {
      return [
        v => !!v || this.$t('email_required'),
        v => /.+@.+\..+/.test(v) || this.$t('invalid_email'),
      ];
    },
    passwordRules() {
      return [
        v => !!v || this.$t('password_required'),
        v => (v.length >= 8 && /[A-Z]/.test(v) && /[0-9]/.test(v) && /[!@#$%^&*(),.?":{}|<>]/.test(v)) ||
            this.$t('password_requirements'),
      ];
    },
    isEmailValid() {
      return this.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
    },
    doPasswordsMatch() {
      return this.password === this.password2;
    },
    isFormValid() {
      const basicValidation = this.emailRules.every(rule => rule(this.email) === true) &&
                            this.passwordRules.every(rule => rule(this.password) === true);

      if (this.creatingAccount) {
        return basicValidation &&
          this.nameRules.every(rule => rule(this.name) === true) &&
          this.doPasswordsMatch;
      }
      return basicValidation;
    },
    passwordMatchRule() {
      return v => v === this.password || this.$t('passwords_do_not_match');
    }
  },

  methods: {
    getFlagIcon(countryCode) {
      return `<span class="flag-icon flag-icon-${countryCode}"></span>`;
    },
    changeLanguage(newLang) {
      this.$i18n.locale = newLang;
      this.$vuetify.lang.current = newLang;
      localStorage.setItem('userLanguage', newLang);
    },
    async handleLogin() {
      try {
        EventBus.$emit('show-overlay');
        const response = await axios.post(this.$config.configurationServer + '/login', {
          username: this.email,
          password: this.password
        });
        if (response.data.status === 'success') {
          localStorage.setItem('userToken', response.data.token);
          await this.fetchConfigurations();
          this.isLoggedIn = true;
          this.dialog = false;
          this.$emit('login-success');
          EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('login_success') });
        } else {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('login_unsucces') });
        }
      } catch (error) {
          console.log('error: ', error);
          if (error.response && error.response.data && error.response.data.status === 'error') {
            // Handle the case where the server returns an error response
            EventBus.$emit('show-snackbar', { type: 'WARN', message: this.$t(error.response.data.message) });
          } else if (error.code === 'ERR_NETWORK') {
            // Handle network errors
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('services_are_offline') });
          } else {
            // Handle other types of errors
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('login_unsucces') });
          }
        } finally {
        EventBus.$emit('hide-overlay');
      }
    },
    async fetchConfigurations() {
      const token = localStorage.getItem('userToken');
      if (token) {
        try {
          const configResponse = await axios.get(this.$config.configurationServer + '/configurations_with_username', {
            headers: { Authorization: `Bearer ${token}` }
          });
          if (configResponse.status === 200) {
            this.configurations = configResponse.data;
            // Set the first configuration as selected
            if (this.configurations.length > 0) {
              this.selectedConfiguration = this.configurations[0]._id;
              localStorage.setItem('userConfigurations', JSON.stringify(this.configurations));
            }
            else {
              this.$emit('start-wizard');
            }
          } else {
            // Handle errors
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') });
            console.error('Error fetching configuration');
          }
        } catch (error) {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') });
          console.error('Error fetching configurations:', error);
        }
      }
    },
    async handleCreateAccount() {
      if (this.isFormValid) {
        try {
          EventBus.$emit('show-overlay');

          const response = await axios.post(this.$config.configurationServer + '/create-account', {
            email: this.email,
            password: this.password,
            name: this.name,
            companyName: this.companyName,
            website: this.website,
            language_code: this.selectedLanguage
          });

          if (response.data.status === 'success') {
            EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('activationlink_send'), timeout: -1 });
            this.password = '';
            this.password2 = '';
            this.name = '',
            this.companyName = '',
            this.website = ''
            this.creatingAccount = false;
          } else {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('account_creation_failed') });
          }
        } catch (error) {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('account_creation_failed') });
          console.error('Account creation error:', error);
        } finally {
          // Hide the loading overlay
          EventBus.$emit('hide-overlay');
        }
      } else {
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('form_invalid') });
      }
    },
    resetForm() {
      this.email = '';
      this.password = '';
      this.password2 = '';
      this.name = '',
      this.companyName = '',
      this.website = ''
    }
  }
};
</script>

<style scoped>
.circle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px; /* adjust size as needed */
  height: 25px; /* adjust size as needed */
  background-color: #E140A0;
  border: 2px solid black;
  border-radius: 50%;
}

.language-selector {
  position: absolute;
  right: 0px;
  margin-top: 15px;
  margin-right: 10px;
}

.dialog-content {
  /* display: flex;
  justify-content: center; */
  background-color: #E4FFF9;
  padding: 20px;
}

@media (max-width: 577px) {
  .circle-icon {
    width: 25px;
    height: 25px;
  }
  .h1-class {
    font-size: 30px;
  }
  .bullet-text {
    font-size: 14px
  }
  .zoom {
    zoom: 95%;
  }
}

@media (max-width: 500px) {
  .zoom {
    zoom: 90%;
  }
}

@media (max-width: 475px) {
  .zoom {
    zoom: 85%;
  }
}

@media (max-width: 450px) {
  .zoom {
    zoom: 80%;
  }
}

@media (max-width: 425px) {
  .zoom {
    zoom: 75%;
  }
}

@media (max-width: 400px) {
  .zoom {
    zoom: 70%;
  }
}
</style>
