<template>
    <v-card class="window-card">
      <div class="chart-title">ADMIN - Meeste gesprekken per configuratie</div>
      <apex-chart :options="options" :series="series"></apex-chart>
    </v-card>
  </template>

  <script>
  import axios from 'axios';
  import { EventBus } from './../../eventBus.js';
  export default {
    name: 'AdminMostConversationsPerCustomerWidget',
    data() {
      return {
        options: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            }
          },
          xaxis: {
            categories: []
          }
        },
        series: [{
          name: this.$t('total_chats'),  // Translation applied here, assuming 'total_chats' is in your i18n
          data: []
        }, {
          name: this.$t('total_messages'), 
          data: []
        }]
      };
    },
    created() {
      EventBus.$on('reload-chart', this.loadData);
    },
    beforeDestroy() {
      EventBus.$off('reload-chart', this.loadData);
    },
    mounted() {
      this.loadData();
    },
    methods: {
      loadData() {
        const token = localStorage.getItem('userToken');
        if (!token) {
          console.error("No token found");
          return;
        }
        const widget_id = 'widget_adm_2';
        axios.get(this.$config.configurationServer + '/dashboard/get_widget_data', {
          headers: { Authorization: `Bearer ${token}` },
          params: { widget_id }
        })
        .then(response => {
            if (response.status === 200 && Array.isArray(response.data)) {
              this.options = {
                ...this.options,
                xaxis: {
                  ...this.options.xaxis,
                  categories: response.data.map(item => item.configuration_name)
                }
              };
              this.series = [{
                ...this.series[0],
                data: response.data.map(item => item.total_chats)
              },{
                ...this.series[1],
                data: response.data.map(item => item.total_user_messages)
              }];
            } else {
              console.error('Error loading data:', response);
            }
        })
        .catch(error => {
            EventBus.$emit('show-snackbar', {
              type: 'ERROR',
              message: 'Data retrieval unsuccessful: ' + error.message
            });
            console.error('Error fetching widget data:', error);
        });
      }
    }
  }
  </script>

  <style scoped>
    .window-card { }

    .chart-title {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }
  </style>
