<template>
  <v-dialog v-model="localDialog" persistent max-width="600px">
    <v-card v-if="selectedConversation" style="overflow:hidden; border-radius:25px;">
      <v-row justify="center">
        <v-row justify="center">
          <v-col cols="auto">
            <h1 style="padding-top:25px;" class="h1-class">{{$t('detailed_conversation_details')}}</h1>
            <template>
              <h2 class="text-center">{{ formatDate(selectedConversation.date_created) }}</h2>
            </template>
          </v-col>
        </v-row>

        <v-card-text class="dialog-content">
            <v-col cols="12">
              <v-simple-table style="border: 1px solid black" >
                  <thead>
                    <tr>
                      <th class="text-left">
                        Model
                      </th>
                      <th class="text-left">
                        Input Costs
                      </th>
                      <th class="text-left">
                        Input Tokens
                      </th>
                      <th class="text-left">
                        Output Costs
                      </th>
                      <th class="text-left">
                        Output Tokens
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{selectedConversation.model}}</td>
                      <td>{{selectedConversation.input_cost}} USD</td>
                      <td>{{selectedConversation.input_tokens}}</td>
                      <td>{{selectedConversation.output_cost}} USD</td>
                      <td>{{selectedConversation.output_tokens}}</td>
                    </tr>
                  </tbody>
              </v-simple-table>
              <v-expansion-panels accordion class="pt-5">
                <div v-for="message in selectedConversation.chat.messages" :key="message.id">
                  <v-expansion-panel v-if="message.role === 'system'" style="background-color: #c2c2c2;">
                    <v-expansion-panel-header class="custom-background"><div>{{ message.summary || 'System Message' }}</div></v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-html="message.content"></div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <div v-else :class="getMessageClass(message)">
                    <h3 class="text-right" v-if="message.timestamp">{{ formatTime(message.timestamp) }}</h3>
                    <div v-html="message.content"></div>
                  </div>
                </div>
              </v-expansion-panels>
            </v-col>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-row style="padding:35px;">
                <v-btn
                  elevation="5"
                  :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding-top:35px; padding-bottom:25px; padding-left:50px; padding-right:50px; font-size:22px; font-weight:bolder;`"
                  color="#E140A0"
                  @click="close">
                  {{$t('close')}}
                </v-btn>
            </v-row>
        </v-card-actions>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    selectedConversation: Object
  },
  data() {
    return {
      localDialog: this.dialog,
    };
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal;
    },
    localDialog(newVal) {
      if (!newVal) {
        this.$emit('update:dialog', newVal);
      }
    },
  },
  methods: {
    getMessageClass(message) {
      return {
        'chatSession-message-user': message.role === 'user',
        'chatSession-message-assistant': message.role === 'assistant',
      };
    },
    close() {
      this.localDialog = false;
      this.$emit('close-dialog');
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Intl.DateTimeFormat('nl-NL', {
        timeZone: userTimeZone,
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);
    },
    formatTime(timestamp) {
      // Append 'Z' to indicate UTC time
      const utcTimestamp = timestamp + 'Z';
      const date = new Date(utcTimestamp);

      const convertedDate = date.toLocaleTimeString('nl-NL', {
        timeZone: 'Europe/Amsterdam',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });

      return convertedDate;
    }
  },
};
</script>

<style scoped>

.dialog-content {
  max-height: 50vh;
  max-width: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (min-width: 1000px) {
  .h1-class {
    font-size: 36px;
  }
  .h2-class {
    font-size: 30px;
  }
  .p-class {
    font-size: 20px;
  }
}
.chatSession-message-user {
  background-color: #e3f2fd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: right;
}

.chatSession-message-assistant {
  background-color: #fce4ec;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
}

.custom-background {
  background-color: #8b8b8b;
}

</style>
