<template>
    <v-container class="pt-5">
      <v-row justify="center">
        <v-col cols="12" xl="6" lg="10" md="10" sm="12" xs="12">
          <v-card class="pl-3">
            <v-row no-gutters justify="center" class="pt-5">
              <v-col cols="12">
                <v-row>
                  <v-col cols="8">
                    <v-autocomplete
                      v-model="selectedCustomerId"
                      :disabled="hasChanges"
                      :items="customers"
                      item-text="name"
                      item-value="_id"
                      :label="$t('customer')"
                      :placeholder="$t('select_customer')"
                      outlined
                      dense
                      @input="loadSelectedCustomer"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="d-inline-flex">
                          <v-btn
                            :disabled="hasChanges"
                            fab
                            small
                            color="primary"
                            @click="setNewCustomer"
                          >
                            <v-icon color="white">
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ $t('new_customer') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="customer">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="d-inline-flex pl-1">
                          <v-btn
                            :disabled="hasChanges || customerHasConfiguration"
                            fab
                            small
                            color="red"
                            @click="event => (event.ctrlKey && event.button === 0) && deleteCustomer()"
                          >
                            <v-icon color="white">
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ $t(deleteCustomerTooltipText) }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <div v-if="customer">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="customer.name"
                        :label="$t('name')"
                        :placeholder="$t('enter_value')"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-text-field
                        v-model="customer.contactPerson"
                        :label="$t('contactperson')"
                        :placeholder="$t('enter_value')"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="customer.contactPersonPhone"
                        :label="$t('phonenumber')"
                        :placeholder="$t('enter_value')"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="customer.contactPersonEmail"
                        :label="$t('email')"
                        :placeholder="$t('enter_value')"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-text-field
                        v-model="customer.street"
                        :label="$t('street')"
                        :placeholder="$t('enter_value')"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="customer.houseNumber"
                        :label="$t('housenumber')"
                        :placeholder="$t('enter_value')"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="customer.addition"
                        :label="$t('addition')"
                        :placeholder="$t('enter_value')"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-text-field
                        v-model="customer.postalCode"
                        :label="$t('postalcode')"
                        :placeholder="$t('enter_value')"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="customer.city"
                        :label="$t('city')"
                        :placeholder="$t('enter_value')"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="customer.country"
                        :items="countries"
                        :label="$t('country')"
                        :placeholder="$t('select_country')"
                        persistent-placeholder
                        outlined
                        dense
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-text-field
                        disabled
                        v-model="customer.stripe_customer_id"
                        :label="$t('stripe_customer_id')"
                        :placeholder="$t('please_enter')"
                        persistent-placeholder
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="customer.language"
                        :items="languages"
                        :label="$t('language_select')"
                        :placeholder="$t('select_language')"
                        persistent-placeholder
                        outlined
                        dense
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="customer.timezone"
                        :items="timezones"
                        :label="$t('timezone')"
                        :placeholder="$t('select_timezone')"
                        persistent-placeholder
                        outlined
                        dense
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-select
                        v-model="customer.taxExempt"
                        :items="taxExemptOptions"
                        :label="$t('tax_exempt_label')"
                        :placeholder="$t('select_tax_exempt')"
                        persistent-placeholder
                        outlined
                        dense
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="customer.taxId"
                        :label="$t('tax_id')"
                        :placeholder="$t('please_enter')"
                        persistent-placeholder
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="2">
                      <v-btn
                        :disabled="!hasChanges"
                        @click="saveCustomer"
                        dense
                        color="primary"
                      >
                        {{ $t('save') }}
                      </v-btn>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        :disabled="!hasChanges"
                        @click="cancelChange"
                        dense
                        class="pl-3"
                        color="secondary"
                      >
                        {{ $t('cancel') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <script>
  import { EventBus } from '@/eventBus';
  import axios from 'axios';
  export default {
    name: 'CustomersTab',
    data: () => ({
      selectedCustomerId: null,
      customerHasConfiguration: false,
      customers: [],
      configurations: [],
      customer: null,
      initialCustomerState: null,
      timezones: [
        { text: 'Europe/Amsterdam', value: 'Europe/Amsterdam' }
      ]
    }),
    mounted() {
      this.initializeCustomerTab();
    },
    computed: {
        languages() {
            return [
                { text: this.$t('language.english'), value: 'en' },
                { text: this.$t('language.dutch'), value: 'nl' },
                { text: this.$t('language.german'), value: 'de' },
                { text: this.$t('language.french'), value: 'fr' },
                { text: this.$t('language.spanish'), value: 'es' },
                { text: this.$t('language.portuguese'), value: 'pt' }
            ];
        },
        countries() {
            return [
            { text: this.$t('NLD'), value: 'NL' },
            { text: this.$t('BEL'), value: 'BE' },
            { text: this.$t('DEU'), value: 'DE' },
            { text: this.$t('LUX'), value: 'LU' },
            ];
        },
        taxExemptOptions() {
            return [
                { text: this.$t('tax_exempt.none'), value: 'none' },
                { text: this.$t('tax_exempt.exempt'), value: 'exempt' },
                { text: this.$t('tax_exempt.reverse'), value: 'reverse' },
            ]
        },
      hasChanges() {
        return JSON.stringify(this.customer) !== JSON.stringify(this.initialCustomerState);
      },
      deleteCustomerTooltipText() {
        if (this.customerHasConfiguration) {
          return 'cannot_delete_customer'; // Assuming you have a separate text for this condition
        }
        return 'delete_customer';
      }
    },
    methods: {
      async initializeCustomerTab() {
        EventBus.$emit('show-overlay');
        try {
          await Promise.all([this.loadCustomers()]);
        } catch (error) {
          console.error('Error during initialization:', error);
          EventBus.$emit('show-snackbar', {
            type: 'ERROR',
            message: this.$t('initialization_error'),
          });
        } finally {
          EventBus.$emit('hide-overlay');
        }
      },
      async loadCustomers() {
        const token = localStorage.getItem('userToken');
        if (token) {
          return axios
            .get(this.$config.configurationServer + '/customers', {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((configResponse) => {
              if (configResponse.status === 200) {
                this.customers = configResponse.data;
              } else {
                EventBus.$emit('show-snackbar', {
                  type: 'ERROR',
                  message: this.$t('data_retrieval_unsucces'),
                });
                return [];
              }
            })
            .catch((error) => {
              EventBus.$emit('show-snackbar', {
                type: 'ERROR',
                message: this.$t('data_retrieval_unsucces') + error,
              });
              this.customers = [];
            });
        } else {
          return Promise.resolve([]);
        }
      },
      async loadSelectedCustomer(customerId) {
        const token = localStorage.getItem('userToken');
        if (token) {
          EventBus.$emit('show-overlay');

          try {
            const params = new URLSearchParams({
              _id: customerId,
            }).toString();

            const configResponse = await axios.get(
              `${this.$config.configurationServer}/customer?${params}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            if (configResponse.status === 200) {
              this.customer = configResponse.data;
              this.initialCustomerState = JSON.parse(JSON.stringify(this.customer));
              this.loadCustomerConfiguration(customerId);
            } else {
              EventBus.$emit('show-snackbar', {
                type: 'ERROR',
                message: this.$t('data_retrieval_unsucces'),
              });
            }
          } catch (error) {
            EventBus.$emit('show-snackbar', {
              type: 'ERROR',
              message: this.$t('data_retrieval_unsucces') + error,
            });
            this.customer = null;
          } finally {
            EventBus.$emit('hide-overlay');
          }
        } else {
          return Promise.resolve(null);
        }
      },
      async loadCustomerConfiguration(customerId) {
        const token = localStorage.getItem('userToken');
        if (token) {
          EventBus.$emit('show-overlay');

          const params = new URLSearchParams({
            _id: customerId,
          }).toString();

          return axios
            .get(`${this.$config.configurationServer}/customer_has_configuration?${params}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((configResponse) => {
              if (configResponse.status === 200) {
                this.customerHasConfiguration = configResponse.data.hasConfiguration;
              } else {
                EventBus.$emit('show-snackbar', {
                  type: 'ERROR',
                  message: this.$t('data_retrieval_unsucces'),
                });
                return [];
              }
            })
            .catch((error) => {
              EventBus.$emit('show-snackbar', {
                type: 'ERROR',
                message: this.$t('data_retrieval_unsucces') + error,
              });
            })
            .finally(() => {
              EventBus.$emit('hide-overlay');
            });
        } else {
          return Promise.resolve(null);
        }
      },
      setNewCustomer() {
        this.customer = {};
        this.selectedCustomerId = null;
      },
      cancelChange() {
        if (this.hasChanges) {
          this.customer = JSON.parse(JSON.stringify(this.initialCustomerState));
        }
        this.selectedCustomerId = this.customer._id;
      },
      async saveCustomer() {
    const token = localStorage.getItem('userToken');
    if (!token) {
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('auth_required') });
        return;
    }
    EventBus.$emit('show-overlay');

    try {
        const response = await axios.post(
        `${this.$config.configurationServer}/customer/create_or_update`,
        this.customer,
        {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            },
        }
        );

        // Handling success based on the operation performed (create or update)
        if (response.status === 200 && response.data) {
        // Check if the response includes 'updated_id' to identify an update operation
        if (response.data.updated_id || response.data.inserted_id) {
            // Bind the stripe_customer_id and updated customer data to this.customer
            this.customer = { ...this.customer, ...response.data.updated_customer };

            EventBus.$emit('show-snackbar', {
            type: 'SUCCESS',
            message: response.data.updated_id
                ? this.$t('customer_updated_successfully')
                : this.$t('customer_created_successfully'),
            });

            this.initialCustomerState = JSON.parse(JSON.stringify(this.customer));
            this.loadCustomers(); // Reload the customer list
        } else {
            // Handle case where neither insert nor update seems to have occurred
            EventBus.$emit('show-snackbar', {
            type: 'WARNING',
            message: response.data.message || this.$t('no_changes_detected'),
            });
        }
        } else {
        // Handle non-200 responses that were understood but indicate failure
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('customer_save_failed') });
        }
    } catch (error) {
        console.error('Error saving customer:', error);
        // Parsing potential error response from backend
        const message =
        error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : this.$t('customer_save_failed');
        EventBus.$emit('show-snackbar', {
        type: 'ERROR',
        message: message + `: ${error.message}`,
        });
    } finally {
        EventBus.$emit('hide-overlay');
    }
    },
      deleteCustomer() {
        const token = localStorage.getItem('userToken');
        if (!token) {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('auth_required') });
          return Promise.resolve(null);
        }

        EventBus.$emit('show-overlay');

        const customerId = this.customer._id;

        axios
          .delete(`${this.$config.configurationServer}/customers/${customerId}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              // Check if the deletion was successful
              EventBus.$emit('show-snackbar', {
                type: 'SUCCESS',
                message: this.$t('customer_deleted_successfully'),
              });
              this.customer = null;
              this.initialCustomerState = null;
            } else {
              EventBus.$emit('show-snackbar', {
                type: 'ERROR',
                message: this.$t('customer_deletion_failed'),
              });
            }
          })
          .catch((error) => {
            EventBus.$emit('show-snackbar', {
              type: 'ERROR',
              message: `${this.$t('customer_deletion_failed')}: ${error.message}`,
            });
          })
          .finally(() => {
            this.initializeCustomerTab();
            EventBus.$emit('hide-overlay');
          });
      },
    },
  };
  </script>
